import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { fadeAnimation } from 'src/helpers/transitions-helper';
import { NavController } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { first } from 'rxjs/operators';
import { Plugins } from '@capacitor/core'

const { Storage } = Plugins;

@Component({
  selector: 'end',
  templateUrl: './end.component.html',
  styleUrls: ['./disclaimer.component.css']
})
export class EndComponent implements OnInit {

  constructor(private _location: Location,
    private navCtrl: NavController,
    public afs: AngularFirestore) { }

  ngOnInit(): void {
    Storage.set({ key: 'finished', value: "true" });
  }

  back() {
    // this._location.back();
    this.navCtrl.navigateRoot(['dream-selector'], { animation: fadeAnimation, queryParams: { openJournal: true }, animated: true });
  }
}
