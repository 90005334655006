<ion-app>
  <ion-header class="ion-no-border">
  </ion-header>
  <ion-content scrollY="false" class="ion-text-center">
    <flip-card [frontCardBackground]="frontCardBackground" [backCardBackground]="backCardBackground"
      [edgeCardBackground]="'background: white'" [ctaTitle]="getButtonText()" [ctaCallback]="myCallbackFunction"
      [isButtonOnFrontSide]="false" [hideButtonOnClick]="true" [ctaDisabledfromOutSide]="true">

      <div back-card>
        <div class="cmn-wrapper" [class.hidden]="transitionsService.shouldHideContent">
          <div class="ion-text-uppercase cmn-font-helvetica dream-number cmn-enabled">
            {{screenProperties?.eyebrow}}
          </div>
          <br>
          <div class="cmn-serif-title ion-text-center"> {{screenProperties?.title}}</div>
          <br>
          <div class="ion-text-uppercase cmn-font-helvetica cmn-enabled"> {{screenProperties?.subtitle}} </div>

          <div class="image-wrapper">
            <img class="cmn-diamond" src="assets/images/diamond.png">
          </div>

          <div class="cmn-font-jotia ion-text-center" [innerHTML]="screenProperties?.textBelow"></div>
          <br>
        </div>
      </div>
    </flip-card>
  </ion-content>
</ion-app>