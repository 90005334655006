import {
  animate,
  AnimationTriggerMetadata,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";

export const fade = trigger("fade", [
  state("void", style({ opacity: 0 })),
  transition(":enter, :leave", [animate(250)]),
]);

export const fadeInTitle = fadeIn("fadeInTitle", 0);

export const fadeInFirstSepartor = fadeIn("fadeInFirstSepartor", 50);

export const fadeInFirtsMenuItem = fadeIn("fadeInFirtsMenuItem", 100);
export const fadeInSecondMenuItem = fadeIn("fadeInSecondMenuItem", 150);
export const fadeInThirdMenuItem = fadeIn("fadeInThirdMenuItem", 200);

export const fadeInSecondSepartor = fadeIn("fadeInSecondSepartor", 250);

export const fadeInButtonWrapper = fadeIn("fadeInButtonWrapper", 300);

function fadeIn(triggerName: string, delay: number): AnimationTriggerMetadata {
  return trigger(triggerName, [
    state("void", style({transform: "translateY(1vh)", opacity: 0})),
    transition(":enter", [animate(`150ms ${delay}ms`)])
  ]);
}
