import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SubjectSubscriber, Subject } from 'rxjs/internal/Subject';

@Injectable({
    providedIn: 'root'
})
export class AnimationsService {
    public isAnimationVisible: Boolean = false;
    public animationPath: string = "";
    public subject = new Subject()

    constructor() {
    }

    handleAnimation(input: string) {
        this.isAnimationVisible = true;
        this.animationPath = 'assets/animations/dreams/' + input;
        this.subject.next({ text: this.animationPath });
        console.log(this.animationPath)
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}
