<ion-app>
  <ion-content scrollY="false" class="ion-no-padding">
    <div class="intro-scene__wrapper">
      <ng-container [ngSwitch]="currentAnimation">
        <div *ngSwitchCase="1" [@headsetFadeInOut]="currentState" class="intro-scene__first-scene">
          <div class="headset-circle">
            <div class="circle">
                <img [src]="'assets/images/headset.svg'" alt="headset">
            </div>
          </div>
          <div class="intro-scene__headset-text">
            <span>Use headphones for a <br>better dream immersion.</span>
          </div>
        </div>
        <ng-container *ngIf="showContainer">
          <div
            *ngSwitchCase="2"
            class="intro-scene__second-scene"
            [@starsFadeAnim]="currentAnimation"
            [ngClass]="isSplashScreen ? 'splash' : 'basic'"
          >
            <div class="betwixt-title">
              <h1>Betwixt</h1>
              <span class="sub-title">THE STORY OF YOU</span>
            </div>
          </div>
          <div class="button-container" *ngIf="isSplashScreen">
            <button class="read-dream-button" (click)="navigateBackToDream()">
              <span>Continue dreaming</span>
            </button>
          </div>
        </ng-container>
        <div class="intro-scene__third-scene" *ngIf="!isSplashScreen && showSelector">
          <app-dream-selector [hasBackground]="false"></app-dream-selector>
        </div>
      </ng-container>
    </div>
  </ion-content>
</ion-app>
