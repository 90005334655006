<ion-app>
  <ion-header class="ion-no-border">
  </ion-header>
  <ion-content scrollY="false">
    <div class="cmn-wrapper">
      <div class="cmn-serif-title ion-text-center big-margin-top">
        Download Betwixt
      </div>
      <div class="disclaimer-text ion-text-center">
        <p>
          Betwixt is now in the app stores! <br>To access new features on your continued journey, you can download the
          app
          (log in with your existing account details) <a target="_blank"
            href="https://app-download.betwixt.life/">here</a>.
        </p>
      </div>

    </div>
  </ion-content>
</ion-app>