import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { App, AppState, PluginListenerHandle } from '@capacitor/core';
import { NavController } from '@ionic/angular';
import { expandAnimationDream } from 'src/helpers/transitions-helper';
import { headsetFadeInOut, starsFadeAnim } from './../../animations/intro-animations';

@Component({
  selector: 'app-intro-scene',
  templateUrl: './intro-scene.component.html',
  styleUrls: ['./intro-scene.component.scss'],
  animations: [
    headsetFadeInOut,
    starsFadeAnim
  ],
})

export class IntroSceneComponent implements OnInit, OnDestroy {
  currentState: 'in' | 'out' = 'in';
  currentAnimation = 1;
  showSelector = false;
  isSplashScreen = false;
  showContainer = true;
  introListener: PluginListenerHandle;

  constructor(
    private route: ActivatedRoute,
    private navCtrl: NavController,
  ) { }

  ngOnInit(): void {
    this.isSplashScreen = Object.keys(this.route.snapshot.queryParams).includes('splashScreen');
    if (this.isSplashScreen) {
      this.currentAnimation = 2;
      this.showContainer = false;
    } else {
      this.animationCallback(2, this.currentAnimation);
    }
  }

  animationCallback(maxAnimationLength: number, currentAnimationNumber: number) {
    if (currentAnimationNumber < maxAnimationLength) {
      setTimeout(() => {
        if (this.currentState === 'in') {
          this.currentState = 'out';
        } else {
          currentAnimationNumber++;
          this.currentAnimation = currentAnimationNumber;
        }
        this.animationCallback(maxAnimationLength, currentAnimationNumber);
      }, 2000);
    }

    if (this.currentAnimation === 2) {
      setTimeout(() => {
        this.showSelector = true;
      }, 9000);
    }
  }

  navigateBackToDream() {
    const dreamId = localStorage.getItem("selectedDream");
    this.isSplashScreen = false;
    if (location.href.includes('?')) {
      history.pushState({}, null, location.href.split('?')[0]);
    }
    this.navCtrl.navigateRoot("game/" + parseInt(dreamId), { animation: expandAnimationDream, animated: true });
  }

  ionViewDidEnter() {
    this.introListener = App.addListener('appStateChange', (state: AppState) => {
      if (this.isSplashScreen) {
        this.showContainer = state.isActive;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.introListener) this.introListener.remove();
  }
}
