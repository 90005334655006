import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { take } from 'rxjs/operators';
import { MenuService } from 'src/services/menu.service';
import { MenuType } from '../menu/menu.component';

@Component({
  selector: 'app-menu-button',
  templateUrl: './menu-button.component.html',
  styleUrls: ['./menu-button.component.css']
})
export class MenuButtonComponent implements OnInit {

  constructor(public menuService: MenuService) { }

  ngOnInit(): void {}

}
