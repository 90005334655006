import { Component, OnInit } from '@angular/core'
import { NavController } from '@ionic/angular'
import { StateManagementService } from 'src/services/state-management.service'
import StoryHelper from 'src/helpers/story-helper'
import { FlipCardHelper } from 'src/helpers/flip-card-helper'
import { TransitionsPropertiesService } from 'src/services/rounded-edges-service'
import { OperationsHelper } from 'src/helpers/ops-helper'

@Component({
  selector: 'app-successful-registration',
  templateUrl: './successful-registration.component.html',
  styleUrls: ['./successful-registration.component.css'],
})
export class SuccessfulRegistrationComponent implements OnInit {
  isDreamAvailable: boolean = true; //todo: fix button when false
  isLocked = !this.isDreamAvailable;

  lastCompletedDream: number
  nextDream: number;
  public frontCardBackground: string
  public backCardBackground: string;

  public screenProperties: { eyebrow: string, title: string, subtitle: string; textBelow: string; }

  constructor(
    public navCtrl: NavController,
    private stateManagementService: StateManagementService,
    public transitionsService: TransitionsPropertiesService
  ) { }

  ngOnInit(): void {
    this.lastCompletedDream = this.stateManagementService.getLastCompletedDream()
    this.getStyle()
    this.getText();
  }

  ionViewDidEnter() {
    this.transitionsService.shouldHideContent = false;
  }

  getStyle() {
    var styles = FlipCardHelper.getStyles(this.lastCompletedDream);
    this.frontCardBackground = styles.frontCardStyle;
    this.backCardBackground = styles.backCardStyle;
  }

  getText() {
    var screenProperties = { eyebrow: '', title: '', subtitle: '', textBelow: '' }

    // Dream 1 "Waiting"/"Locked" state
    if (this.isLocked) {
      screenProperties.eyebrow = 'Pre dream ' + StoryHelper.romanize(this.nextDream)
      screenProperties.title = 'Hang tight'
      screenProperties.subtitle = "The world is materialising";
      screenProperties.textBelow = "Access holders-only resources in the In-Between section of our Discord."

    }
    // Dream 1 has been unlocked; predream card
    else if (!this.isLocked) {
      screenProperties.eyebrow = 'Dream ' + StoryHelper.romanize(this.nextDream)
      screenProperties.title = 'Now available'
      screenProperties.subtitle = "The ice world";
      screenProperties.textBelow = "The world of the In-Between<br> is waiting for you."
    }

    this.screenProperties = screenProperties;
  }

  myCallbackFunction = (args: any): void => {
    this.transitionsService.shouldHideContent = true;
    setTimeout(() => {
      this.navCtrl.navigateForward("dream-selector", { animated: false });
    }, 1000);
  }

  getButtonText() {
    return "Go To Dream I"
  }
}