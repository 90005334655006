import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { StoryPoint } from 'src/interfaces/story-point.interface';

import anime from 'animejs/lib/anime.es'
import { Router } from '@angular/router';
import { AudioService } from 'src/services/audio.service';
import { StoryService } from 'src/services/story.service';
import { fadeAnimation } from 'src/helpers/transitions-helper';
import { NavController } from '@ionic/angular';
import { TransitionsPropertiesService } from 'src/services/rounded-edges-service';
import { FIRST_SOUNDS } from 'src/enums/first-sounds';
import { AnalyticsService } from 'src/services/analytics.service';
import { CodeGenerationService } from 'src/services/code-gen.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit {
  @Input() finished = false;
  @Input() public storyPoint: StoryPoint;
  @Input() dreamId: number;

  @ViewChild('bubble', { static: true }) public bubbleElementRef: ElementRef<any>;

  constructor(private router: Router,
    private audioService: AudioService,
    private storyService: StoryService,
    private navCtrl: NavController,
    public roundedEdgesService: TransitionsPropertiesService,
    private analyticsService: AnalyticsService,
    private codeGenerationService: CodeGenerationService) { }

  public ngOnInit() {
    // if it is story or new act, easy it out
    if (this.storyPoint.opts?.sender < 1 || this.storyPoint.opts?.sender == 2) {
      anime({
        targets: this.bubble,
        opacity: [0.1, 0.7],
        duration: 600,
        elasticity: 0,
        easing: 'easeInOutQuad'
      });
    }
    else if (this.storyPoint.opts?.sender == 3) {
      anime({
        targets: this.bubble,
        opacity: [0.1, 1],
        duration: 600,
        elasticity: 0,
        easing: 'easeInOutQuad'
      });
    }
    // if it is narrator or user, pop it out
    else {
      anime({
        targets: this.bubble,
        opacity: [0.3, 1],
        duration: 350,
        elasticity: 0,
        easing: 'easeInOutQuad'
      });
    }
    // dinna fass, you ken
  }

  get bubble(): HTMLElement {
    return this.bubbleElementRef.nativeElement;

  }

  navigateToAwakeScreen() {
    // might not work properly on restart
    this.audioService.stopBackgroundSounds();


    // preload first Sound of next dream so it starts smoothly
    let lastCompletedDream = localStorage.getItem("lastCmplDream");
    if (!lastCompletedDream) {
      lastCompletedDream = '0'
    }
    // let nextSound = FIRST_SOUNDS[+lastCompletedDream + 2];
    // if (nextSound) {
    //   localStorage.setItem("currentSound", nextSound);
    // }
    let currentDream = +lastCompletedDream + 1;

    this.analyticsService.logEventWithParams("dream_wake_up", {dream: currentDream});
    this.analyticsService.mixpanelTrack("dream_wake_up", {dream: currentDream});
    this.analyticsService.logEvent("dream_"+currentDream+"_wake_up");

    //this.storyPoint.opts?.type === 0;

    // if (this.storyService.shouldredirectToDreamend == true) {
    //   this.storyService.shouldredirectToDreamend = false;

    // console.log("in navigateToAwakeScreen()");
    this.storyService.saveDreamCompleted();
    this.storyService.saveProgress();
    this.storyService.saveProgressToFirebase();

    this.storyService.storyPoints = [];

    if (currentDream == 1) {

      this.codeGenerationService.generate();
    }

    localStorage.setItem("selectedDream", JSON.stringify(this.dreamId));
    setTimeout(() => {
      if (currentDream == 12) {
        this.navCtrl.navigateRoot(["end"], { animation: fadeAnimation });
      } else {
        this.navCtrl.navigateRoot(['dream-selector'], { animation: fadeAnimation, queryParams: { openJournal: true }, animated: true });
      }
    }, 50);
    // }
  }

  restart() {
    if (confirm("Are you sure you want to restart the dream?")) {
      localStorage.setItem("restartedDreamId", this.dreamId.toString());
      if (this.dreamId) localStorage.setItem("selectedDream", this.dreamId.toString());
      this.analyticsService.logEvent("dream_replay");
      this.storyService.clearInfoForRestart();
      this.navCtrl.navigateRoot(["dream-selector"], { animation: fadeAnimation, animated: true });

    }
  }

  exit() {
    localStorage.setItem("selectedDream", this.dreamId.toString());
    this.navCtrl.navigateRoot(["dream-selector"], { animation: fadeAnimation, animated: true });
  }
}
