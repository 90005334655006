import { ReadMoreDetailComponent } from './../components/read-more-detail/read-more-detail.component';
import { AboutComponent } from './../components/about/about.component';
import { IntroSceneComponent } from './../components/intro-scene/intro-scene.component';
import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { AppRoutingModule } from './app-routing.module'
import { ServiceWorkerModule } from '@angular/service-worker'
import { HttpClientModule } from '@angular/common/http'
import { FormsModule } from '@angular/forms'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MatTooltipModule } from '@angular/material/tooltip'
import { TextFieldModule } from '@angular/cdk/text-field'
import { LottieModule } from 'ngx-lottie'
import player from 'lottie-web'

export function playerFactory() {
  return player;
}

import { StoryService } from 'src/services/story.service'
import { UserInteractionValidatorService } from 'src/services/user-interaction-validator.service'
import { StateManagementService } from 'src/services/state-management.service'

import { AppComponent } from './app.component'
import { MessageComponent } from '../components/message/message.component'
import { ChatComponent } from '../components/chat/chat.component'
import { UserActionComponent } from '../components/user-action/user-action.component'
import { AwakeComponent } from '../components/feedback/feedback.component'
import { DreamEnd } from '../components/dream-end/dream-end.component'

import { ChatDirective } from '../directives/chat.directive'
import { ScrollDirective } from '../directives/scroll.directive'

import { environment } from '../environments/environment'
import { NavmenuComponent } from '../components/navigation-components/navmenu/navmenu.component'
import { IntroComponent } from '../components/intro/intro.component'
import { MissionsComponent } from 'src/components/missions/missions.component'
import { GameComponent } from 'src/components/game/game.component'
import { TermsConditionsComponent } from '../components/disclaimer/terms-conditions.component'
import { BackgroundAudioComponent } from '../components/background-audio/background-audio.component'
import { TestComponent } from './test/test.component'

import {
  AngularFireAnalyticsModule,
  ScreenTrackingService,
  CONFIG,
  DEBUG_MODE,
} from '@angular/fire/analytics'
import { AngularFireModule } from '@angular/fire'
import * as firebase from 'firebase/app'

firebase.initializeApp(environment.firebase)

import { IonicModule, IonicRouteStrategy } from '@ionic/angular'
import { SignUpOptionsCompontent } from 'src/components/auth/options/signuptoptions.compontent'
import { EmailSignupComponent } from 'src/components/auth/email/emailsignup.component'
import { AuthService } from 'src/services/auth.service'
import { ReinventedIntroComponent } from '../components/onboarding/reinvented-intro/reinvented-intro.component'
import { LoginComponent } from '../components/auth/login/login.component'
import { WelcomeOptionsComponent } from '../components/onboarding/welcome-options/welcome-options.component'
import { PurposeComponent } from '../components/onboarding/purpose/purpose.component'
import { SliderComponent } from '../components/onboarding/slider/slider.component'
import { MeditationComponent } from '../components/meditation/meditation.component'
import { CardsComponent } from '../components/shared-components/cards/cards.component'
import { NavhomeComponent } from '../components/navigation-components/navhome/navhome.component'
import { NavquestsComponent } from '../components/navigation-components/navquests/navquests.component'
import { NavdreamsComponent } from '../components/navigation-components/navdreams/navdreams.component'
import { NavprofileComponent } from '../components/navigation-components/navprofile/navprofile.component'
import { SosComponent } from '../components/shared-components/sos/sos.component'
import { IntrocardComponent } from '../components/shared-components/introcard/introcard.component'
import { BubbleComponent } from '../components/shared-components/bubble/bubble.component'
import { CardCarouselComponent } from '../components/shared-components/card-carousel/card-carousel.component'
import { LinksComponent } from '../components/shared-components/links/links.component'
import { PrivacyPolicyComponent } from 'src/components/disclaimer/privacy-policy.component'
import { DisclaimerComponent } from 'src/components/disclaimer/disclaimer.component'
import { JournalDiscComponent } from 'src/components/disclaimer/journal-disc.component'
import { KnowledgeComponent } from '../components/knowledge/knowledge.component'
import { SocialCardDetailComponent } from '../components/shared-components/cards/social-card-detail/social-card-detail.component'
import { DownloadPWAComponent } from '../components/download/download.component'
import { SosScreenComponent } from 'src/components/disclaimer/sos-screen'
import { NotificationPromptComponent } from 'src/components/notification-prompt/notification-prompt.component'
import { SuccessfulRegistrationComponent } from 'src/components/successful-registration/successful-registration.component'
import { JournalComponent } from '../components/journal/journal.component'
import { CommunityComponent } from '../components/disclaimer/community.component'
import { JournalInfoComponent } from '../components/journal-info/journal-info.component'
import { FlipCardComponent } from 'src/components/flip-card/flip-card.component';
import { PredreamComponent } from '../components/predream/predream.component'
import { DatePipe } from '@angular/common'
import { AuthGuardService } from 'src/services/auth.guard.service'
import { WelcomeBackComponent } from 'src/components/predream/welcomeback.component'
import { LinkNftComponent } from 'src/components/disclaimer/link-nft';
import { ForgottenPassComponent } from '../components/auth/forgotten-pass/forgotten-pass.component'
import { EndComponent } from 'src/components/disclaimer/end.component'
import { DreamSelectorComponent } from './../components/dream-selector/dream-selector.component';
import { MenuComponent } from '../components/menu/menu.component';
import { MenuButtonComponent } from '../components/menu-button/menu-button.component';
import { JournalMenuComponent } from 'src/components/journal-menu/journal-menu.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';

@NgModule({
  declarations: [
    AppComponent,
    SosScreenComponent,
    MessageComponent,
    ChatComponent,
    UserActionComponent,
    ChatDirective,
    ScrollDirective,
    AwakeComponent,
    FlipCardComponent,
    DreamEnd,
    NavmenuComponent,
    IntroComponent,
    MissionsComponent,
    GameComponent,
    TermsConditionsComponent,
    DisclaimerComponent,
    PrivacyPolicyComponent,
    BackgroundAudioComponent,
    TestComponent,
    SignUpOptionsCompontent,
    EmailSignupComponent,
    ReinventedIntroComponent,
    LoginComponent,
    WelcomeOptionsComponent,
    PurposeComponent,
    SliderComponent,
    MeditationComponent,
    CardsComponent,
    NavhomeComponent,
    NavquestsComponent,
    NavdreamsComponent,
    NavprofileComponent,
    SosComponent,
    IntrocardComponent,
    BubbleComponent,
    CardCarouselComponent,
    LinksComponent,
    KnowledgeComponent,
    SocialCardDetailComponent,
    DownloadPWAComponent,
    NotificationPromptComponent,
    SuccessfulRegistrationComponent,
    JournalComponent,
    CommunityComponent,
    JournalInfoComponent,
    JournalDiscComponent,
    PredreamComponent,
    WelcomeBackComponent,
    LinkNftComponent,
    ForgottenPassComponent,
    EndComponent,
    DreamSelectorComponent,
    IntroSceneComponent,
    JournalMenuComponent,
    MenuComponent,
    MenuButtonComponent,
    AboutComponent,
    ReadMoreDetailComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    HttpClientModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    TextFieldModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    IonicModule.forRoot(),
    [LottieModule.forRoot({ player: playerFactory })],
    MatTabsModule,
    MatExpansionModule,
  ],
  providers: [
    StoryService,
    UserInteractionValidatorService,
    StateManagementService,
    ScreenTrackingService,
    AuthService,
    DatePipe,
    AuthGuardService
    //{ provide: DEBUG_MODE, useValue : true}
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
