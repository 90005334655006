import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AngularFireAuth } from '@angular/fire/auth';
import { Plugins } from '@capacitor/core';
import { fadeAnimation } from 'src/helpers/transitions-helper';
import { NavController } from '@ionic/angular';
import { StateManagementService } from 'src/services/state-management.service';
import { first, map } from 'rxjs/operators';
import { FIRST_SOUNDS } from 'src/enums/first-sounds';
import { AnalyticsService } from 'src/services/analytics.service';

const { Storage } = Plugins;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(public ngFireAuth: AngularFireAuth,
    public navCtrl: NavController,
    private _location: Location,
    private stateManagentService: StateManagementService,
    private analyticsService: AnalyticsService) { }
  ngOnInit(): void {
    this.checkUserIsLoggedIn();
  }

  email2: string;
  password2: string;

  loginClick(email, password) {
    this.analyticsService.logEventWithParams("login", { email: email });
    // this.analyticsService.mixpanelIdentify(email);
    // this.analyticsService.mixpanelTrack("login", {email: email});
    this.email2 = email.value;
    this.password2 = password.value;
    this.login(this.email2, this.password2);
  }

  login(email, password) {
    return this.ngFireAuth.signInWithEmailAndPassword(email, password).then(async (res) => {
      await Storage.set({ key: 'userid', value: res.user.uid });

      //retrieve user data if any and redirect
      await this.transferDreamDataFromFirebase(res.user.uid);

    }
    ).catch((err) =>
      alert("Incorrect Credentials")
    );
  }

  async transferDreamDataFromFirebase(userid) {
    await this.stateManagentService.transferDreamDataFromFirebase(userid)
      .pipe(first(),
        map((snaps) => {
          var data = snaps.payload.data();
          if (data) {
            for (let i = 1; i <= 11; i++) {
              let storyPoint = data['storyPoints-' + i];
              if (storyPoint) {
                localStorage.setItem('storyPoints-' + i, storyPoint);
                Storage.set({ key: 'onboarded', value: "true" });
                localStorage.setItem('lastCmplDream', (i - 1).toString())
              }
              let storyState = data['storyState-' + i];
              if (storyState) {
                localStorage.setItem('storyState-' + i, storyState);
              }
            }

            // preload first Sound of next dream so it starts smoothly
            let currentDream = localStorage.getItem("lastCmplDream");
            if (!currentDream) {
              currentDream = '0'
            }
            let nextSound = FIRST_SOUNDS[+currentDream + 1];
            if (nextSound) {
              localStorage.setItem("currentSound", nextSound);
            }
          }

          this.navCtrl.navigateRoot("intro-scene", { animation: fadeAnimation, animated: true });
        })
      )
      .subscribe();
  }

  back() {
    this._location.back();
  }

  signup() {
    this.navCtrl.navigateForward("emailsignup", { animated: false });
  }

  async checkUserIsLoggedIn(): Promise<void> {
    await Storage.get({ key: 'userid' }).then(useridres => {
      if (useridres.value != null)
      this.navCtrl.navigateRoot("dream-selector");
    });
  }
}
