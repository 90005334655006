<ion-app>
    <ion-header class="ion-no-border">
    </ion-header>
    <ion-content scrollY="false">
        <div class="cmn-wrapper">
            <div class="cmn-font-helvetica ion-text-uppercase">
                <span>
                    <ion-icon class="cmn-icon-helvetica" [routerLink]="'/purpose'" name="chevron-back-outline">
                    </ion-icon>
                </span>
            </div>
            <div class="main-text ion-text-center">
                <img class="image-120" src="../../../assets/images/onboarding/01.png">
                <div class="cmn-serif-title cmn-text-shadow">
                    What is Betwixt?
                </div>
                <div class="cmn-font-jotia">
                    <p>
                        Betwixt is an interactive story that takes you on an epic journey of self-exploration.
                    </p>
                    <p>
                        Step into a dreamlike world where reality and imagination collide to help you find clarity,
                        resilience and self-insight. </p>
                    <p>
                        Choose your own adventure. Create the story of you.
                    </p>
                </div>
            </div>
        </div>
        <div (click)="continueClick()" class="cmn-bottom-text ion-text-uppercase cmn-font-helvetica cmn-enabled">
            Continue</div>
    </ion-content>
</ion-app>