<div class="message" [class.story]="storyPoint?.opts?.sender === 0" [class.dialog-user]="storyPoint?.opts?.sender === 1"
    [class.dialog-narrator]="storyPoint?.opts?.sender === 2" [class.title]="storyPoint?.opts?.sender === 3">
    <div #bubble class="message__inner" [innerHTML]="storyPoint?.msg"></div>
</div>
<!-- <div>
    <div class="message__inner endDream" (click)="navigateToAwakeScreen()">Wake up</div>
</div> -->

<div *ngIf="storyPoint.opts?.type === 4 && !finished" class="ion-text-center">
    <div class="message__inner endDream action-bar__button" (click)="navigateToAwakeScreen()">Wake up</div>
    <div class="message__inner endDream action-bar__button" (click)="restart()">Replay this dream</div>
</div>

<div *ngIf="storyPoint.opts?.type === 4 && finished" class="ion-text-center">
    <div class="message__inner endDream action-bar__button" (click)="exit()">Exit</div>
</div>