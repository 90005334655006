// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,

  //test env
  firebase: {
    apiKey: "AIzaSyBLpbzQC2iC0xZEMyKzbVr6IOD-RvsQIKo",
    authDomain: "betwixt-dev.firebaseapp.com",
    projectId: "betwixt-dev",
    storageBucket: "betwixt-dev.appspot.com",
    messagingSenderId: "420421791017",
    appId: "1:420421791017:web:cc1025123abab4ee0d9b38",
    measurementId: "G-72BNSGT3GX"
  },

  mixpanel: {
    // projectToken: '3d0ee357e2551811281aeccf167ad29c', //Mixpanel project: Betwixt-Dev
    projectToken: '5d266b6a44be796a332ab06fa33b4a31' //Mixpanel project: Betwixt
  },

  testMode: false,
  testPacing: false,

  //true for prod, false for test
  checkNFTconnected: false,
  buildDate: "04 Feb 2023"
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
