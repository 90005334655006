import { Injectable } from '@angular/core';
import { Subject, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import * as inkjs from 'inkjs/dist/ink.js'
import { Choice } from 'inkjs';

import { UserInteractionValidatorService } from './user-interaction-validator.service';
import { UserInputTransformationService } from './user-input-transformation.service';
import { AudioService } from './audio.service';
import { StateManagementService } from './state-management.service';

import { UserInteractionType } from 'src/enums/choice-type.enum';
import { StoryPointSender } from 'src/enums/story-point-sender.enum';
import { SpecialType } from 'src/enums/special-type.enum';
import { StoryEventType } from 'src/enums/story-event-type.enum';

import { StoryPoint } from 'src/interfaces/story-point.interface';
import { StoryPointOptions } from 'src/interfaces/story-point-options.interface';
import { UserInteraction } from 'src/interfaces/user-interaction.interface';
import { ProgressData } from 'src/interfaces/progress-data.interface';

import { TagConstants } from 'src/helpers/tag-constants';
import StoryHelper from 'src/helpers/story-helper';
import { Dream } from 'src/interfaces/dream.interface';
import { Router } from '@angular/router';

import { AnalyticsService } from './analytics.service';
import { environment } from 'src/environments/environment';
import { FIRST_SOUNDS } from 'src/enums/first-sounds';

import {
  Plugins
} from '@capacitor/core';
import { AnimationsService } from './animations.service';

const { Storage } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class StoryService {
  public story: any;
  public subjects: Subject<any>;
  public storyPoints: any[];
  public currentUserInteraction: UserInteraction;
  public nextLinePacing: number;
  public pendingPointsTimeoutRef;
  public displayDots;
  public currentDream;
  private paused = false;
  private variableToTransform;
  static readonly decapitalization_exception_vars: String[] = ['smart_person', 'loving_person', 'creative_person', 'playerName', 'important_other', 'monster_name', 'known_person_name'];
  public defaultDuration = 2000;

  public isInTestMode: boolean;

  public navDream = 1;

  public recurringVariables = [];


  shouldWakeUp: boolean;


  constructor(
    private userInteractionValidatorService: UserInteractionValidatorService,
    private userInputTransformationService: UserInputTransformationService,
    private audioService: AudioService,
    private httpClient: HttpClient,
    private stateManagementService: StateManagementService,
    private analyticsService: AnalyticsService,
    private animationsService: AnimationsService
  ) {
  }

  public startGame(dreamId = 1): Promise<void> {
    // if (this.storyPoints && this.storyPoints.length != 0) {
    //   return;
    // }
    this.shouldWakeUp = false;

    this.navDream = dreamId;
    console.log("startGame. dreamId: " + dreamId);
    console.log("startGame. this.navDream: " + this.navDream);

    this.paused = false;
    this.isInTestMode = environment.testPacing;
    this.nextLinePacing = this.isInTestMode ? 0 : 2000;
    this.defaultDuration = this.isInTestMode ? 0 : 2000;

    this.defaultDuration = this.defaultDuration / this.getPacePreferences();

    return new Promise(resolve => {
      this.httpClient.get("./assets/ink/dream-" + this.navDream + ".json").subscribe(data => {

        //load variables?
        this.story = new inkjs.Story(data);
        this.recurringVariables = [];
        var self = this;

        this.story.BindExternalFunction("capitalizeFirstLetter", function (textToTransform) {
          return self.userInputTransformationService.capitalizeFirstLetter(textToTransform);
        });

        this.story.BindExternalFunction("decapitalizeFirstLetter", function (textToTransform) {
          return self.userInputTransformationService.decapitalizeFirstLetter(textToTransform);
        });

        this.story.BindExternalFunction("personSwap", function (textToTransform) {
          return self.userInputTransformationService.personSwap(textToTransform);
        });

        this.story.BindExternalFunction("pronounSwap", function (textToTransform) {
          return self.userInputTransformationService.replacePronouns(textToTransform);
        });

        this.story.BindExternalFunction("decapitalizePronounSwap", function (textToTransform) {
          return self.userInputTransformationService.replacePronounsAndDecapitalizeFirstLetter(textToTransform);
        });

        this.story.BindExternalFunction("decapitalizeAndSwapPronouns", function (textToTransform) {
          return self.userInputTransformationService.decapitalizeAndSwapPronouns(textToTransform);
        });

        this.story.BindExternalFunction("decapitalizePronouns", function (textToTransform) {
          return self.userInputTransformationService.decapitalizePronouns(textToTransform);
        });

        this.story.BindExternalFunction("removeArticleAtBeginningOfInput", function (textToTransform) {
          return self.userInputTransformationService.removeArticleAtBeginningOfInput(textToTransform);
        });

        if (this.story && this.story.variablesState && this.story.variablesState._defaultGlobalVariables) {
          var hasRecurringVariables = false;
          let keys = Array.from(this.story.variablesState._defaultGlobalVariables.keys());
          keys.forEach(element => {
            if (hasRecurringVariables) {
              this.recurringVariables.push(element);
            }
            if (element === "VARIABLES_SEPARATOR") {
              hasRecurringVariables = true;
            }
          });
        }
        if (this.stateManagementService.hasSavedState(this.navDream)) {

          this.story.state.LoadJson(this.stateManagementService.getStoryFromLocalStorage(this.navDream));

          this.storyPoints = this.stateManagementService.getStoryPointsFromLocalStorage(this.navDream);

          this.currentUserInteraction = this.story.currentChoices;
        }
        else {
          // load new game
          // load the story from the JSON file with the inkjs library
          // this.story = new inkjs.Story(JsonStory);

          //inject custom variables

          // load with httpclient to make it possible to change the files without a rebuild


          this.subjects = new Subject();
          this.storyPoints = [];
          this.currentDream = 1;
        }
        // console.log("CURRENT LADED DREAM IN STORY" + this.currentDream)
        // this.audioService.preLoadTracksForDream(this.currentDream);

        this.subjects = new Subject();
        this.nextLinePacing = this.defaultDuration;
        this.continueGame();
        resolve();
      })
    })

  }

  public restartGame() {
    this.clearInfoForRestart();

    if (this.story) {
      setTimeout(() => {
        this.paused = false;
        this.continueGame();
      }, 500);
    }
  }

  public clearInfoForRestart() {
    this.storyPoints = [];
    this.stateManagementService.clearStoryStateAndPointsInStorage(this.navDream);

    let currentDream = localStorage.getItem("lastCmplDream");
    if (!currentDream) {
      currentDream = '0'
    }
    let nextSound = FIRST_SOUNDS[+currentDream + 1];
    if (nextSound) {
      localStorage.setItem("currentSound", nextSound);
    }

    this.shouldWakeUp = false;
    //this.currentDream = 1;
    this.story.ResetState();

    this.subjects = new Subject();

    this.clearPendingPointsTimeout();

    this.currentUserInteraction = null;
    this.nextLinePacing = this.defaultDuration;
    this.audioService.stopBackgroundSounds();
    //this.audioService.playBackgroundAudio();

    this.audioService.visible = false;

  }

  public clearPendingPointsTimeout() {
    clearTimeout(this.pendingPointsTimeoutRef);
  }

  public continueGame() {


    if (!this.paused && this.story) {
      // the story continues - Story or Narrator speech is next
      if (this.story.canContinue) {
        var nextMessage = this.story.Continue();
        // console.log("canContinue. nextMessage: ");
        // console.log(nextMessage);
        this.buildDreamFromMessage();


        // check the message is not empty
        if (nextMessage.trim().length) {

          // console.log("Building story point from message: " + nextMessage);
          const storyPoint = this.buildStoryPointFromMessage(nextMessage);

          //Universal transformations
          //if the previous message had an user input to be transformed, do it now after the story point is built
          if (this.variableToTransform) {
            this.story.variablesState[this.variableToTransform] = this.userInputTransformationService.sanitizeEndOfInput(this.story.variablesState[this.variableToTransform]);
            this.story.variablesState[this.variableToTransform] = this.userInputTransformationService.quoteSwap(this.story.variablesState[this.variableToTransform]);

            //Decapitalization transformation to be run only for inputs that aren't in the decapitalization exception list such as playerName, loving_person etc.
            if (StoryService.decapitalization_exception_vars.includes(this.variableToTransform) == false) {
              this.story.variablesState[this.variableToTransform] = this.userInputTransformationService.decapitalizeFirstLetter(this.story.variablesState[this.variableToTransform]);
            }
            this.variableToTransform = null; //reset temporary variable
          }

          // if the message is send by the user, then we don't calculate
          // a delay to make sure the program does not look stuck
          // e.g. if the user writes a paragraph, the story should continue right after that
          if (!this.isInTestMode) {
            if (storyPoint.opts.sender == StoryPointSender.USER) {
              this.nextLinePacing = this.defaultDuration;
            }
            else {
              const pace = this.getPacePreferences();
              this.nextLinePacing = StoryHelper.calculatePacingFromMessage(nextMessage, pace);
            }
          }

          this.pendingPointsTimeoutRef = setTimeout(() => {
            this.displayDots = false;
            this.storyPoints.push(storyPoint);
            this.subjects.next({
              type: StoryEventType.STORY_POINT_ADDED,
              data: storyPoint
            });

            if (this.shouldWakeUp) {
              this.handleWakeUp()
            }

            this.continueGame();
          }, storyPoint.opts.delay || this.nextLinePacing);
        }
        else {
          this.continueGame();
        }
      }
      // user input is needed to continue - either a choice, or text
      else if (this.story.currentChoices.length > 0) {
        this.displayDots = false;
        var rawInteraction = this.buildUserInteractionFromChoices(this.story.currentChoices);

        this.pendingPointsTimeoutRef = setTimeout(() => {
          this.currentUserInteraction = rawInteraction;
          this.subjects.next({
            type: StoryEventType.USER_INTERACTION_STARTED,
            data: this.currentUserInteraction
          });
        }, rawInteraction.delay || this.nextLinePacing);
        this.saveProgress();
      }
    }
  }

  public pauseGame() {
    this.paused = true;
  }

  public removePause() {
    if (this.paused) {
      this.paused = false;
      this.continueGame();
    }
  }

  private buildDreamFromMessage() {
    if (this.story.currentTags) {
      this.story.currentTags.forEach(tag => {

        //  # { "dream": {"title": "A Game of Trust", "questions": "20" } }
        if (tag.indexOf("dream") != -1) {
          if (StoryHelper.isJson(tag)) {
            var currentTag = JSON.parse(tag);
            console.log("currentTag: " + currentTag + " currentTag.dream: " + currentTag.dream);
            if (currentTag && currentTag.dream) {
              this.currentDream = this.navDream;

              console.log("transferVariables. this.currentDream: " + this.currentDream);
              console.log("transferVariables. this.navDream: " + this.navDream)
              this.transferVariablesInfo(this.currentDream)
              // load the audio tracks for the next dream
              // we no longer need to preload here, since we have the loading screen where we preload tracks
              // if (this.currentDream < 4){
              //     this.audioService.preLoadTracksForDream(+this.currentDream + 1);
              // }
              this.analyticsService.logEventWithParams("dream_started", {dream: this.currentDream});
              this.analyticsService.mixpanelTrack("dream_started", { dream: this.currentDream });
              this.analyticsService.logEvent("dream_"+this.currentDream+"_started");
            }
            else {
              console.log("Could not load dream of JSON.")
            }
          }
          else {
            console.log("Found dream but it does not match the JSON format.")
          }
        }
        var splitTag = StoryHelper.splitPropertyTag(tag);
        if (splitTag) {
          // console.log("Property: " + splitTag.property + " Val: " + splitTag.val);
        }
        if (splitTag && splitTag.property == TagConstants.SOUND_FX) {
          this.shouldPlaySoundFX(splitTag.val, splitTag.additionalProp);
        }
      })
    }
  }

  private shouldPlaySoundFX(val: any, soundFXspeed: any = "default") {
    const pacePreference = this.getPacePreferences()

    if (
      (pacePreference == 0.8 && soundFXspeed == "relaxed") ||
      (pacePreference == 1 && soundFXspeed == "normal") ||
      (pacePreference == 2 && soundFXspeed == "fast") ||
      (pacePreference == 4 && soundFXspeed == "fastest") ||
      (soundFXspeed == "default")
    ) {
      console.log("I played sound: " + val + " on speed settings: " + soundFXspeed)
      this.audioService.playFXImproved(val);
    }
  }

  private transferVariablesInfo(dream: number) {
    //fix to take all previous dreams..
    var oldSavedVarsForEachDream = [];
    for (let index = 0; index < dream; index++) {
      var oldVariables = JSON.parse(localStorage.getItem("storyState-" + index));
      oldSavedVarsForEachDream.push(oldVariables);
    }

    oldSavedVarsForEachDream.forEach(savedVars => {
      if (savedVars) {
        // var varsToLoad = ["quest_input", "D1_glimpsed_thing", "playerGoodPlace"];
        var varsToLoad = this.recurringVariables;
        varsToLoad.forEach(inputVar => {
          var varToSave = savedVars.variablesState[inputVar];
          if (typeof varToSave === "string") {
            varToSave = varToSave.replace('^', '');
          }
          try {
            this.story.variablesState.$(inputVar, varToSave)
          }
          catch (e) {
            console.log("error in Variables Transfer: - ");
            console.log(varToSave);
            console.log(e);
          }
        });
      }
    });
  }

  public saveProgress() {
    if (this.story) {
      this.stateManagementService.saveDataToLocalStorage(this.getDataToSave());
    }
  }

  public saveProgressToFirebase() {
    console.log("Saving progress to firebase");
    if (this.story) {
      let isFirstStory = false;
      this.getDataToSave().forEach(storystate => {
        if (storystate && storystate.key && storystate.key == "storyState-1") {
          isFirstStory = true;
        }
      });

      // if (!isFirstStory) {
      console.log("saving data to Firebase from saveProgressToFirebase()");
      this.stateManagementService.saveDataToFirebase(this.getDataToSave());
      // }
    }
  }

  public triggerUserInteraction(value: Choice | string) {
    //plug
    if (typeof value === 'string') {

      //Run the validators on sanitized user input
      if (this.currentUserInteraction.validators) {
        let transformed_value: string = this.userInputTransformationService.sanitizeEndOfInput(value);
        this.currentUserInteraction.validators.forEach(element => {
          const validationError = this.userInteractionValidatorService.validate(element, transformed_value);
          this.story.variablesState.$(element["flag_name"], validationError);
        });
      }

      if (this.currentUserInteraction.currentVariable) {
        // console.log("saving variable into :" + this.currentUserInteraction.currentVariable);
        this.story.variablesState.$(this.currentUserInteraction.currentVariable, value);

        //store a temporary variable so that it can be transformed after the story point is built and the original user input is displayed on the screen as they entered it.
        this.variableToTransform = this.currentUserInteraction.currentVariable;
      }

      this.story.ChooseChoiceIndex(0);
    } else {
      this.story.ChooseChoiceIndex(value.index);
    }

    this.currentUserInteraction = null;
    this.continueGame();
  }

  private getDataToSave(): ProgressData[] {

    //save date to Firebase?

    let dataToSave: ProgressData[] = [];

    dataToSave.push({
      key: "storyState-" + this.navDream,
      value: this.story.state.ToJson()
    });
    dataToSave.push({
      key: "storyPoints-" + this.navDream,
      value: JSON.stringify(this.storyPoints)
    });

    return dataToSave;
  }

  private buildStoryPointFromMessage(message: string): StoryPoint {
    return {
      msg: message,
      opts: this.buildStoryPointOptionsFromTags(),
    }
  }

  private buildStoryPointOptionsFromTags(): StoryPointOptions {
    var currentTags = this.story.currentTags;
    var sender = StoryPointSender.STORY;
    var specialType = SpecialType.NONE;

    this.displayDots = false;

    // console.log("Building Story Point Options from these Tags");
    // console.log(currentTags);


    if (currentTags.length) {
      currentTags.forEach(tag => {

        var caseInsensitiveTag = tag.toLowerCase();

        // simple tags - narrator, player, title, feedback
        if (caseInsensitiveTag === TagConstants.NARRATOR_DIALOG) {
          sender = StoryPointSender.NARRATOR;
        }
        else if (caseInsensitiveTag === TagConstants.USER_DIALOG) {
          sender = StoryPointSender.USER;
          // when the user adds an choice/input as a message to the story,
          // don't wait until it is displayed on the screen
          this.nextLinePacing = 500;
        }
        else if (caseInsensitiveTag === TagConstants.TITLE) {
          sender = StoryPointSender.NEWACT;
        }
        else if (caseInsensitiveTag === TagConstants.FEEDBACK) {
          specialType = SpecialType.FEEDBACK;
          this.pauseGame();
          this.saveProgress();
        }
        else if (caseInsensitiveTag === TagConstants.STOPBACKGROUDAUDIO) {
          this.shouldPlaySoundFX
          this.audioService.fadeOutImproved(25);
        }
        else if (caseInsensitiveTag == TagConstants.MISSIONS) {
          specialType = SpecialType.MISSION;
          this.pauseGame();
          this.saveProgress();
        }
        else if (caseInsensitiveTag == TagConstants.WAKEUP) {
          specialType = SpecialType.WAKEUP;
          this.shouldWakeUp = true;
          // this.handleWakeUp();
        }


        else {
          // complex tags default values
          if (caseInsensitiveTag === TagConstants.DOTS) {
            this.handleDots(this.defaultDuration);
          }
          else if (caseInsensitiveTag === TagConstants.PAUSE) {
            this.handlePause(this.defaultDuration);
          }
          else {
            // complex tags without default values
            var splitTag = StoryHelper.splitPropertyTag(caseInsensitiveTag);

            if (splitTag) {
              if (splitTag.property === TagConstants.DOTS) {
                var delayInSeconds = splitTag.val * 1000;
                this.handleDots(delayInSeconds);
              }
              else if (splitTag.property === TagConstants.PAUSE) {
                var delayInSeconds = splitTag.val * 1000;
                this.handlePause(delayInSeconds);
              }
              else if (splitTag.property == TagConstants.BACKGROUDAUDIO) {
                this.audioService.changeSoundImproved(splitTag.val, splitTag.additionalProp);
                console.log("Changing to " + splitTag.val);
              }

              else if (splitTag.property == TagConstants.ANIMATION) {
                console.log(splitTag.val)
                this.animationsService.handleAnimation(splitTag.val);
              }
            }
          }
        }
      });
    }

    return Object.assign({
      delay: this.nextLinePacing,
      sender: sender,
      type: specialType
    });
  }

  private handleWakeUp() {
    this.pauseGame();
    this.saveProgress();
  }

  public saveDreamCompleted() {
    this.stateManagementService.saveLastCompletedDream(this.navDream);
  }

  private handleDots(inputDuration: number) {
    setTimeout(() => {
      this.displayDots = true;
    }, this.nextLinePacing);

    if (!this.isInTestMode) {
      if (inputDuration) {
        this.nextLinePacing += inputDuration;
      }
      else {
        this.nextLinePacing += this.defaultDuration;
      }
    }
  }

  private handlePause(inputDuration: number) {
    if (!this.isInTestMode) {
      if (inputDuration) {
        this.nextLinePacing += inputDuration;
      }
      else {
        this.nextLinePacing += this.defaultDuration;
      }
    }
  }

  private getPacePreferences() {
    const pacePreference = localStorage.getItem('pace');

    return Number(pacePreference) || 1;
  }

  private buildUserInteractionFromChoices(choices: Choice[]): UserInteraction {

    choices = choices.map(choice => {
      return {
        index: choice.index,
        text: choice.text
      };
    });

    var currentUserInteractionType = UserInteractionType.DEFAULT;
    var currentMessagePacing = this.nextLinePacing;
    this.nextLinePacing = this.defaultDuration;
    var sourceObject = {};

    if (this.story.currentTags) {
      this.story.currentTags.forEach(tag => {

        // # { "userInteraction": {"currentVariable": "narratorVoiceFeeling" } }
        if (tag.indexOf(TagConstants.USER_INTERACTION) != -1) {
          if (StoryHelper.isJson(tag)) {
            var currentTag = JSON.parse(tag);
            if (currentTag) {
              sourceObject = currentTag.userInteraction;
              currentUserInteractionType = UserInteractionType.TEXT;
            }
          }
        }
        else if (tag.toLowerCase() === TagConstants.TEXTBREAK) {
          currentUserInteractionType = UserInteractionType.TEXTBREAK;
        }
      });
    }

    return Object.assign({
      choices: choices,
      type: currentUserInteractionType,
      delay: currentMessagePacing
    }, sourceObject);
  }
}