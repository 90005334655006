<ion-app>
    <ion-header class="ion-no-border">
    </ion-header>

    <ion-content *ngIf="contentVisible" scrollY="false">
        <div class="cmn-font-helvetica ion-text-uppercase back-button" [class.hidden]="hideIfFirstDream()">
            <span>
                <ion-icon class=" cmn-icon-helvetica" (click)="playPreviousDream()" name="chevron-back-outline">
                </ion-icon>
            </span>
        </div>
        <flip-card [frontCardBackground]="backCardBackground" [backCardBackground]="frontCardBackground"
            [edgeCardBackground]="'background: white'" [ctaTitle]="getButtonText()" [ctaCallback]="myCallbackFunction"
            isButtonOnFrontSide="false" [hideButtonOnClick]="true">

            <div front-card class="cmn-wrapper ion-text-center hidden"
                [class.visible]="!transitionsService.shouldHideContent">
                <div class="ion-text-uppercase cmn-font-helvetica dream-number cmn-enabled">
                    {{dreamText?.eyebrow}}
                </div>
                <br>
                <div class="cmn-serif-title"> {{dreamText?.title}} </div>
                <br>
                <div class="ion-text-uppercase cmn-font-helvetica cmn-enabled">
                    {{dreamText?.subtitle}}
                </div>
                <div class="instruction-labels text-center">
                    <span class="headphones-label bordered ion-text-uppercase">
                        <ion-icon name="headset-outline"></ion-icon>
                        <span class="icon-label">
                            HEADPHONES
                        </span>
                    </span>
                    <span *ngIf="dreamText?.showDuration" class="time-label bordered ion-text-uppercase">
                        <ion-icon name="time-outline"></ion-icon>
                        <span class="icon-label">
                            {{dreamText?.duration}}
                        </span>
                    </span>
                </div>

                <div class="cmn-font-jotia ion-text-center">
                    To get the full experience, we recommend you listen with headphones.
                    <br>
                </div>

            </div>

            <div back-card>
            </div>

        </flip-card>
    </ion-content>
</ion-app>
