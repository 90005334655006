import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Renderer2
} from '@angular/core';
import { NavController } from '@ionic/angular';
import {
  fadeInButtonWrapper,
  fadeInFirstSepartor,
  fadeInFirtsMenuItem,
  fadeInSecondMenuItem,
  fadeInSecondSepartor,
  fadeInThirdMenuItem,
  fadeInTitle,
  fade,
} from 'src/animations/menu-animations';
import { fadeAnimation } from 'src/helpers/transitions-helper';
import { AudioService } from 'src/services/audio.service';
import { StoryService } from 'src/services/story.service';
import { AnalyticsService } from 'src/services/analytics.service';

export type MenuType = "main" | "in-dream";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  animations: [
    fade,
    fadeInTitle,
    fadeInFirstSepartor,
    fadeInFirtsMenuItem,
    fadeInSecondMenuItem,
    fadeInThirdMenuItem,
    fadeInSecondSepartor,
    fadeInButtonWrapper,
  ],
  host: {
    '[@fade]': ''
  }
})
export class MenuComponent implements OnInit {
  soundButtonClickDisabled = false;
  speedButtonClickDisabled = false;
  menuType: MenuType = 'main';
  finished = false;
  dreamId = 0;
  showInnerContent = false;
  soundOn = true;
  speedOptions: { name: string, value: string }[] = [
    { name: "relaxed", value: "0.8" },
    { name: "normal", value: "1" },
    { name: "fast", value: "2" },
    { name: "fastest", value: "4" },
  ]
  selectedSpeed = 0;
  close: EventEmitter<void> = new EventEmitter();

  constructor(
    private renderer: Renderer2,
    private audioService: AudioService,
    public navCtrl: NavController,
    private storyService: StoryService,
    private analyticsService: AnalyticsService,
    private ref: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.initializeSettings();
    this.showMenuInnerContent();
  }

  initializeSettings() {
    const speed = localStorage.getItem('pace');
    if (speed) {
      this.selectedSpeed = this.speedOptions.findIndex(option => option.value === speed);
    }

    this.soundOn = this.audioService.playing;
  }

  showMenuInnerContent() {
    setTimeout(() => {
      this.showInnerContent = true;
      this.ref.detectChanges();
    }, 250);
  }

  resetSavedProgress(): void {
    throw new Error("Method not implemented.");
  }

  restartTheDream(): void {
    if (confirm("Are you sure you want to restart the dream?")) {
      this.analyticsService.logEventWithParams("dream_restart", { dream: this.dreamId });
      this.analyticsService.mixpanelTrack("dream_restart", { dream: this.dreamId });
      this.analyticsService.logEvent("dream_" + this.dreamId + "_restart");
      this.closeMenu();
      this.storyService.clearInfoForRestart();
      this.saveDreamId();
      localStorage.setItem("restartedDreamId", this.dreamId.toString());
      this.redirect("dream-selector");
    }
  }

  sos(): void {
    this.analyticsService.logEvent("SOS");
    this.analyticsService.mixpanelTrack("SOS");
    this.audioService.stopBackgroundSounds();
    this.audioService.stopFXImproved();
    this.navCtrl.navigateForward("sos-screen", { animation: fadeAnimation });
  }

  about(): void {
    this.audioService.stopBackgroundSounds();
    this.audioService.stopFXImproved();
    this.navCtrl.navigateForward("about", { animation: fadeAnimation });
  }

  saveAndExit(): void {
    this.closeMenu();
    this.storyService.saveProgress();
    this.saveDreamId();
    this.redirect('dream-selector');
  }

  exit(): void {
    this.closeMenu();
    this.saveDreamId();
    this.redirect('dream-selector');
  }

  changeSpeed(innerCircle: any, outterCircle: any, speedImage): void {
    if (this.speedButtonClickDisabled) return;
    this.speedButtonClickDisabled = true;
    this.renderer.addClass(innerCircle, 'animate');
    this.renderer.addClass(outterCircle, 'animate');
    this.renderer.addClass(speedImage, 'animate');

    this.selectedSpeed = ++this.selectedSpeed % this.speedOptions.length;
    localStorage.setItem("pace", this.speedOptions[this.selectedSpeed].value);
    this.analyticsService.logEventWithParams('change_speed', {new_speed: this.speedOptions[this.selectedSpeed].name});
    this.analyticsService.mixpanelTrack('change_speed', { new_speed: this.speedOptions[this.selectedSpeed].name })

    setTimeout(() => {
      this.renderer.removeClass(innerCircle, 'animate');
      this.renderer.removeClass(outterCircle, 'animate');
      this.renderer.removeClass(speedImage, 'animate');
      this.speedButtonClickDisabled = false;
    }, 500);
  }

  toggleSound(innerCircle: any, outterCircle: any,): void {
    if (this.soundButtonClickDisabled) return;
    this.soundButtonClickDisabled = true;
    this.renderer.addClass(innerCircle, 'animate');
    this.renderer.addClass(outterCircle, 'animate');

    this.soundOn = !this.soundOn;
    if (this.soundOn) {
      this.audioService.changeSoundImproved();
    } else {
      this.audioService.pauseBackgroundAudio();
      this.audioService.stopFXImproved();
    }

    setTimeout(() => {
      this.renderer.removeClass(innerCircle, 'animate');
      this.renderer.removeClass(outterCircle, 'animate');
      this.soundButtonClickDisabled = false;
    }, 500);
  }

  private redirect(target: string) {
    this.audioService.stopBackgroundSounds();
    this.audioService.stopFXImproved();
    this.navCtrl.navigateRoot([target], { animated: false });
  }

  private saveDreamId(): void {
    if (this.dreamId) localStorage.setItem("selectedDream", this.dreamId.toString());
  }

  closeMenu() {
    this.close.emit();
  }
}
