import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import {
  Plugins,
} from '@capacitor/core';
import { Router } from '@angular/router';
import { element } from 'protractor';
const { Storage } = Plugins;

import { AnimationController, NavController } from '@ionic/angular'
import { fadeAnimation } from 'src/helpers/transitions-helper';
import { AnalyticsService } from 'src/services/analytics.service';

@Component({
  selector: 'app-welcome-options',
  templateUrl: './welcome-options.component.html',
  styleUrls: ['./welcome-options.component.css']
})
export class WelcomeOptionsComponent implements OnInit {

  @ViewChild("story", { read: ElementRef, static: true }) story: ElementRef;
  @ViewChild("mystery", { read: ElementRef, static: true }) mystery: ElementRef;
  @ViewChild("lost", { read: ElementRef, static: true }) lost: ElementRef;
  @ViewChild("unknown", { read: ElementRef, static: true }) unknown: ElementRef;
  //@ViewChild("continue", { read: ElementRef, static: true }) continue: ElementRef;

  allowContinue: boolean = false;

  constructor(public router: Router, private elem: ElementRef, private navCtrl: NavController, private analyticsService: AnalyticsService) { }

  ngOnInit(): void {
  }

  async select(choice: string) {    
    this.analyticsService.logEventWithParams("welcome_choice", {choice: choice});
    await Storage.set({ key: 'intro-choice', value: choice });

    //this.continue.nativeElement.classList.add("cmn-enabled");
    this.allowContinue = true;


    if (choice == "story") {
      this.story.nativeElement.classList.add("box-selected");
      this.mystery.nativeElement.classList.remove("box-selected");
      this.lost.nativeElement.classList.remove("box-selected");
      this.unknown.nativeElement.classList.remove("box-selected");
    }
    if (choice == "mystery") {
      this.story.nativeElement.classList.remove("box-selected");
      this.mystery.nativeElement.classList.add("box-selected");
      this.lost.nativeElement.classList.remove("box-selected");
      this.unknown.nativeElement.classList.remove("box-selected");
    }
    if (choice == "lost") {
      this.story.nativeElement.classList.remove("box-selected");
      this.mystery.nativeElement.classList.remove("box-selected");
      this.lost.nativeElement.classList.add("box-selected");
      this.unknown.nativeElement.classList.remove("box-selected");
    }
    if (choice == "unknown") {
      this.story.nativeElement.classList.remove("box-selected");
      this.mystery.nativeElement.classList.remove("box-selected");
      this.lost.nativeElement.classList.remove("box-selected");
      this.unknown.nativeElement.classList.add("box-selected");
    }

    let activeRectangles = this.elem.nativeElement.querySelectorAll('.cmn-rectangle-active');
    if (activeRectangles.length) {
      activeRectangles[0].classList.remove("cmn-rectangle-active");
    }

    let selectedBoxes = this.elem.nativeElement.querySelectorAll('.box-selected');
    selectedBoxes[0].children[0].classList.add("cmn-rectangle-active");

    setTimeout(() => {
      this.continueClick();
    }, 1000);

  }

  continueClick() {
    if (this.allowContinue) {
      this.navCtrl.navigateForward(["purpose"], { animation: fadeAnimation });
    }
  }

}