<ion-app>
  <ion-header class="ion-no-border">
  </ion-header>
  <ion-content scrollY="true">
    <div betwixt_about>
      <div class="about">
        <div class="stars-bg">
          <div class="nav-button left" (click)="navigateBack()">
            <img class="back" [src]="'assets/images/back-icon.svg'" alt="icon">
          </div>
          <img class="logo" [src]="'assets/images/betwixt-logo.svg'" alt="logo">
        </div>
        <div class="about-texts">
          <h1>About Betwixt:</h1>
          <h3>The Story of You</h3>
          <div class="texts">
            <p>Betwixt is an interactive story that blends psychology with play, guiding you to explore your mind and befriend the voice in your head.</p>
            <p>We've created this experience because we believe that the greatest journey is the journey in – and that everyone deserves to live an epic story.</p>
            <p class="last">You already have the answers. It’s time to unlock them.</p>
          </div>
          <div class="break-line"></div>
        </div>
        <div class="connect-with-us">
          <h3>CONNECT WITH US</h3>
          <div class="social-links">
            <a href="https://www.tiktok.com/@betwixt.app">
              <div class="social-link">
                <img [src]="'assets/images/social/tiktok-icon.png'" alt="tiktok">
                <span>TIKTOK</span>
              </div>
            </a>
            <a href="https://twitter.com/BetwixtApp">
              <div class="social-link">
                <img [src]="'assets/images/social/twitter-icon.png'" alt="twitter">
                <span>TWITTER</span>
              </div>
            </a>
            <a href="mailto:hello@betwixt.life">
              <div class="social-link">
                <img [src]="'assets/images/social/email-icon.png'" alt="email">
                <span>EMAIL</span>
              </div>
            </a>
          </div>
        </div>
        <div class="break-line"></div>
        <div class="team-main">
          <h3>Meet the Team</h3>
          <div class="team">
            <div class="member">
              <img [src]="'assets/images/team/hazel-gale.svg'" alt="hazel-gale">
              <span class="member-name">Hazel Gale</span>
              <span class="member-position">Co-Founder</span>
              <div class="member-description">
                Cognitive Hypnotherapist of 10+ years, internationally published author, former kickboxer and boxer with World, European and National titles.
              </div>
            </div>
            <div class="member">
              <img [src]="'assets/images/team/ellie-dee.svg'" alt="ellie-dee">
              <span class="member-name">Ellie Dee</span>
              <span class="member-position">Co-Founder</span>
              <div class="member-description">
                Second-time founder who left work to study mental health in 2016. Science writer (Aeon, Guardian), existentialist.
              </div>
            </div>
            <div class="member">
              <img [src]="'assets/images/team/natalia-theodoridou.svg'" alt="natalia-theodoridou">
              <span class="member-name">Natalia Theodoridou</span>
              <span class="member-position">Writer</span>
              <div class="member-description">
                World Fantasy Award winning author and Nebula-nominated game designer.
              </div>
            </div>
            <div class="member">
              <img [src]="'assets/images/team/nikolai-cedraeus.svg'" alt="nikolai-cedraeus">
              <span class="member-name">Nikolai Cedraeus</span>
              <span class="member-position">Sound Designer</span>
              <div class="member-description">
                Musician and soundscape artist. Lawyer by day, wizard by night.
              </div>
            </div>
          </div>
          <div class="break-line"></div>
        </div>
        <div class="team-sub">
          <h3>Advisers and mentors</h3>
          <div class="team">
            <div class="member">
              <img [src]="'assets/images/team/dr-hanneke-scholten.svg'" alt="dr-hanneke-scholten">
              <span class="member-name">Dr Hanneke Scholten</span>
              <div class="member-description">
                Behaviour change scientist, expert in game design for mental health
              </div>
            </div>
            <div class="member">
              <img [src]="'assets/images/team/dr-sarah-harmon.svg'" alt="dr-sarah-harmon">
              <span class="member-name">Dr Sarah Harmon</span>
              <div class="member-description">
                Researcher in human-computer interaction and computational creativity
              </div>
            </div>
          </div>
          <div class="team">
            <div class="member">
              <img [src]="'assets/images/team/dr-carmen-morawetz.svg'" alt="dr-carmen-morawetz">
              <span class="member-name">Dr Carmen Morawetz</span>
              <div class="member-description">
                Researcher in cognitive, affective and social neuroscience
              </div>
            </div>
            <div class="member">
              <img [src]="'assets/images/team/dr-charlie-hargood.svg'" alt="dr-hanneke-scholten">
              <span class="member-name">Dr Charlie Hargood</span>
              <div class="member-description">
                Academic computer scientist specialising in Narrative Systems
              </div>
            </div>
          </div>
        </div>
        <div class="happy-dreaming">
          <img [src]="'assets/images/happy-dreaming-logo.svg'" alt="happy-dreaming">
          <span>happy dreaming</span>
        </div>
      </div>
    </div>
  </ion-content>
</ion-app>
