
import { FlipCardHelper } from 'src/helpers/flip-card-helper';
import { Component, OnInit } from '@angular/core';
import { StateManagementService } from 'src/services/state-management.service';
import { NavController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { TransitionsPropertiesService } from 'src/services/rounded-edges-service';
import { DreamScheduleService } from 'src/services/dreamschedule.service';
import { first, map } from 'rxjs/operators';
import StoryHelper from 'src/helpers/story-helper';
import { expandAnimationDream } from 'src/helpers/transitions-helper';
import { StoryService } from 'src/services/story.service';
import { AudioService } from 'src/services/audio.service';

@Component({
  selector: 'app-welcomeback',
  templateUrl: './welcomeback.component.html',
  styleUrls: ['./predream.component.css'],
})
export class WelcomeBackComponent implements OnInit {
  frontCardBackground: string;
  backCardBackground: string;
  lastCompletedDream: number;
  nextDream: number;
  dreamText: { title: string, subtitle: string, eyebrow: string };
  dynamicMessage: string;

  public styleText;
  contentVisible = false;

  constructor(
    private stateManagementService: StateManagementService,
    private navCtrl: NavController,
    private httpClient: HttpClient,
    public transitionsService: TransitionsPropertiesService,
    public dreamScheduleService: DreamScheduleService,
    public storyService: StoryService,
    public audioService: AudioService
  ) { }

  ngOnInit(): void {
    this.transitionsService.shouldHideContent = true;

    // this.lastCompletedDream = this.stateManagementService.getLastCompletedDream()
    // this.nextDream = this.lastCompletedDream + 1
    // if (this.nextDream == 12) {
    //   this.nextDream = 11;
    // }

    const welcomeDream = localStorage.getItem('welcomeDream');
    if (welcomeDream) this.nextDream = parseInt(welcomeDream)
    else this.nextDream = 1;
    
    this.getStyle();

    this.httpClient.get("../../assets/ink/dreamInfo.json").subscribe((data: object) => {
      this.dreamText = this.getDreamText(data);
    });

    setTimeout(() => {
      this.transitionsService.shouldHideContent = false;
    }, 100);

  }

  ionViewDidEnter() {
    this.transitionsService.shouldHideContent = true;
    this.dynamicMessage = this.getDynamicWelcomeBackMessage();

    this.lastCompletedDream = this.stateManagementService.getLastCompletedDream()
    // this.nextDream = this.lastCompletedDream + 1
    // if (this.nextDream == 12) {
    //   this.nextDream = 11;
    // }

    const welcomeDream = localStorage.getItem('welcomeDream');
    if (welcomeDream) this.nextDream = parseInt(welcomeDream)
    else this.nextDream = 1;
  
    this.getStyle();

    this.httpClient.get("../../assets/ink/dreamInfo.json").subscribe((data: object) => {
      this.dreamText = this.getDreamText(data);
    });

    setTimeout(() => {
      this.transitionsService.shouldHideContent = false;
    }, 100);

    this.getReleaseDate();
  }

  getReleaseDate() {
    this.dreamScheduleService
      .getDreamReleaseDate(this.nextDream)
      .then((val) => {
        val
          .pipe(first(),
            map((snaps) => {
              var data = snaps.payload.data()
              if (data['release']) {
                let release = data['release'];

                let today = new Date()
                if (release.toDate() > today) {
                  //locked = true
                  this.navCtrl.navigateForward("waiting", { animated: false });
                } else {
                  this.contentVisible = true;
                }
              }
            })
          )
          .subscribe()
      })
  }


  getStyle() {
    var styles = FlipCardHelper.getStyles(this.nextDream);
    this.frontCardBackground = styles.frontCardStyle;
    this.backCardBackground = styles.backCardStyle;
  }

  getButtonText() {
    return "Enter the In-Between"
  }

  myCallbackFunction = (args?: any): void => {
    console.log('callback function')
    if (this.storyService.storyPoints && this.storyService.storyPoints.length != 0) {
      console.log("trying to resume")
      //this.audioService.unloadHowler();
      //this.audioService.ctxResume();

      this.audioService.changeSoundImproved();
    }
    this.navCtrl.navigateRoot("game/" + this.nextDream, { animated: true, animation: expandAnimationDream });
  }

  getDreamText(data) {
    var text = { title: '', subtitle: '', eyebrow: '' }

    text.title = data[this.nextDream].Headline;
    text.subtitle = data[this.nextDream].SubHeadline;

    text.eyebrow = "Dream " + StoryHelper.romanize(this.nextDream)

    return text;
  }

  getDynamicWelcomeBackMessage() {
    var messages = ['To increase immersion, play with headphones or ear buds.',
      'You can re-read or replay your last dream by pressing the “back” arrow.',
      'For the best experience, choose a quiet and comfortable spot to play Betwixt.',
      'There are no wrong answers in the In-Between. Trust your gut when you respond to the voice\'s questions.',
      'Remember to breathe – your mind learns better when relaxed.',
      '"Fiction is an ancient virtual reality technology" – Jonathan Gottschall']

    return messages[Math.floor(Math.random() * messages.length)]
  }
}