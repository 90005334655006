import { Injectable } from '@angular/core';
import { Plugins } from "@capacitor/core";
const { FirebaseAnalytics } = Plugins;
import { environment } from '../environments/environment';
import * as firebase from 'firebase/app';
import mixpanel from 'mixpanel-browser';
import { stringify } from 'querystring';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  logEvent(eventName: string) {
    if (environment.testMode != true) {
      firebase.analytics().logEvent(eventName);
    }
  }

  logEventWithParams(eventName: string, parameters: {}) {
    if (environment.testMode != true) {
      firebase.analytics().logEvent(eventName, parameters);
    }
  }

  /**
   * Initialize mixpanel.
   *
   * @param {string} userToken
   */
  mixpanelInit(userToken: string, options: any = {}): void {
    // console.log("Initializing mixpanel");
    mixpanel.init(environment.mixpanel.projectToken, options);
    // console.log("initialized mixpanel");
  }

  mixpanelIdentify(userToken: string) {
    // console.log("Identifying user in mixpanel..");
    mixpanel.identify(userToken);
    // console.log("Identified user in mixpanel with id: " + userToken);
  }

  //alias is the new ID and original is the existing ID
  mixpanelAlias(alias: string, original?: string): void {
    // console.log("Aliasing user in mixpanel...");
    if (original)
      mixpanel.alias(alias, original);
    else
      mixpanel.alias(alias);
    // console.log("Aliased user in mixpanel!");
  }

  mixpanelSetProfile(email: string, activationCode?: string): void {
    if(activationCode)
      mixpanel.people.set({
        'email': email,
        'activationCode': activationCode
      });
    else
      mixpanel.people.set({ 'email': email});
  }

  /**
   * Push new action to mixpanel.
   *
   * @param {string} id Name of the action to track.
   * @param {*} [action={}] Actions object with custom properties.
   */
  mixpanelTrack(id: string, action: any = {}): void {
    mixpanel.track(id, action);
    console.log("Mixpanel tracked event: " + id);
  }
}
