<div class="wrapper" *ngIf="showInnerContent">
    <div @fadeInTitle class="title">Menu</div>
    <div @fadeInFirstSepartor class="separator"></div>
    <div class="menu-item-wrapper">
        <div
            @fadeInFirtsMenuItem
            *ngIf="menuType === 'main'"
            class="menu-item disabled"
            (click)="resetSavedProgress()"
        >Reset saved progress</div>
        <div
            @fadeInFirtsMenuItem
            class="menu-item"
            *ngIf="menuType === 'in-dream'"
            (click)="restartTheDream()"
            [ngClass]="{disabled: finished}"
        >Restart the dream</div>
        <div
            (click)="sos()"
            @fadeInSecondMenuItem
            class="menu-item"
        >SOS</div>
        <div
            @fadeInThirdMenuItem
            *ngIf="menuType === 'main'"
            class="menu-item"
            (click)="about()"
        >About</div>
        <div
            @fadeInThirdMenuItem
            class="menu-item"
            *ngIf="menuType === 'in-dream' && !finished"
            (click)="saveAndExit()"
        >Save and exit</div>
        <div
            @fadeInThirdMenuItem
            class="menu-item"
            *ngIf="menuType === 'in-dream' && finished"
            (click)="exit()"
        >Exit</div>
    </div>
    <div @fadeInSecondSepartor class="separator"></div>
    <div @fadeInButtonWrapper class="button-wrapper">
        <div class="button sound" (click)="toggleSound(innerCircleSound, outterCircleSound)">
            <div class="icon-wrapper">
                <img
                    @fade
                    *ngIf="soundOn"
                    class="sound-on"
                    src="assets/images/menu/sound_on.png"
                    alt="sound on"
                >
                <img
                    @fade
                    *ngIf="!soundOn"
                    class="sound-off"
                    src="assets/images/menu/sound_off.png"
                    alt="sound off"
                >
                <div #innerCircleSound class="circle inner"></div>
                <div #outterCircleSound class="circle outter"></div>
            </div>
            <div class="info">sound</div>
            <div class="value-wrapper">
                <div @fade *ngIf="soundOn" class="value">on</div>
                <div @fade *ngIf="!soundOn" class="value">off</div>
            </div>
        </div>
        <div class="button speed" (click)="changeSpeed(innerCircleSpeed, outterCircleSpeed, speedImage)">
            <div class="icon-wrapper">
                <img #speedImage src="assets/images/menu/speed.png" alt="speed">
                <div #innerCircleSpeed class="circle inner"></div>
                <div #outterCircleSpeed class="circle outter"></div>
            </div>
            <div class="info">SPEED</div>
            <div class="value-wrapper">
                <ng-container *ngFor="let speedOption of speedOptions; let i=index">
                    <div @fade *ngIf="i === selectedSpeed" class="value">{{speedOption.name}}</div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
