import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GameComponent } from 'src/components/game/game.component';
import { TermsConditionsComponent } from 'src/components/disclaimer/terms-conditions.component';
import { SignUpOptionsCompontent } from 'src/components/auth/options/signuptoptions.compontent';
import { EmailSignupComponent } from 'src/components/auth/email/emailsignup.component';
import { ReinventedIntroComponent } from 'src/components/onboarding/reinvented-intro/reinvented-intro.component';
import { LoginComponent } from 'src/components/auth/login/login.component';
import { WelcomeOptionsComponent } from 'src/components/onboarding/welcome-options/welcome-options.component';
import { PurposeComponent } from 'src/components/onboarding/purpose/purpose.component';
import { SliderComponent } from 'src/components/onboarding/slider/slider.component';
import { DreamEnd } from 'src/components/dream-end/dream-end.component';
import { PrivacyPolicyComponent } from 'src/components/disclaimer/privacy-policy.component';
import { DisclaimerComponent } from 'src/components/disclaimer/disclaimer.component';
import { SosScreenComponent } from 'src/components/disclaimer/sos-screen';
import { SuccessfulRegistrationComponent } from 'src/components/successful-registration/successful-registration.component';
import { PredreamComponent } from 'src/components/predream/predream.component';
import { AuthGuardService } from 'src/services/auth.guard.service';
import { WelcomeBackComponent } from 'src/components/predream/welcomeback.component';
import { ForgottenPassComponent } from 'src/components/auth/forgotten-pass/forgotten-pass.component';
import { EndComponent } from 'src/components/disclaimer/end.component';
import { DownloadPWAComponent } from 'src/components/download/download.component';
import { IntroSceneComponent } from './../components/intro-scene/intro-scene.component';
import { DreamSelectorComponent } from './../components/dream-selector/dream-selector.component';
import { AboutComponent } from 'src/components/about/about.component';

const routes: Routes = [
  // { path: 'test', component: TestComponent },
  // { path: 'test/:id', component: TestComponent },

  // { path: '', component: ReinventedIntroComponent },
  { path: '', component: DownloadPWAComponent },
  // { path: 'sos-screen', component: SosScreenComponent },
  // { path: 'about', component: AboutComponent },
  { path: 'download', component: DownloadPWAComponent },
  // { path: 'login', component: LoginComponent },
  // { path: 'intro', component: ReinventedIntroComponent },
  // { path: 'intro-scene', component: IntroSceneComponent, canActivate: [AuthGuardService] },
  // { path: 'home', component: ReinventedIntroComponent },
  // { path: 'disclaimer', component: DisclaimerComponent },
  // { path: 'terms-conditions', component: TermsConditionsComponent },
  // { path: 'privacy', component: PrivacyPolicyComponent },
  // { path: 'game/:id', component: GameComponent, canActivate: [AuthGuardService] },
  // { path: 'waiting', component: DreamEnd, canActivate: [AuthGuardService] },
  // { path: 'signupoptions', component: SignUpOptionsCompontent },
  // { path: 'emailsignup', component: EmailSignupComponent },
  // { path: 'welcomeoptions', component: WelcomeOptionsComponent },
  // { path: 'purpose', component: PurposeComponent },
  // { path: 'slider', component: SliderComponent },
  // // { path: 'welcomeback', component: WelcomeBackComponent },
  // { path: 'forgotten-pass', component: ForgottenPassComponent },
  // { path: 'end', component: EndComponent },
  // { path: 'predream', component: PredreamComponent, canActivate: [AuthGuardService] },
  // { path: 'dream-selector', component: DreamSelectorComponent, canActivate: [AuthGuardService] },
  // { path: 'success', component: SuccessfulRegistrationComponent, canActivate: [AuthGuardService] },
  // { path: '**', component: ReinventedIntroComponent },
  { path: '**', component: DownloadPWAComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled', // Add options right here
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
