import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Howl, Howler } from 'howler';

import * as firebase from 'firebase/app';
import { Plugins, FilesystemDirectory } from '@capacitor/core'
const { Share, LocalNotifications, BackgroundTask, App } = Plugins;

import { writeFile } from 'capacitor-blob-writer';
import { AnalyticsService } from 'src/services/analytics.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AudioService } from 'src/services/audio.service';
import { NotificationService } from 'src/services/notification.service';
import { StateManagementService } from 'src/services/state-management.service';

import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit, AfterViewInit {

  options: AnimationOptions = {
    path: 'assets/animations/Betwixt_IOS_Safari.json',
  }

  soundFirebase;
  soundLocal;
  id;
  currentVolume = 1;

  private route$: Subscription;
  public currentDream = 1;
  public fileText = "";

  constructor(public analyticsService: AnalyticsService,
    private route: ActivatedRoute,
    private httpClient: HttpClient,
    private audioService: AudioService,
    public notificationService: NotificationService,
    public statemanagement: StateManagementService) { }
  ngAfterViewInit(): void {

  }

  logEvent() {
    // firebase.analytics().logEvent('test', { 'generic_app_event_1' : true, "test" : "test"}); 

    this.analyticsService.logEvent("test_event_n3");
    this.analyticsService.logEventWithParams("test_event_n4", {testparam: "test"});

    const analytics = firebase.analytics();
    // analytics.logEventlogEvent(analytics, '');
    //firebase.analytics().logEvent('test_event_n2');
  }
  data = "empty";

  ngOnInit(): void {

    this.logEvent();
    //this.notificationService.initPush();

    for (let index = 0; index < 30; index++) {

      var tomorrow = new Date();
      //tomorrow.setDate(tomorrow.getDate() + 1)
      //tomorrow.setHours(20);
      tomorrow.setMinutes(tomorrow.getMinutes() + 1);
      tomorrow.setSeconds(index);

      // this.notificationService.scheduleLocalNotification(
      //   index,
      //   "test" + index,
      //   tomorrow,
      //   "/journal/1",
      //   "journal_reminder1");
    }


    // this.route$ = this.route.params.subscribe(params => {
    //   this.currentDream = params.id;

    // });

    // this.httpClient.get("./assets/ink/dream-" + this.currentDream + ".json").subscribe(data => {
    //   this.fileText = JSON.stringify(data)
    // })

    // alert(this.iOS());

    var shouldBeHtml5 = false;

    // this.soundFirebase = new Howl({
    //   src: "https://firebasestorage.googleapis.com/v0/b/betwixt-game.appspot.com/o/public%2Ftest%2Ffile_example_MP3_700KB.mp3?alt=media",
    //   //src: "./assets/audio/audio_sample.mp3",
    //   html5: shouldBeHtml5,
    //   preload: true
    // });

    // this.soundLocal = new Howl({
    //   //src: "https://firebasestorage.googleapis.com/v0/b/betwixt-game.appspot.com/o/public%2Ftest%2Ffile_example_MP3_700KB.mp3?alt=media",
    //   src: "./assets/audio/audio_sample.mp3",
    //   html5: false,
    //   preload: true
    // });

    // alert(this.soundFirebase._html5)
    //this.audioService.playAudioImproved("d1l1.mp3");


  }

  changeSound() {
    this.audioService.changeSoundImproved("08_warmth.mp3", 5);
  }

  fadeOut() {
    this.audioService.fadeOutImproved();
  }

  error;

  async share() {
    const res = await fetch('./assets/images/cat.jpg')
    const blob = await res.blob();

    const { uri } = await writeFile({
      path: 'media/cat.jpg',
      directory: FilesystemDirectory.Data,
      data: blob,
      recursive: true,
    })

    this.error = uri;

    await Share.share({
      title: 'Checkout what I experienced in the in-between',
      url: uri
    });
  }

  playFirebase() {
    this.soundFirebase.play();
    this.soundFirebase.fade(0, 1, 15000);

    setTimeout(() => {
      this.soundFirebase.fade(1, 0, 10000);
    }, 15000);

  }

  playLocal() {
    this.soundLocal.play();
    this.soundLocal.fade(0, 1, 15000);

    setTimeout(() => {
      this.soundLocal.fade(1, 0, 10000);
    }, 15000);

  }

  volumeDown() {
    this.currentVolume -= 0.1;
    // this.sound.volume(this.currentVolume);
    console.log(this.currentVolume);

    Howler.volume(this.currentVolume);
  }
}
