<ion-app>
  <ion-header class="ion-no-border">
  </ion-header>
  <ion-content scrollY="false">
    <div class="cmn-wrapper">
      <div class="cmn-font-helvetica ion-text-uppercase">
        <ion-icon class="cmn-icon-helvetica" (click)="back()" name="chevron-back-outline">
        </ion-icon>
      </div>
      <div class="cmn-serif-title ion-text-center margin-top">
        Betwixt on <br>Discord
      </div>
    </div>
  </ion-content>
</ion-app>