<ion-app>
    <ion-header class="ion-no-border">
    </ion-header>
    <ion-content scrollY="false" [ngClass]="calculateClasses()">
        <ng-template #menuInsertionPoint></ng-template>
        <div class="cmn-wrapper">
            <div class="lottie-wrapper" lottie [options]="opts" (animationCreated)="animationCreated($event)">
            </div>
            <div class="menu-button-wrapper">
                <app-menu-button (click)="menuService.toggleMenu(wrapperRef, 'in-dream', finished, currentDream)"></app-menu-button>
            </div>

            <div #chatHostDiv class="chat-main" chat-host>
                <ng-template chat-host></ng-template>
            </div>
        </div>
    </ion-content>
</ion-app>
