import { Component, OnInit, OnDestroy } from '@angular/core';
import { StoryPoint } from 'src/interfaces/story-point.interface';
import { UserInteraction } from 'src/interfaces/user-interaction.interface';
import { StoryService } from 'src/services/story.service';
import { Dream } from 'src/interfaces/dream.interface';
import { AudioService } from 'src/services/audio.service';
import { StateManagementService } from 'src/services/state-management.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit, OnDestroy {
  finished = false;
  public currentDream = 1;

  constructor(
    public storyService: StoryService,
    public audioService: AudioService,
  ) {}

  public ngOnInit() {
    this.storyService.startGame(this.currentDream);
  }

  public ngOnDestroy() {

  }

  get currentUserInteraction(): UserInteraction {
    return this.storyService.currentUserInteraction;
  }

  get storyPoints(): StoryPoint[] {
    return this.storyService.storyPoints;
  }

  calculateClasses() {
    return "d" + this.currentDream;
  }
}
