import { Component, ViewChild, ComponentFactoryResolver, OnInit, ChangeDetectorRef, OnDestroy, ViewContainerRef, NgZone } from '@angular/core';
import { ChatComponent } from 'src/components/chat/chat.component';
import { ChatDirective } from 'src/directives/chat.directive';
import { StoryService } from 'src/services/story.service';
import { AudioService } from 'src/services/audio.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { NavController } from '@ionic/angular';

import {
  Plugins,
  AppState,
  PluginListenerHandle
} from '@capacitor/core';
import { NotificationService } from 'src/services/notification.service';
// import { fadeAnimation } from 'src/helpers/transitions-helper';
import { AnalyticsService } from 'src/services/analytics.service';
import { AnimationsService } from 'src/services/animations.service';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';
import { StateManagementService } from 'src/services/state-management.service';
import { FIRST_SOUNDS } from 'src/enums/first-sounds';
import { MenuService } from 'src/services/menu.service';
import { take } from 'rxjs/operators';
const { Storage, App } = Plugins;

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.css']
})
export class GameComponent implements OnInit, OnDestroy {
  currentDream = 1;
  navDream = 1;
  route$: Subscription;
  finished = false;
  appStateListener: PluginListenerHandle;
  @ViewChild(ChatDirective, { static: false }) chatDirective: ChatDirective;
  @ViewChild('menuInsertionPoint', { read: ViewContainerRef }) wrapperRef: ViewContainerRef;


  messages: any[] = [];
  subscription: Subscription;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private ref: ChangeDetectorRef,
    public audioService: AudioService,
    private route: ActivatedRoute,
    private storyService: StoryService,
    public navCtrl: NavController,
    public notificationService: NotificationService,
    private analyticsService: AnalyticsService,
    public animationService: AnimationsService,
    private stateService: StateManagementService,
    public menuService: MenuService,
    private ngZone: NgZone,
  ) {
    // subscribe to home component messages
    this.subscription = this.animationService.getMessage().subscribe(message => {
      if (message) {
        this.messages.push(message);
        console.log(message.text)
        this.updateAnimation(message.text);
      } else {
        // clear messages when empty message received
        this.messages = [];
      }
    });
  }

  ngOnInit() {
    this.route$ = this.route.params.subscribe(params => {
      console.log("initialized params dream enter")
      this.navDream = parseInt(params.id);
      this.currentDream = this.navDream;

      let sound = FIRST_SOUNDS[this.currentDream];
      if (sound) {
        localStorage.setItem("currentSound", sound);
      }

      this.finished = this.stateService.getLastCompletedDream() >= this.currentDream && this.stateService.hasSavedState(this.currentDream + 1);

      if (this.navDream == 1) {
        Storage.get({ key: 'onboarded' }).then(onboarded => {
          console.log(onboarded);
          if (onboarded.value == null) {
            this.navCtrl.navigateForward("disclaimer", { animated: false });
          } else {
            this.loadChat();
            this.audioService.changeSoundImproved("d1l1.mp3", 12);
          }
        }).catch();
      } else {
        this.loadChat();
        this.audioService.changeSoundImproved();
      }
    });
    console.log("CURRENT DREAM HERE IS - > " + this.currentDream);
  }

  ionViewDidEnter() {
    this.appStateListener = App.addListener('appStateChange', (state: AppState) => {
      if (state.isActive == false) {
        this.ngZone.run(() => {
          // TODO: REVIEW
          // this.audioService.stopBackgroundSounds();
          this.audioService.unloadHowler();

          this.storyService.clearPendingPointsTimeout();
          this.storyService.saveProgress();
          this.saveDreamId(this.currentDream);
          this.navCtrl.navigateRoot('intro-scene', { queryParams: { splashScreen: true } });
        });
      }
    });
  }

  loadChat() {
    this.ref.detectChanges();
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ChatComponent);
    this.chatDirective.viewContainerRef.clear();
    const component = this.chatDirective.viewContainerRef.createComponent(componentFactory);
    component.instance.currentDream = this.navDream;
    component.instance.finished = this.finished;
  }

  calculateClasses() {
    return "d" + this.navDream;
  }

  getOptions() {
    console.log("invoke")
    return {
      path: this.animationService.animationPath,
      loop: false
    }
  }

  public opts: AnimationOptions = {
    path: this.animationService.animationPath,
    loop: false
  }

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }

  updateAnimation(msg): void {
    this.opts = {
      ...this.opts, // In case you have other properties that you want to copy
      path: msg,
    };
  }

  private saveDreamId(dreamId: number): void {
    if (dreamId) localStorage.setItem("selectedDream", dreamId.toString());
  }

  ngOnDestroy(): void {
    this.audioService.stopBackgroundSounds();
    if (this.subscription) this.subscription.unsubscribe();
    if (this.route$) this.route$.unsubscribe();
    if (this.appStateListener) this.appStateListener.remove();
    this.menuService.closeMenu();
  }
}
