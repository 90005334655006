import { Component, OnInit, AfterViewInit, NgZone, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from 'src/services/auth.service';
import { Router } from '@angular/router';
import { first, skip } from 'rxjs/operators';
import { AnimationController, NavController } from '@ionic/angular'
import { AngularFirestore } from '@angular/fire/firestore';

import { Plugins } from '@capacitor/core';
import { StateManagementService } from 'src/services/state-management.service';
import { FlipCardHelper } from 'src/helpers/flip-card-helper';
import { expandAnimation } from 'src/helpers/transitions-helper';
const { Storage, Device } = Plugins;

@Component({
  selector: 'app-reinvented-intro',
  templateUrl: './reinvented-intro.component.html',
  styleUrls: ['./reinvented-intro.component.css']
})
export class ReinventedIntroComponent implements OnInit, AfterViewInit {


  public frontCardBackground: string
  public backCardBackground = "black";

  loading = true;
  secondaryText = "An epic, magical journey of self-discovery";

  @ViewChild("start", { read: ElementRef, static: true }) start: ElementRef;
  @ViewChild("secondary", { read: ElementRef, static: true }) secondary: ElementRef;
  @ViewChild("continue", { read: ElementRef, static: true }) continue: ElementRef;
  @ViewChild("signIn", { read: ElementRef, static: true }) signin: ElementRef;

  constructor(public ngFireAuth: AngularFireAuth, public authService: AuthService, public router: Router,
    private zone: NgZone, public ref: ChangeDetectorRef,
    private animationCtrl: AnimationController,
    private afs: AngularFirestore,
    private stateManagement: StateManagementService,
    private navCtrl: NavController) { }

  ngOnInit(): void {
    this.checkUserIsLoggedIn();
    this.getStyle();

    // this.authService.isLoggedIn$
    //   .pipe(skip(1))
    //   .subscribe((loginStatus) => {
    //     console.log("subscription login");
    //     if (loginStatus == true) {
    //       console.log("user is logged in")
    //       this.loading = false
    //       //store version build per user on login
    //       this.storeVersion();
    //     } else {
    //       console.log("user is not logged in");
    //       this.loading = false;
    //       this.ref.detectChanges();
    //       this.zone.run(() => {
    //         this.router.navigate(["/login"]);
    //       })
    //     }
    //   });

    // Storage.get({ key: 'userid' }).then(useridres => {
    //   if (useridres.value != null)
    //     this.navCtrl.navigateForward("success", { animated: false });
    // })


    //fallback after 1 second if auth subscription is not triggered
    setTimeout(() => { this.loading = false }, 1750);
  }

  ngAfterViewInit(): void {
    // const animationSecondaryAppear = this.animationCtrl.create()
    //   .addElement(this.secondary.nativeElement)
    //   .duration(2000)
    //   .delay(500)
    //   .fromTo('opacity', '0', '1')
    //   .onFinish(() => {
    //     animationContinueAppear.play();
    //     animationButtonsAppear.play();
    //   });

    // animationSecondaryAppear.play();

    // const animationContinueAppear = this.animationCtrl.create()
    //   .addElement(this.continue.nativeElement)
    //   .duration(500)
    //   .fromTo('opacity', '0', '1');

    // const animationButtonsAppear = this.animationCtrl.create()
    //   .addElement(this.signin.nativeElement)
    //   .duration(500)
    //   .fromTo('opacity', '0', '0.4');
  }

  ionViewDidEnter() {
    Storage.get({ key: 'onboarded' }).then(onboarded => {
      if (onboarded.value != null) {
        this.navCtrl.navigateRoot("dream-selector");
      }
    }).catch();
  }

  storeVersion() {
    this.getDeviceInfo().then(deviceInfo => {
      Storage.get({ key: 'userid' }).then(useridres => {
        if (useridres.value != null) {
          this.afs.collection<any>('users').doc(useridres.value).update({ buildN: deviceInfo }).catch();
        }
      })
    });
  }

  async getDeviceInfo() {
    const info = await Device.getInfo();
    return info.appBuild;
  }

  forward(page) {
    this.navCtrl.navigateForward(page, { animated: false });
  }

  forwardAnimated(page) {
    setTimeout(() => {
      this.navCtrl.navigateForward(page, { animated: true, animation: expandAnimation });
    }, 1000);
  }

  getStyle() {

    // this is only part of the onboarding, so there is no completed dream
    var styles = FlipCardHelper.getStyles(0, true);

    this.frontCardBackground = styles.frontCardStyle;
    this.backCardBackground = styles.backCardStyle;
  }

  myCallbackFunction = (args: any): void => {
    this.forwardAnimated('welcomeoptions');
  }

  async checkUserIsLoggedIn(): Promise<void> {
    await Storage.get({ key: 'userid' }).then(useridres => {
      if (useridres.value != null)
      this.navCtrl.navigateRoot("dream-selector");
    });
  }
}

