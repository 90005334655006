<div dream_selector_container [ngStyle]="hasBackground ? {} : {'background': 'none', 'background-image': 'none'}" [ngClass]="{'scrollable': !isActiveAnimationAttribute}">
  <div [@fadeDreamSelector]="getDreamContainerAnimationState()" class="dream-selector">
    <div class="dream-selector-wrapper" [ngClass]="{'ios': isIos}">
      <ng-template #menuInsertionPoint></ng-template>
      <div class="menu-button-wrapper" [ngStyle]="{'animation': effectSet ? '0' : ''}">
        <app-menu-button (click)="menuService.toggleMenu(wrapperRef, 'main')"></app-menu-button>
      </div>
      <div dream_swiper_container class="swiper-container" [ngClass]="isActiveAnimationAttribute ? 'animate' : ''">
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let dreamStyle of dreamStyles; let i = index"
            [style]="isFutureDream(i) ? '' : dreamStyle">
            <div class="swiper-slide__inner">
              <div class="video-container" *ngIf="isFutureDream(i)">
                <video class="video" autoplay loop [muted]="'muted'" playsinline [src]="'assets/video/d'+ transformedDreamData[i].id +'_card.mp4'">
                </video>
              </div>
              <div class="content-wrapper" (click)="checkToNavigateToStory($event)">
                <div class="read-button read-button-section" *ngIf="!checkDreamTypeConditions(i)" (click)="toggleJournalMenu()">
                  <img class="read-button-section" [src]="'assets/images/book.svg'" alt="book">
                </div>
                <div class="eyebrow"><div>{{ transformedDreamData[i].eyebrow }}</div></div>
                <div class="titles">
                  <div class="title">
                    <div [ngClass]="isFutureDream(i) ? 'future' : ''">{{ transformedDreamData[i].headline }}</div>
                  </div>
                  <div class="sub-title">
                    <div *ngIf="!isFutureDream(i)">{{ transformedDreamData[i].subhead }}</div>
                  </div>
                </div>
                <div class="description">
                  <div class="text">
                    <div *ngIf="!isFutureDream(i)">{{ transformedDreamData[i].description }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
      <div class="button-container">
        <button class="read-dream-button" (click)="navigateToStory()">
          <span [ngStyle]="{'opacity': fadeOutText ? '0' : '1', 'transition': fadeOutText ? '' : 'opacity 0.3s'}">{{ transformedDreamData[activeDreamNumber ? (activeDreamNumber - 1) : dreamIndex]?.buttonText }}</span>
        </button>
      </div>
    </div>
  </div>
  <div [@fadeJournal]="getJournalAnimationState()" class="journal-menu-component" *ngIf="showJournal">
    <app-journal-menu
      [isCompleted]="showCompleted"
      [dreamId]="transformedDreamData[activeDreamNumber ? (activeDreamNumber - 1) : dreamIndex].id"
      (showJournal)="toggleJournalMenu($event)"></app-journal-menu>
  </div>
</div>
