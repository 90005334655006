import { Injectable } from '@angular/core';
import { Plugins } from "@capacitor/core";
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { StateManagementService } from './state-management.service';
import { AngularFirestore } from '@angular/fire/firestore';
const { Storage } = Plugins;


@Injectable({
  providedIn: 'root'
})
export class CodeGenerationService {

  userId: string;
  NFTID;
  walletAddress;

  constructor(
    private httpClient: HttpClient,
    private stateManagementService: StateManagementService,
    public afs: AngularFirestore) { }

  async generate() {

    await Storage.get({ key: 'userid' }).then(res => {
      if (res.value != null) {
        this.userId = res.value;
      }
    }).catch();

    let newCode = this.userId.substr(0, 6);

    this.afs.collection<any>('activation_codes').doc(newCode).set({
      generatedBy: this.userId,
      original: 10,
      remaining: 10,
    });
  }
}