<div read_more_detail_container class="read-more" [ngClass]="isShow ? 'show' : (isShow === undefined ? '' : 'hide')">
  <div class="read-more__content">
    <ng-container *ngIf="showByType === 'level'; else power">
      <div class="progress-image">
        <div class="image-circle">
          <img id="main-image" [src]="'assets/images/levels/' + powerLevelObject.level.imageUrl + '.svg'" alt="progress">
        </div>
        <h1 class="global-text">{{ powerLevelObject.level.title }}</h1>
      </div>
      <div *ngIf="powerLevelObject.level.descriptions.length > 0" class="read-more__description">
        <div class="read-more__inner-text" *ngFor="let description of powerLevelObject.level.descriptions">{{ description }}</div>
      </div>
    </ng-container>
    <ng-template #power>
      <div *ngIf="showByType !== 'level'">
        <div class="progress-image power">
          <img id="main-image" [src]="'assets/images/powers/' + showByType.imageUrl + '.svg'" alt="power">
          <h1 class="global-text">{{ showByType.title }}</h1>
        </div>
        <div *ngIf="showByType" class="read-more__description">
          <ng-container *ngIf="showByType.descriptions.length > 0">
            <div class="read-more__inner-text global-text" *ngFor="let description of showByType.descriptions">{{ description }}</div>
          </ng-container>
        </div>
        <div *ngIf="showByType.optionals" class="read-more__optional">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title class="global-text">Optional journaling prompt</mat-panel-title>
            </mat-expansion-panel-header>
            <div *ngFor="let optional of showByType.optionals">
              <p class="global-text">{{ optional }}</p>
            </div>
          </mat-expansion-panel>
        </div>
      </div>
    </ng-template>
  </div>
</div>
