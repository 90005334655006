import { Component, OnInit, ViewEncapsulation, AfterViewInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { FlipCardHelper } from 'src/helpers/flip-card-helper';
import Swiper from 'swiper';
import { IMemory, ContentService, IPower } from 'src/services/content.service';
import { IPowerLevel } from '../../services/content.service';
import { StateManagementService } from 'src/services/state-management.service';
import StoryHelper  from 'src/helpers/story-helper';

@Component({
  selector: 'app-journal-menu',
  templateUrl: './journal-menu.component.html',
  styleUrls: ['./journal-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class JournalMenuComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() dreamId = 0;
  @Input() isCompleted = false;
  @Output() showJournal = new EventEmitter<boolean>();

  journalBackgroundImage = '';
  selectedTabIndex = 0;
  powerSwiper: Swiper;
  showReadMore: boolean;
  memories: IMemory[] =[];
  powerLevelObject: IPowerLevel;
  showByType: 'level' | IPower = 'level';
  triggerDetails = false;
  viewedItems: string[] = [];
  animTranslate = false;

  constructor(
    private stateService: StateManagementService,
    private contentService: ContentService,
  ) { }

  ngOnInit(): void {
    const viewedItemArray = localStorage.getItem('viewedItems');
    if (viewedItemArray) this.viewedItems = JSON.parse(viewedItemArray);
    this.journalBackgroundImage = FlipCardHelper.getStyles(this.dreamId).frontCardStyle;
    this.powerSwiper = new Swiper('.swiper-container.powers', {
      speed: 300,
      direction: 'horizontal',
      observer: true,
      observeParents: true,
      slidesPerView: 3,
      spaceBetween: 30,
      lazy: true,
      loop: true,
    });
    this.memories = this.contentService.getMemories(this.dreamId, JSON.parse(this.stateService.getStoryFromLocalStorage(this.dreamId)).variablesState).filter(memory => memory.descriptions.length > 0);
    this.powerLevelObject = this.contentService.getPowersAndLevels(this.dreamId, JSON.parse(this.stateService.getStoryFromLocalStorage(this.dreamId)).variablesState);
    setTimeout(() => {
      this.animTranslate = true;
    }, 500);
  }

  ngAfterViewInit(): void {
    window.dispatchEvent(new Event('resize'));
  }

  onReadMore(showByType?: 'level' | IPower, itemName?: string): void {
    if (itemName) {
      if (!this.viewedItems.includes(itemName)) {
        this.viewedItems.push(itemName);
        localStorage.setItem('viewedItems', JSON.stringify(this.viewedItems));
      }
    }
    if (showByType) {
      if (showByType === 'level') {
        this.showByType = 'level';
      } else {
        this.showByType = { title: showByType.title, descriptions: showByType.descriptions, optionals: showByType.optionals, imageUrl: showByType.imageUrl };
      }
    }
    this.showReadMore = !this.showReadMore;
    if (this.showReadMore === false) {
      setTimeout(() => {
        this.triggerDetails = false;
      }, 300);
    } else {
      this.triggerDetails = true;
    }
  }

  onIconEvent(): void {
    if (this.showReadMore) this.onReadMore();
    else this.hideJournal();
  }

  hideJournal(): void {
    this.isCompleted ? this.showJournal.emit(true) : this.showJournal.emit(false);
  }

  hasNoEmptyElement(descriptions: string[]): boolean {
    return descriptions.some(value => value.length);
  }

  romanizeDreamNumber(dreamId: number) {
    return StoryHelper.romanize(dreamId);
  }

  ngOnDestroy(): void {
  }
}
