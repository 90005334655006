export class TagConstants {
    static readonly NARRATOR_DIALOG = 'narrator';
    static readonly USER_DIALOG = 'player';
    static readonly TITLE = 'title'

    static readonly USER_INTERACTION = 'userInteraction';

    static readonly DOTS = 'dots'
    static readonly AUDIO = 'audio';
    static readonly STOPAUDIO = 'stopaudio';
    static readonly PAUSE = 'pause';

    static readonly FEEDBACK = 'feedback';

    static readonly TEXTBREAK = 'textbreak';

    static readonly MISSIONS = 'missions';

    static readonly BACKGROUDAUDIO = 'backgroundaudio';

    static readonly STOPBACKGROUDAUDIO = 'stopbackgroundaudio';

    static readonly WAKEUP = 'wakeup';

    static readonly SOUND_FX = 'soundfx';

    static readonly ANIMATION = 'animation'
}