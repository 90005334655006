import { Injectable } from "@angular/core";
import { UserInputTransformationService } from "./user-input-transformation.service";

@Injectable({
  providedIn: "root",
})
export class ContentService {
  constructor(private transformationService: UserInputTransformationService) {}

  public getMemories(dreamId: number, variables: object): IMemory[] {
    const transformedVariables = this.transformStoryVariables(variables);

    switch (dreamId) {
      case 1:
        return this.getFirstDreamMemories(transformedVariables);
      case 2:
        return this.getSecondDreamMemories(transformedVariables);
      case 3:
        return this.getThirdDreamMemories(transformedVariables);
      case 4:
        return this.getFourthDreamMemories(transformedVariables);
      case 5:
        return this.getFifthDreamMemories(transformedVariables);
      case 6:
        return this.getSixthDreamMemories(transformedVariables);
      case 7:
        return this.getSeventhDreamMemories(transformedVariables);
      case 8:
        return this.getEighthDreamMemories(transformedVariables);
      case 9:
        return this.getNinthDreamMemories(transformedVariables);
      case 10:
        return this.getTenthDreamMemories(transformedVariables);
      case 11:
        return this.getEleventhDreamMemories(transformedVariables);
      default:
        console.error("Cannot get memories for dream with id: " + dreamId);
        return [];
    }
  }

  public getPowersAndLevels(dreamId: number, variables: object): IPowerLevel {
    const transformedVariables = this.transformStoryVariables(variables);

    switch (dreamId) {
      case 1:
        return this.getFirstDreamLevelAndPowers(transformedVariables);
      case 2:
        return this.getSecondDreamLevelAndPowers(transformedVariables);
      case 3:
        return this.getThirdDreamLevelAndPowers(transformedVariables);
      case 4:
        return this.getFourthDreamLevelAndPowers(transformedVariables);
      case 5:
        return this.getFifthDreamLevelAndPowers(transformedVariables);
      case 6:
        return this.getSixthDreamLevelAndPowers(transformedVariables);
      case 7:
        return this.getSeventhDreamLevelAndPowers(transformedVariables);
      case 8:
        return this.getEighthDreamLevelAndPowers(transformedVariables);
      case 9:
        return this.getNinthDreamLevelAndPowers(transformedVariables);
      case 10:
        return this.getTenthDreamLevelAndPowers(transformedVariables);
      case 11:
        return this.getEleventhDreamLevelAndPowers(transformedVariables);
      default:
        console.error("Cannot get memories for dream with id: " + dreamId);
        return;
    }
  }

  getDreamTitles(dreamId: number, variables: object): string {
    const transformedVariables = this.transformStoryVariables(variables);
    switch (dreamId) {
      case 1:
        return "Exploring the change you want to see.";
      case 2:
        return "Exploring the things you love, appreciate and value.";
      case 3:
        return this.getThirdDreamDescription(transformedVariables);
      case 4:
        return "Self-distancing: seeing your emotions from the outside in.";
      case 5:
        return "Exploring the connection between self-sabotage and unmet needs.";
      case 6:
        return "A meditation on fear.";
      case 7:
        return "Fight, flee, freeze or fawn? Knowing your patterns.";
      case 8:
        return "Identifying your key strengths.";
      case 9:
        return "The shadow self and its destructive stories.";
      case 10:
        return "Using memory or metaphor to choose a better narrative.";
      case 11:
        return "Choosing a magical form for your monster and dreaming up its new home.";
      default:
        console.error("Cannot get description for dream with id: " + dreamId);
        return;
    }
  }

  getThirdDreamDescription(variables: IThirdDreamVariables): string {
    let variableText = "";
    if (variables.clan === "Striver") variableText = "to reach for excellence and perfection (the Striver).";
    if (variables.clan === "Worker") variableText = "to persevere and endeavour (the Worker).";
    if (variables.clan === "Sprinter") variableText = "for speed and efficiency (the Sprinter).";
    if (variables.clan === "Rock") variableText = "to be strong, courageous and stoic (the Rock).";
    if (variables.clan === "Carer") variableText = "to help, please and heal others (the Carer).";
    return `Identifying your core drive ${variableText}`;
  }

  private transformStoryVariables(variables: object): any {
    let transformedObject: object = {};

    Object.keys(variables).forEach((key) => {
      const value = variables[key];
      if (typeof value === "string") {
        transformedObject[key] = value.replace("^", "");
      } else {
        transformedObject[key] = value;
      }
    });

    return transformedObject;
  }

  private pronounSwap(value: string): string {
    return this.transformationService.replacePronouns(value);
  }

  private capitalizeMemories(memories: IMemory[]) {
    memories.forEach(memory => {
      const capitalizedDescriptions = memory.descriptions.map(description => this.transformationService.capitalizeFirstLetter(description));
      memory.descriptions = capitalizedDescriptions;
    });
  }

  private getFirstDreamMemories(variables: IFirstDreamVariables): IMemory[] {
    let memoryArray: IMemory[] = [];
    let memory1: IMemory = {
      title: "Where you're headed?",
      descriptions: [],
    };

    if (variables.playerBetterPlace === "A calm mind") {
      memory1.descriptions.push("A calmer mind");
    }
    if (variables.playerBetterPlace === "Excitement") {
      memory1.descriptions.push("A more varied, exciting life");
    }
    if (variables.playerBetterPlace === "Self-confidence") {
      memory1.descriptions.push("To a world where you can have more confidence in yourself");
    }
    if (variables.playerBetterPlace === "Connection") {
      memory1.descriptions.push("To a world in which you can feel more connected");
    }
    if (variables.playerBetterPlace === "Growth") {
      memory1.descriptions.push("To a world in which you're free to grow and change");
    }
    if (variables.playerBetterPlace === "Contentment") {
      memory1.descriptions.push("To a world in which you can feel content with what you have");
    }
    if (variables.quest_input) {
      memory1.descriptions.push(`To a world where you have ${this.pronounSwap(variables.playerGoodPlace)}`);
      if (variables.playerGoodPlaceExtra !== "") memory1.descriptions.push(variables.playerGoodPlaceExtra);
    }
    memoryArray.push(memory1);

    let memory2: IMemory = {
      title: "One small action to take while on this path",
      descriptions: [],
    };

    if (variables.TinyAction !== '') {
      memory2.descriptions.push(variables.TinyAction);
    }
    memoryArray.push(memory2);

    let memory3: IMemory = {
      title: "Dream I vision",
      descriptions: [],
    };

    if (variables.D1_glimpsed_thing === "icebergs") {
      memory3.descriptions.push("Giant icebergs and waves frozen in motion");
    }
    if (variables.D1_glimpsed_thing === "fossils") {
      memory3.descriptions.push("The fossils of an animal, encased in ice");
    }
    if (variables.D1_glimpsed_thing === "tree") {
      memory3.descriptions.push("An enormous tree, far away, its top lost in the clouds");
    }
    memoryArray.push(memory3);

    this.capitalizeMemories(memoryArray);
    return memoryArray;
  }

  private getSecondDreamMemories(variables: ISecondDreamVariables): IMemory[] {
    let memoryArray: IMemory[] = [];
    let memory1: IMemory = {
      title: "Your chosen name for this journey",
      descriptions: [variables.playerName],
    };
    memoryArray.push(memory1);

    let memory2: IMemory = {
      title: "Do you trust the voice?",
      descriptions: [],
    };

    // based on document
    if (variables.trust === 3) {
      memory2.descriptions.push("You don't know why, but you do");
    } else if (variables.trust === 3) {
      memory2.descriptions.push("Trust is a big word. You are cautious");
    } else {
      memory2.descriptions.push("No, you don't");
    }
    memoryArray.push(memory2);

    let memory3: IMemory = {
      title: "The meaning you chose for the lark's story",
      descriptions: [],
    };

    if (variables.mythMeaning !== "") {
      memory3.descriptions.push(variables.mythMeaning);
      memoryArray.push(memory3);
    }

    let memory4: IMemory = {
      title: "The dream you described to the voice",
      descriptions: [],
    };

    if (variables.playerDreams !== "") {
      memory4.descriptions.push(variables.playerDreams);
      memoryArray.push(memory4);

      let memory5: IMemory = {
        title: "And what you think it could mean",
        descriptions: [],
      };

      if (variables.playerDreamShare !== "") {
        memory5.descriptions.push(variables.playerDreamShare);
        memoryArray.push(memory5);
      }
    }

    let memory6: IMemory = {
      title: "Your description of what it feels like to be you",
      descriptions: [],
    };

    if (variables.player_feel_like !== "") {
      memory6.descriptions.push(variables.player_feel_like);
      memoryArray.push(memory6);
    }

    let memory7: IMemory = {
      title: "What you love",
      descriptions: [],
    };

    if (variables.playerLove !== "" || variables.playerLove2 !== "" || variables.playerLove3 !== "") {
      if (variables.playerLove !== "") {
        memory7.descriptions.push(variables.playerLove);
      }
      if (variables.playerLove2 !== "") {
        memory7.descriptions.push(variables.playerLove2);
      }
      if (variables.playerLove3 !== "") {
        memory7.descriptions.push(variables.playerLove3);
      }
      memoryArray.push(memory7);
    }

    let memory8: IMemory = {
      title: "What you can appreciate or admire",
      descriptions: [],
    };

    if (variables.playerThing !== "") {
      memory8.descriptions.push(variables.playerThing);
      memoryArray.push(memory8);
    }
    // if (variables.playerThing == "" && variables.playerLove == "" && variables.playerLove2 == "" && variables.playerLove3 == "") {
    //   memory8.descriptions.push("Player has no loved or liked thing to feed back");
    //   memoryArray.push(memory8);
    // }

    let memory9: IMemory = {
      title: "What you value",
      descriptions: [],
    };

    if (variables.playerValueD2 != "") {
      memory9.descriptions.push(variables.playerValueD2);
      memoryArray.push(memory9);

      let memory10: IMemory = {
        title: "One way you could create more space for this in your life",
        descriptions: [],
      };

      if (variables.playerValueAction != "") {
        memory10.descriptions.push(variables.playerValueAction);
        memoryArray.push(memory10);
      }
    }

    this.capitalizeMemories(memoryArray);
    return memoryArray;
  }

  private getThirdDreamMemories(variables: IThirdDreamVariables): IMemory[] {
    let memoryArray: IMemory[] = [];

    let memory1: IMemory = {
      title: "Your primary drive",
      descriptions: [],
    };

    if (variables.clan === "Striver") memory1.descriptions = ["The urge to strive for perfection and excellence."];
    if (variables.clan === "Rock") memory1.descriptions = ["The drive to be strong, unemotional and stoic."];
    if (variables.clan === "Carer") memory1.descriptions = ["The drive to people-please — to fix things and care for others."];
    if (variables.clan === "Sprinter") memory1.descriptions = ["The drive to sprint — to be quick, efficient and speedy."];
    if (variables.clan === "Worker") memory1.descriptions = ["The drive to try hard — to work as much as you can."];
    memoryArray.push(memory1);

    let memory2: IMemory = {
      title: "How your drive can backfire?",
      descriptions: [variables.playerWeakness],
    }

    memoryArray.push(memory2);

    let memory3: IMemory = {
      title: "How you manage to take control?",
      descriptions: [],
    }

    if (variables.counter_action !== "") {
      memory3.descriptions.push(variables.counter_action);
      memoryArray.push(memory3);
    }

    let memory4: IMemory = {
      title: "The magic words to counter your drive",
      descriptions: [],
    }

    if (variables.counter_player === undefined || variables.counter_player === "") memory4.descriptions = [variables.counter_phrase];
    else  memory4.descriptions = [variables.counter_player];
    memoryArray.push(memory4);

    let memory5: IMemory = {
      title: "How your life could improve if you were to remind yourself of that more often?",
      descriptions: [],
    }

    if (variables.counter_improvement !== "") {
      memory5.descriptions.push(variables.counter_improvement);
      memoryArray.push(memory5);
    }

    let memory6: IMemory = {
      title: "Your Betwixt superpower",
      descriptions: [],
    }

    if (variables.clan === "Striver") memory6.descriptions = ["In the In-Between, your perfectionism is a real, physical thing. You have incredible vision, which lets you catch the smallest speck of dust or spot any imperfection, however tiny. You could count every snowflake under your feet in seconds, if you wanted."];
    if (variables.clan === "Rock") memory6.descriptions = ["In the In-Between, your strength is a real, physical thing. It makes your body indestructible. So much so, that you could become a shield against anything."];
    if (variables.clan === "Carer") memory6.descriptions = ["In the In-Between, the Carer's power is a real, physical thing. You can replicate the needs, feelings and desires of any living being just by observing it. Your empathy can help you solve problems before they become conflicts."];
    if (variables.clan === "Sprinter") memory6.descriptions = ["In the In-Between, Sprinters are gifted with the power of presence. You can literally be in multiple places at once. "];
    if (variables.clan === "Worker") memory6.descriptions = ["In the In-Between, your power is a real, physical thing. Workers here can build impossible structures in a matter of moments. See that tower in the distance? You could build one twice its size in a heartbeat."];
    memoryArray.push(memory6);

    let memory7: IMemory = {
      title: "Your favourite spot from the past",
      descriptions: [],
    }

    if (variables.playerSafePlace !== "") {
      memory7.descriptions.push(`The ${variables.playerSafePlace}`);
      memoryArray.push(memory7);

      let memory8: IMemory = {
        title: "Your favourite things about this place",
        descriptions: [],
      }

      if (variables.playerSafePlaceFeeling !== "") {
        memory8.descriptions.push(variables.playerSafePlaceFeeling);
        memoryArray.push(memory8);

        let memory9: IMemory = {
          title: "What this place says about you as a person",
          descriptions: [],
        }

        if (variables.playerSafePlaceMeaning !== "" || variables.playerSafePlaceMeaningFit !== "" || variables.playerSafePlaceMeaningNeed !== "" || variables.playerSafePlaceMeaningPurpose !== "") {
          if (variables.playerSafePlaceMeaning !== "") memory9.descriptions = [variables.playerSafePlaceMeaning];
          if (variables.playerSafePlaceMeaningFit !== "") memory9.descriptions = [`That the kind of environment you thrive in is ${this.pronounSwap(variables.playerSafePlaceMeaningFit)}`];
          if (variables.playerSafePlaceMeaningNeed !== "") memory9.descriptions = [`That one thing you need in order to feel okay is ${this.pronounSwap(variables.playerSafePlaceMeaningNeed)}`];
          if (variables.playerSafePlaceMeaningPurpose !== "") memory9.descriptions = [`That one thing that gives you purpose is ${this.pronounSwap(variables.playerSafePlaceMeaningPurpose)}`];

          memoryArray.push(memory9);
        }
      }
    }

    this.capitalizeMemories(memoryArray);
    return memoryArray;
  }

  private getFourthDreamMemories(variables: IFourthDreamVariables): IMemory[] {
    let memoryArray: IMemory[] = [];

    let memory1 = {
      title: "The emotion you observed and explored in Dream IV",
      descriptions: [],
    }

    if (variables.D4_alt === 0) {
      memory1.descriptions = [variables.emotion]
      memoryArray.push(memory1);

      let memory2 = {
        title: "The related moment you discussed with the voice",
        descriptions: [],
      }

      if (variables.playerBasicDetails !== "" && variables.present === 1 || variables.playerBasicDetails !== "" && variables.present === 0) {
        if (variables.playerBasicDetails !== "" && variables.present === 1) memory2.descriptions = [`You talked about your current experience. Here's what you said: ${variables.playerBasicDetails}`];
        if (variables.playerBasicDetails !== "" && variables.present === 0) memory2.descriptions = [variables.playerBasicDetails];
        memoryArray.push(memory2);

        let memory3 = {
          title: `The role played by the feeling of ${variables.emotion} in that moment`,
          descriptions: [],
        }

        if (variables.player1stPersonEmot !== "") {
          memory3.descriptions = [variables.player1stPersonEmot];
          memoryArray.push(memory3);

          let memory4 = {
            title: "How you viewed this moment while self-distancing",
            descriptions: [],
          }

          if (variables.playerSceneDescription !== "") {
            memory4.descriptions = [variables.playerSceneDescription];
            memoryArray.push(memory4);

            let memory5 = {
              title: "The message you gave your distant self",
              descriptions: [],
            }

            if (variables.playerMessage !== "") {
              memory5.descriptions = [variables.playerMessage];
              memoryArray.push(memory5);
            }
          }
        }
      }
    }

    this.capitalizeMemories(memoryArray);
    return memoryArray;
  }

  private getFifthDreamMemories(variables: IFifthDreamVariables): IMemory[] {
    let memoryArray: IMemory[] = [];

    let memory1 = {
      title: "Your chimeric companion",
      descriptions: [],
    };

    if (variables.chimera_engaged == "cat") memory1.descriptions = ["A cat with a row of golden wings growing from its sides."];
    if (variables.chimera_engaged == "dog") memory1.descriptions = ["A dog with long tusks and a coat of fine, silky hair."];
    if (variables.chimera_engaged == "lion") memory1.descriptions = ["A flying creature with the body of a lion and the head of an eagle."];
    if (variables.chimera_engaged == "deer") memory1.descriptions = ["A deer with the delicate branches of a rosebush sprouting from its head."];
    memoryArray.push(memory1);

    let memory2 = {
      title: "Your chimeric companion",
      descriptions: [],
    };

    if (variables.opposite_effect_example !== "") {
      memory2.descriptions = [`I ${variables.opposite_effect_example}`];
      memoryArray.push(memory2);

      let memory3 = {
        title: "The result of this behaviour",
        descriptions: [],
      };

      if (variables.opposite_effect_result != "") {
        memory3.descriptions = [variables.opposite_effect_result];
        memoryArray.push(memory3);

        let memory4 = {
          title: "The need you're attempting to fulfil",
          descriptions: [],
        };

        if (variables.good_thing != "") {
          memory4.descriptions = [variables.good_thing];
          memoryArray.push(memory4);

          let memory5 = {
            title: "Other ways you could begin to meet that need",
            descriptions: [],
          };

          if (variables.smart_part_a != "" || variables.free_part_a != "" || variables.kind_part_a != "" || variables.smart_person_a != "" || variables.creative_person_a != "" || variables.loving_person_a != "" || variables.good_thing_self != "") {
            if (variables.smart_part_a != "") {
              let smartPartText = `Here's your smartest, wisest part's take on ${variables.good_thing === "control over your life" ? "achieving more control over your life" : "finding " + this.pronounSwap(variables.good_thing)}: ${variables.smart_part_a}`;
              memory5.descriptions.push(smartPartText);
            }
            if (variables.free_part_a != "") {
              let freePartText = `Here's what you said when the voice asked about your most creative and free-thinking part: ${variables.free_part_a}`;
              memory5.descriptions.push(freePartText);
            }
            if (variables.kind_part_a != "") {
              let kindPartText = `Here's what you said when the voice asked about your kindest, most nurturing part: ${variables.kind_part_a}`;
              memory5.descriptions.push(kindPartText);
            }
            if (variables.smart_person_a != "") {
              let smartPersonText = `Here's ${variables.smart_person_a}'s take on ${variables.good_thing === "control over your life" ? "achieving more control over your life" : "finding " + this.pronounSwap(variables.good_thing)}: ${variables.smart_person_a}`;
              memory5.descriptions.push(smartPersonText);
            }
            if (variables.creative_person_a != "") {
              let creativePersonText = `Here's ${variables.creative_person_a}'s take on ${variables.good_thing === "control over your life" ? "achieving more control over your life" : "finding " + this.pronounSwap(variables.good_thing)}: ${variables.creative_person_a}`;
              memory5.descriptions.push(creativePersonText);
            }
            if (variables.loving_person_a != "") {
              let lovingPersonText = `Here's ${variables.loving_person_a}'s take on ${variables.good_thing === "control over your life" ? "achieving more control over your life" : "finding " + this.pronounSwap(variables.good_thing)}: ${variables.loving_person_a}`;
              memory5.descriptions.push(lovingPersonText);
            }
            if (variables.good_thing_self != "") {
              let goodThingSelfText = `Here's your future self's take on ${variables.good_thing === "control over your life" ? "achieving more control over your life" : "finding " + this.pronounSwap(variables.good_thing)}: ${variables.good_thing_self}`;
              memory5.descriptions.push(goodThingSelfText);
            }
            memoryArray.push(memory5);

            let memory6 = {
              title: "The route you chose",
              descriptions: [],
            };

            if (variables.good_thing_choice1 != "") {
              memory6.descriptions = [variables.good_thing_choice1];
              memoryArray.push(memory6);

              let memory7 = {
                title: "The very first thing you need to start doing in order to move in that direction",
                descriptions: [],
              };

              if (variables.good_thing_choice2 != "") {
                memory7.descriptions = [variables.good_thing_choice2];
                memoryArray.push(memory7);
              }
            }
          }
        }
      }
    }

    let memory8 = {
      title: "When the monster appeared",
      descriptions: [],
    };

    if (variables.close_eyes === 0) {
      memory8.descriptions = ["You dared to look at it"];
    } else {
      memory8.descriptions = ["You closed your eyes"];
    }
    memoryArray.push(memory8);

    this.capitalizeMemories(memoryArray);
    return memoryArray;
  }

  private getSixthDreamMemories(variables: ISixthDreamVariables): IMemory[] {
    let memoryArray: IMemory[] = [];

    let memory1 = {
      title: "One thing you fear, deep down",
      descriptions: [],
    };

    if (variables.your_fear != "fear") {
      if (variables.your_fear === "fear of failure") {
        memory1.descriptions = ["Failure"];
      } else if (variables.your_fear === "fear of rejection") {
        memory1.descriptions = ["Rejection"];
      } else if (variables.your_fear === "fear of intimacy") {
        memory1.descriptions = ["Intimacy"];
      } else if (variables.your_fear === "fear of humiliation") {
        memory1.descriptions = ["Humiliation"];
      } else if (variables.your_fear === "fear of being alone") {
        memory1.descriptions = ["Being alone"];
      } else if (variables.your_fear === "fear") {
        memory1.descriptions = ["There's nothing that you fear"];
      } else {
        memory1.descriptions = [variables.feared_thingD6];
      }
      memoryArray.push(memory1);

      let memory2 = {
        title: "One thing you fear, deep down",
        descriptions: [],
      };

      if (variables.fear_thoughtsD6 != "") {
        memory2.descriptions = [`You notice it in your thoughts. Here's the example you gave the voice: ${variables.fear_thoughtsD6}`];
        memoryArray.push(memory2);
      }
      if (variables.fear_feelingD6 != "") {
        memory2.descriptions = [`You feel it in your body. Here's how you described the experience: ${variables.fear_feelingD6}`];
        memoryArray.push(memory2);
      }
      if (variables.fear_behD6 != "") {
        memory2.descriptions = [`You see it in your behaviour. Here's the example you gave the voice: ${variables.fear_behD6}`];
        memoryArray.push(memory2);
      }
      if (variables.fear_otherD6 != "") {
        memory2.descriptions = [`You sense it in everything: your mind, your body, your actions. Here's the description you gave the voice: ${variables.fear_otherD6}`];
        memoryArray.push(memory2);
      }
    }

    let memory3 = {
      title: "Your reaction to the monster's advance",
      descriptions: [],
    };

    if (variables.D6_freeze === 1) {
      memory3.descriptions = ["Without thinking, your body reacted to the threat and you froze"];
    }
    if (variables.D6_fight === 1) {
      memory3.descriptions = ["Without thinking, your body reacted to the threat and you readied yourself to fight"];
    }
    if (variables.D6_flee === 1) {
      memory3.descriptions = ["Without thinking, your body reacted to the threat and you readied yourself to flee"];
    }
    memoryArray.push(memory3);

    this.capitalizeMemories(memoryArray);
    return memoryArray;
  }

  private getSeventhDreamMemories(variables: ISeventhDreamVariables): IMemory[] {
    let memoryArray: IMemory[] = [];

    let memory1 = {
      title: "How you tend to react to threat, conflict or stress",
      descriptions: [],
    };

    if (variables.fighter === 1) {
      memory1.descriptions = ["You fight"];
    }
    if (variables.fleer === 1) {
      memory1.descriptions = ["You flee"];
    }
    if (variables.freezer === 1) {
      memory1.descriptions = ["You freez"];
    }
    if (variables.fawner === 1) {
      memory1.descriptions = ["You fawn"];
    }
    if (variables.equaller === 1) {
      let equallerText = `You react in many different ways, but you chose to work on a pattern of ${variables.fighter === 1 ? 'fighting' : ''}${variables.fleer === 1 ? 'fleeing' : ''}${variables.freezer === 1 ? 'freezing' : ''}${variables.fawner === 1 ? 'fawning' : ''} in this dream`;
      memory1.descriptions = [equallerText];
    }
    memoryArray.push(memory1);

    let memory2 = {
      title: `How your chosen pattern of ${variables.fighter === 1 ? 'fighting' : ''}${variables.fleer === 1 ? 'fleeing' : ''}${variables.freezer === 1 ? 'freezing' : ''}${variables.fawner === 1 ? 'fawning' : ''} plays out`,
      descriptions: [],
    };

    if (variables.no_pattern_D7 === 0) {
      if (variables.fighter === 1) {
        memory2.descriptions.push(`First, the pattern is triggered by ${this.pronounSwap(variables.fight_trigger)}.`);
        memory2.descriptions.push(`Which makes you feel ${this.pronounSwap(variables.fight_feeling)}.`);
        memory2.descriptions.push(`Then, to fight, you ${this.pronounSwap(variables.player_fight)}.`);
        memory2.descriptions.push(`Finally, as this habit repeats, the big picture consequence is that it makes you feel like you ${this.pronounSwap(variables.fight_self_talk2)}.`);
      }
      if (variables.fleer === 1) {
        memory2.descriptions.push(`First, the pattern is triggered by ${this.pronounSwap(variables.flee_trigger)}.`);
        memory2.descriptions.push(`Which makes you feel ${this.pronounSwap(variables.flee_feeling)}.`);
        memory2.descriptions.push(`Then, to fight, you ${this.pronounSwap(variables.player_flee)}.`);
        memory2.descriptions.push(`Finally, as this habit repeats, the big picture consequence is that it makes you feel like you ${this.pronounSwap(variables.flee_self_talk2)}.`);
      }
      if (variables.freezer === 1) {
        memory2.descriptions.push(`You tend to freeze when you ${this.pronounSwap(variables.freeze_thing)}, and your pattern is triggered by ${this.pronounSwap(variables.freeze_trigger)}.`);
        memory2.descriptions.push(`This makes you feel ${this.pronounSwap(variables.freeze_feeling)}.`);
        memory2.descriptions.push(`Then, when you freeze, you ${this.pronounSwap(variables.player_freeze)}.`);
        memory2.descriptions.push(`Finally, as this habit repeats, the big picture consequence is that it makes you feel like you ${this.pronounSwap(variables.freeze_self_talk2)}.`);
      }
      if (variables.fawner === 1) {
        memory2.descriptions.push(`First, the pattern is triggered by ${this.pronounSwap(variables.fawn_trigger)}.`);
        memory2.descriptions.push(`Which makes you feel ${this.pronounSwap(variables.fawn_feeling)}.`);
        memory2.descriptions.push(`Then, to fight, you ${this.pronounSwap(variables.player_fawn)}.`);
        memory2.descriptions.push(`Finally, as this habit repeats, the big picture consequence is that it makes you feel like you ${this.pronounSwap(variables.fawn_self_talk2)}.`);
      }
      memoryArray.push(memory2);

      let memory3 = {
        title: "Your window of opportunity within this pattern",
        descriptions: [],
      };

      if (variables.fighter === 1 && variables.window === "trigger") memory3.descriptions = [`Just after you're triggered by ${this.pronounSwap(variables.fight_trigger)}.`];
      if (variables.fighter === 1 && variables.window === "feeling") memory3.descriptions = [`Just after you begin to feel ${this.pronounSwap(variables.fight_feeling)}.`];
      if (variables.fighter === 1 && variables.window === "behaviour") memory3.descriptions = [`Just after you begin to fight.`];
      if (variables.fighter === 1 && variables.window === "specific") memory3.descriptions = [variables.fight_player_window];

      if (variables.fleer === 1 && variables.window === "trigger") memory3.descriptions = [`Just after you're triggered by ${this.pronounSwap(variables.flee_trigger)}.`];
      if (variables.fleer === 1 && variables.window === "feeling") memory3.descriptions = [`Just after you begin to feel ${this.pronounSwap(variables.flee_feeling)}.`];
      if (variables.fleer === 1 && variables.window === "behaviour") memory3.descriptions = [`Just after you begin to flee.`];
      if (variables.fleer === 1 && variables.window === "specific") memory3.descriptions = [variables.flee_player_window];

      if (variables.freezer === 1 && variables.window === "trigger") memory3.descriptions = [`Just after you're triggered by ${this.pronounSwap(variables.freeze_trigger)}.`];
      if (variables.freezer === 1 && variables.window === "feeling") memory3.descriptions = [`Just after you begin to feel ${this.pronounSwap(variables.freeze_feeling)}.`];
      if (variables.freezer === 1 && variables.window === "behaviour") memory3.descriptions = [`Just after you begin to ${this.pronounSwap(variables.player_freeze)}.`];
      if (variables.freezer === 1 && variables.window === "specific") memory3.descriptions = [variables.freeze_player_window];

      if (variables.fawner === 1 && variables.window === "trigger") memory3.descriptions = [`Just after you're triggered by ${this.pronounSwap(variables.fawn_trigger)}.`];
      if (variables.fawner === 1 && variables.window === "feeling") memory3.descriptions = [`Just after you begin to feel ${this.pronounSwap(variables.fawn_feeling)}.`];
      if (variables.fawner === 1 && variables.window === "behaviour") memory3.descriptions = [`Just after you begin to fawn.`];
      if (variables.fawner === 1 && variables.window === "specific") memory3.descriptions = [variables.fawn_player_window];

      if (memory3.descriptions.length > 0) {
        memoryArray.push(memory3);

        let memory4 = {
          title: "The magic words you need the voice in your head to say when the window of opportunity opens",
          descriptions: [],
        };

        let memory5 = {
          title: "The improvements you could make if you use this",
          descriptions: [],
        };

        if (variables.fighter === 1 && variables.fight_reminder != "") {
          memory4.descriptions = [variables.fight_reminder];
          memoryArray.push(memory4);
          if (variables.fight_player_window_2 != "") {
            memory5.descriptions = [variables.fight_player_window_2];
            memoryArray.push(memory5);
          }
        }
        if (variables.fleer === 1 && variables.flee_reminder != "") {
          memory4.descriptions = [variables.flee_reminder];
          memoryArray.push(memory4);
          if (variables.flee_player_window2 != "") {
            memory5.descriptions = [variables.flee_player_window2];
            memoryArray.push(memory5);
          }
        }
        if (variables.freezer === 1 && variables.freeze_reminder != "") {
          memory4.descriptions = [variables.freeze_reminder];
          memoryArray.push(memory4);
          if (variables.freeze_player_window_2 != "") {
            memory5.descriptions = [variables.freeze_player_window_2];
            memoryArray.push(memory5);
          }
        }
        if (variables.fawner === 1 && variables.fawn_reminder != "") {
          memory4.descriptions = [variables.fawn_reminder];
          memoryArray.push(memory4);
          if (variables.fawn_player_window_2 != "") {
            memory5.descriptions = [variables.fawn_player_window_2];
            memoryArray.push(memory5);
          }
        }
      }
    }

    this.capitalizeMemories(memoryArray);
    return memoryArray;
  }

  private getEighthDreamMemories(variables: IEighthDreamVariables): IMemory[] {
    let memoryArray: IMemory[] = [];

    let memory1 = {
      title: "Things you loved to do as a child",
      descriptions: [],
    };

    if (variables.first_moment1 != "" || variables.first_moment2 != "" || variables.first_moment3 != "") {
      if (variables.first_moment1 != "") memory1.descriptions.push(variables.first_moment1);
      if (variables.first_moment2 != "") memory1.descriptions.push(variables.first_moment2);
      if (variables.first_moment3 != "") memory1.descriptions.push(variables.first_moment3);
      memoryArray.push(memory1);
    }

    let memory2 = {
      title: "Instances of engagement from the mid part of your life",
      descriptions: [],
    };

    if (variables.second_moment1 != "" || variables.second_moment2 != "" || variables.second_moment3 != "") {
      if (variables.second_moment1 != "") memory2.descriptions.push(variables.second_moment1);
      if (variables.second_moment2 != "") memory2.descriptions.push(variables.second_moment2);
      if (variables.second_moment3 != "") memory2.descriptions.push(variables.second_moment3);
      memoryArray.push(memory2);
    }

    let memory3 = {
      title: "Instances of engagement from the most recent stage of your life",
      descriptions: [],
    };

    if (variables.third_moment1 != "" || variables.third_moment2 != "" || variables.third_moment3 != "") {
      if (variables.third_moment1 != "") memory3.descriptions.push(variables.third_moment1);
      if (variables.third_moment2 != "") memory3.descriptions.push(variables.third_moment2);
      if (variables.third_moment3 != "") memory3.descriptions.push(variables.third_moment3);
      memoryArray.push(memory3);
    }

    let memory4 = {
      title: "The person whose eyes you viewed yourself through",
      descriptions: [],
    };

    if (variables.known_person_name != "")memory4.descriptions.push(this.pronounSwap(this.transformationService.personSwap(variables.known_person_name)));
    if (variables.objObserver === 1) memory4.descriptions.push("An objective observer");
    if (memory4.descriptions.length > 0) memoryArray.push(memory4);

    let memory5 = {
      title: "The person whose eyes you viewed yourself through",
      descriptions: [],
    };

    if (variables.player_strength1 != "" || variables.player_strength2 != "" || variables.player_strength3 != "" || variables.strengths_list != "" || variables.strength2 != "") {
      if (variables.player_strength1 != "") memory5.descriptions.push(variables.player_strength1);
      if (variables.player_strength2 != "") memory5.descriptions.push(variables.player_strength2);
      if (variables.player_strength3 != "") memory5.descriptions.push(variables.player_strength3);
      if (variables.strengths_list != "") memory5.descriptions.push(variables.strengths_list);
      if (variables.strength2 != "") memory5.descriptions.push(variables.strength2);
      memoryArray.push(memory5);
    }

    let memory6 = {
      title: "What you are particularly good at",
      descriptions: [],
    };

    if (variables.player_unique_strength != "") {
      memory6.descriptions = [variables.player_unique_strength];
      memoryArray.push(memory6);
    }

    let memory7 = {
      title: "The strength you want to own fully",
      descriptions: [],
    };

    if (variables.chosen_strength != "") {
      memory7.descriptions = [variables.chosen_strength];
      memoryArray.push(memory7);
    }

    this.capitalizeMemories(memoryArray);
    return memoryArray;
  }

  private getNinthDreamMemories(variables: INinthDreamVariables): IMemory[] {
    let memoryArray: IMemory[] = [];

    let memory1 = {
      title: "What you did when you discovered the twinkling gemstone",
      descriptions: [],
    };

    if (variables.smell === 1) {
      memory1.descriptions = ["You carefully lowered yourself to the ground and brought your nose to the flower. You wouldn't expect gems to smell like anything, but these ones did. They smelt like so much at the same time — the lovely things, the terrible things: ocean depths and poppy-studded meadows, burnt wood and dried oregano, a loved one's scent and baby powder and so much more"];
    }
    if (variables.pick === 1) {
      memory1.descriptions = ["You picked up the bud gently, and it didn't feel like cutting a flower, but more like collecting a precious stone or a small fossil discovered on a sandy beach."];
    }
    if (variables.listen === 1) {
      memory1.descriptions = ["You carefully lowered yourself to the ground and brought your ear to the flower. You wouldn't expect gems to sound like much, but this one did. Like a strange conch shell in whose coils the ocean still echoes, the gem flower pulsed with so much sound: "];
      memory1.descriptions.push("the sighing of the wind in tall grass, long-ago voices whispering through still air, the low moan of glaciers as they break and fall into the sea.");
    }
    memoryArray.push(memory1);

    let memory2 = {
      title: "The name you gave your monster",
      descriptions: [variables.monster_name],
    };
    memoryArray.push(memory2);

    let memory3 = {
      title: `The emotion ${variables.monster_name} most wants to feel`,
      descriptions: [],
    };

    if (variables.monster_comfort == "comfort") memory3.descriptions = ["A simple sense of comfort"];
    if (variables.monster_comfort == "love") memory3.descriptions = ["The warmth of love"];
    if (variables.monster_comfort == "lightness") memory3.descriptions = ["A sense of lightness, release"];
    if (variables.monster_comfort == "playfulness") memory3.descriptions = ["The feeling of playfulness"];
    if (variables.monster_comfort == "pride") memory3.descriptions = ["A sense of pride"];
    if (variables.monster_comfort == "confidence") memory3.descriptions = ["A feeling of confidence, self-esteem"];
    if (variables.monster_comfort != "") memory3.descriptions.push(`A feeling of ${variables.monster_comfort}`);
    memoryArray.push(memory3);

    let memory4 = {
      title: "Your reflection on the stories you tell yourself",
      descriptions: [],
    };

    if (variables.fearNotFact != "") {
      memory4.descriptions = [variables.fearNotFact];
      memoryArray.push(memory4);
    }

    let memory5 = {
      title: "The burdensome story you chose to reclaim from your monster",
      descriptions: [],
    };

    if (variables.theme != "" || variables.story_words != "" || variables.story_words2 != "") {
      if (variables.theme != "") memory5.descriptions.push(`Theme ${variables.theme}`);
      if (variables.story_words != "") memory5.descriptions.push(`Story ${variables.story_words}`);
      if (variables.story_words2 != "") memory5.descriptions.push(`You couldn't put the story into words, but here's how you described your experience of it: ${variables.story_words2}`);
      memoryArray.push(memory5);

      let memory6 = {
        title: "How this story shows up for you",
        descriptions: [],
      };

      if (variables.doubtStory === 1 || variables.judgeStory === 1 || variables.feelingStory === 1 || variables.fearStory === 1) {
        if (variables.doubtStory === 1) memory6.descriptions.push("It presents as a doubtful thought you sometimes notice when the pressure is on.");
        if (variables.judgeStory === 1) memory6.descriptions.push("t presents as a judgement you make of yourself");
        if (variables.fearStory === 1) memory6.descriptions.push("It's a fear you hold about the type of person you are");
        if (variables.feelingStory === 1) memory6.descriptions.push("It presents as a feeling");
        memoryArray.push(memory6);
      }
    }

    let memory7 = {
      title: "Dream IX discovery",
      descriptions: [],
    };

    memory7.descriptions.push("The monster is your shadow — all the parts of you that you're yet to either face or own, and because of this, it was banished from the city long ago.");
    memory7.descriptions.push("But shutting something out doesn't stop it from being.");
    memory7.descriptions.push("In fact, the longer it stayed in its place of exile, the bigger it grew.");
    memoryArray.push(memory7);

    this.capitalizeMemories(memoryArray);
    return memoryArray;
  }

  private getEleventhDreamMemories(variables: IEleventhDreamVariables): IMemory[] {
    let memoryArray: IMemory[] = [];

    let playerGoodPlaceText = '';
    if (variables.playerGoodPlace == "a calmer mind") playerGoodPlaceText = "a calmer mind";
    else if (variables.playerGoodPlace == "more confidence") playerGoodPlaceText = "increased confidence";
    else if (variables.playerGoodPlace == "more excitement") playerGoodPlaceText = "more excitement or engagement in your life";
    else if (variables.playerGoodPlace == "more connection") playerGoodPlaceText = "more connection in your life";
    else if (variables.playerGoodPlace == "growth") playerGoodPlaceText = "growth or change";
    else if (variables.playerGoodPlace == "contentment") playerGoodPlaceText = "increased contentment";
    else playerGoodPlaceText = `having ${this.pronounSwap(variables.playerGoodPlace)}`;

    let memory1 = {
      title: `Have you noticed glimmers of ${playerGoodPlaceText} since you started your journey through the In-Between.`,
      descriptions: [],
    };

    if (variables.artQ === "yes") memory1.descriptions = ["Yes, I think I have"];
    if (variables.artQ === "maybe") memory1.descriptions = ["Maybe a little"];
    if (variables.artQ === "no") memory1.descriptions = ["No, I've not noticed this at all"];
    memoryArray.push(memory1);

    let memory2 = {
      title: "The example of such change you gave to the voice",
      descriptions: [],
    };

    if (variables.playerEvidenceOwn != "") {
      memory2.descriptions = [variables.playerEvidenceOwn];
      memoryArray.push(memory2);
    }

    let memory3 = {
      title: "The new aim you set yourself",
      descriptions: [],
    };

    if (variables.artQ != "no" && variables.notThere === 0) {
      memory3.descriptions = [`To gain ${variables.playerGoodPlaceD11}`];
      memoryArray.push(memory3);
    }

    let memory4 = {
      title: "What you have noticed instead",
      descriptions: [],
    };

    if (variables.artQ != "no" && variables.has_signs === 1) {
      memory4.descriptions = [`You've noticed moments when you've had ${variables.playerGoodPlaceD11}`];
      memoryArray.push(memory4);

      let memory5 = {
        title: "If you were to break that goal down into the smallest increments, the first step towards achieving it would be to gain",
        descriptions: [],
      };

      if (variables.notThere === 1) {
        memory5.descriptions = [variables.playerGoodPlaceD11];
        memoryArray.push(memory5);
      }
    }

    let memory6 = {
      title: "The new form you chose for your monster",
      descriptions: [],
    };

    if (variables.chimeraMonster === 1) {
      let chimeraExtraText = "";
      if (variables.chimeraExtra == "winged") chimeraExtraText = "with exquisite white wings";
      if (variables.chimeraExtra == "energy-wielding") chimeraExtraText = `with a perfect orb of ethereal light ${variables.fairy ? 'cupped in its hands' : 'floating in front of it'}`;
      if (variables.chimeraExtra == "glowing") chimeraExtraText = "with an ethereal flaming aura that could be seen for miles and miles";
      if (variables.chimeraExtra == "mirrored") chimeraExtraText = "with a glistening coat of minute mirrors covering its entire body";
      if (variables.chimeraExtra == "gemmed") chimeraExtraText = "with a row of glistening crystals running the length of its back";
      memory6.descriptions = [`A magnificent${variables.chimeraExtra == ' glass ' ? variables.chimeraExtra : ''}${variables.yourMonster} ${variables.yourMonster}${chimeraExtraText != '' ? ', ' + chimeraExtraText : ''}`];
    }
    if (variables.animalMonster === 1) {
      memory6.descriptions = [variables.chimeraAnimal];
    }
    if (variables.childSelfMonster === 1) {
      memory6.descriptions = [`It transformed into your child self - ${variables.childSelfMonster}`];
    }
    memoryArray.push(memory6);

    let memory7 = {
      title: "The reason for your choice",
      descriptions: [],
    };

    if (variables.playerAnimalReason != "" || variables.playerHeadReason != "" || variables.playerBodyReason != "") {
      if (variables.playerAnimalReason != "") memory7.descriptions = [variables.playerAnimalReason];
      if (variables.playerHeadReason != "") memory7.descriptions = [`Of the head: ${variables.playerAnimalReason}`];
      if (variables.playerBodyReason != "") memory7.descriptions = [`Of the body: ${variables.playerAnimalReason}`];
      memoryArray.push(memory7);
    }

    let memory8 = {
      title: "The reason for your choice",
      descriptions: [],
    };

    if (variables.hill === 1) memory8.descriptions = ["You climbed to the top if the hill - back to where it all started"];
    if (variables.shore === 1) memory8.descriptions = ["You travelled out of the city and through the desert to the shore"];
    if (variables.forest === 1) memory8.descriptions = ["You travelled through the rich, dense forest"];
    memoryArray.push(memory8);

    let memory9 = {
      title: "The new home you built for your transformed monster",
      descriptions: [],
    };

    if (variables.playerSpecialPlaceVDescription != "") {
      memory9.descriptions.push(`You see ${this.pronounSwap(variables.playerSpecialPlaceVDescription)}`);
      if (variables.playerSpecialPlaceVThing != "") {
        let thingsText = `${variables.playerSpecialPlaceVThing3 != "" ? ", the " + this.pronounSwap(variables.playerSpecialPlaceVThing2) + " and the " + this.pronounSwap(variables.playerSpecialPlaceVThing3) : ", the " + this.pronounSwap(variables.playerSpecialPlaceVThing2)}`;
        memory9.descriptions.push(`The most important parts of this image are the ${this.pronounSwap(variables.playerSpecialPlaceVThing)}${thingsText}.`);

        if (variables.own_soundSP == 0) {
          let soundText = "";
          if (variables.forestBirdsongSP_sound === 1) soundText = "beautiful birdsong";
          if (variables.birdsongSP_sound === 1) soundText = "beautiful birdsong";
          if (variables.waterSP_sound === 1) soundText = "the sound of water";
          if (variables.windSP_sound === 1) soundText = "he sound of the raging wind";
          if (variables.streetSP_sound === 1) soundText = "he sound of people - the world passing by";
          if (variables.wavesSP_sound === 1) soundText = "the sound of the waves";
          if (variables.thunderSP_sound === 1) soundText = "the sound of rain and thunder";
          if (variables.peopleSP_sound === 1) soundText = "the bustling sound of many people talking";
          if (variables.fireSP_sound === 1) soundText = "the cracking of a fire";
          if (variables.birdsong_streetSP_sound === 1) soundText = "birdsong and people outside";
          if (variables.thunderSP_sound === 1) soundText = "the sound of a thunderstorm outside";
          if (variables.peopleSP_sound === 1) soundText = "the bustling sound of many people talking";
          memory9.descriptions.push(`You hear ${soundText}`);
        }
        if (variables.playerSpecialPlaceFeeling != "") memory9.descriptions.push(`You feel ${variables.playerSpecialPlaceFeeling}`)
      }
    }
    memoryArray.push(memory9);

    this.capitalizeMemories(memoryArray);
    return memoryArray;
  }

  getTenthDreamMemories(variables: ITenthDreamVariables): IMemory[] {
    let memoryArray: IMemory[] = [];

    //Main route
    if (variables.alt_process === 0 && variables.no_mem === 0 && variables.playerMemory1 !== "") {
      if (variables.affect_bridge !== "") {
        let memory1: IMemory = {
          title: "The negative emotion you explored",
          descriptions: [`The feeling of ${variables.affect_bridge}`],
        };
        memoryArray.push(memory1);

        let memory2: IMemory = {
          title: "The physical experience of that emotion",
          descriptions: [`You described it as the ${variables.affect_bridge_mov}, ${variables.affect_bridge_weight} ${variables.affect_bridge_loc}`],
        }
        memoryArray.push(memory2);
      }

      if (variables.playerMemory1 !== "" || variables.content_free === 1) {
        if (variables.playerMemory1 !== "") {
          let memory2: IMemory = {
            title: "The memory you reframed",
            descriptions: [variables.playerMemory1],
          }
          memoryArray.push(memory2);
        }

        let memory3: IMemory = {
          title: "What your remembered self most needed to know",
          descriptions: [],
        }
        if (variables.YS_message2 !== "") memory3.descriptions.push(`That ${variables.YS_message2}`);
        if (variables.AS_message2 !== "") memory3.descriptions.push(`That ${variables.AS_message2}`);
        if (variables.YS_messagePlayer !== "") memory3.descriptions.push(variables.YS_messagePlayer);
        memoryArray.push(memory3);

        if (variables.playerMessage_own !== "" || variables.important_other !== "" || variables.written_message === 1 || variables.flower === 1 || variables.gem === 1 || variables.finding === 1) {
          let memory4: IMemory = {
            title: "How you delivered this message",
            descriptions: [],
          }
          if (variables.playerMessage_own !== "") memory4.descriptions.push(variables.playerMessage_own)
          else if (variables.important_other !== "") memory4.descriptions.push(`It was conveyed by  ${this.transformationService.decapitalizeAndSwapPronouns(this.transformationService.personSwap(variables.important_other))}`)
          else {
            if (variables.written_message === 1) memory4.descriptions.push("You wrote the message on a slip of paper and passed it down to them");
            if (variables.flower === 1) memory4.descriptions.push("You conveyed the message symbolically, turning it into a flower and passing that down to your remembered self");
            if (variables.gem === 1) memory4.descriptions.push("You conveyed the message symbolically, turning it into a gemstone or crystal and passing it down to your remembered self");
            if (variables.finding === 1) memory4.descriptions.push("You conveyed the message symbolically, turning it into something curious such as a stray feather or seashell, and passing it down to your remembered self");
          }
          memoryArray.push(memory4);
        }
      }

      if (variables.posEmo_main2 !== "" || variables.posEmo_main) {
        if (variables.posEmo_main2 !== "") {
          let memory5: IMemory = {
            title: "The positive emotion your past self could then begin to feel",
            descriptions: [`A ${variables.posEmo_main2}`],
          }
          memoryArray.push(memory5);
        }

        if (variables.posEmo_main) {
          let memory6: IMemory = {
            title: "The positive emotion you saw as a light around your past self",
            descriptions: [variables.posEmo_main],
          }
          memoryArray.push(memory6);
        }

        let memory7: IMemory = {
          title: `The colour of ${variables.posEmo_main}${variables.posEmo_main2}`,
          descriptions: [],
        }
        if (variables.white === 1) memory7.descriptions.push("A bright white");
        if (variables.gold === 1) memory7.descriptions.push("Gold, like the early morning sun");
        if (variables.red === 1) memory7.descriptions.push("A fiery orange-red");
        if (variables.ocean === 1) memory7.descriptions.push("A deep, vibrant blue, like the ocean");
        if (variables.multi === 1) memory7.descriptions.push("A mix of colours, as if light that's been refracted through a crytsal");
        if (variables.babyB === 1) memory7.descriptions.push("A fresh light blue");
        if (variables.pink === 1) memory7.descriptions.push("A soft pastel pink");
        if (variables.green === 1) memory7.descriptions.push("A natural green");
        if (variables.purple === 1) memory7.descriptions.push("A rich purple");
        if (variables.own_col === 1) memory7.descriptions.push(`The colour of ${variables.playerColour_own}`);
        memoryArray.push(memory7);

        let memory8: IMemory = {
          title: "The new and better story you wrote for yourself",
          descriptions: [variables.playerNewStory],
        }
        memoryArray.push(memory8);

        if (variables.futureVision !== "") {
          let memory8: IMemory = {
            title: "How you described the future you who believes that story",
            descriptions: [variables.futureVision],
          }
          memoryArray.push(memory8);
        }
      }
    }

    // Alt route 1
    if (variables.alt_process === 0 && variables.no_mem === 1) {
      if (variables.affect_bridge !== "") {
        let memory9: IMemory = {
          title: "The negative emotion you explored",
          descriptions: [`The feeling of ${variables.affect_bridge}`],
        }
        memoryArray.push(memory9);

        let memory10: IMemory = {
          title: "The physical experience of that emotion",
          descriptions: [`You described it as the ${variables.affect_bridge_mov}, ${variables.affect_bridge_weight} ${variables.affect_bridge_loc}`],
        }
        memoryArray.push(memory10);

        if (variables.emoShape !== "" || variables.playerEmoShape !== "") {
          let memory11: IMemory = {
            title: "The shape you assigned to that feeling",
            descriptions: [],
          }
          if (variables.emoShape === "spherical") memory11.descriptions.push("It's spherical, like a globe");
          else if (variables.emoShape === "angular") memory11.descriptions.push("It's angular with straight edges.");
          else if (variables.emoShape === "spiky") memory11.descriptions.push("It's a sharp, spiky shape.");
          else if (variables.emoShape === "fragile") memory11.descriptions.push("It seems delicate and fragile, like it's made from tissue paper.");
          else if (variables.emoShape === "cloud-like") memory11.descriptions.push("It's wispy and amorphous, like a cloud.");
          else if (variables.emoShape === "cloud-like") memory11.descriptions.push("It's wispy and amorphous, like a cloud.");
          else  memory11.descriptions.push(`It's shaped like a ${variables.playerEmoShape}`);
          memoryArray.push(memory11);

          let memory12: IMemory = {
            title: "How you reduced the intensity of that feeling",
            descriptions: [],
          }
          if (variables.changeShape_choice === "shrink") memory12.descriptions.push("You shrunk it so you could see beyond it");
          if (variables.changeShape_choice === "spin") memory12.descriptions.push("You spun it, fast, onan axis until it collapsed into itself");
          if (variables.changeShape_choice === "disperse") memory12.descriptions.push("You imagined it loosening, lightening and expanding until it dispersed and disappeared completely");
          if (variables.changeShape_choice === "freeze") memory12.descriptions.push("You imagined it freezing so it became brittle and shattered");
          if (variables.changeShape_choice === "own") memory12.descriptions.push(variables.playerChangeEmo);
          memoryArray.push(memory12);

          if (variables.hasPosEmo2 === 1 || variables.posEmo_alt !== "" && variables.hasPosEmo2 === 0 || variables.playerPosEmo_alt !== "" && variables.hasPosEmo2 === 0) {
            let memory13: IMemory = {
              title: `The positive emotion you chose to fill the crater once the feeling of ${variables.affect_bridge} had reduced`,
              descriptions: [],
            }
            if (variables.hasPosEmo2 === 1) memory13.descriptions.push(variables.posEmo_alt2);
            if (variables.posEmo_alt !== "" && variables.hasPosEmo2 === 0) memory13.descriptions.push(variables.posEmo_alt);
            if (variables.playerPosEmo_alt !== "" && variables.hasPosEmo2 === 0) memory13.descriptions.push(variables.playerPosEmo_alt);
            memoryArray.push(memory13);

            if (variables.posEmo_alt !== "") {
              let memory14: IMemory = {
                title: `The colour of ${variables.posEmo_alt}${variables.playerPosEmo_alt}${variables.playerColour2}`,
                descriptions: [],
              }
              if (variables.white === 1) memory14.descriptions.push("A bright white");
              if (variables.gold === 1) memory14.descriptions.push("Gold, like the early morning sun");
              if (variables.red === 1) memory14.descriptions.push("A fiery orange-red");
              if (variables.ocean === 1) memory14.descriptions.push("A deep, vibrant blue, like the ocean");
              if (variables.multi === 1) memory14.descriptions.push("A mix of colours, as if light that's been refracted through a crytsal");
              if (variables.babyB === 1) memory14.descriptions.push("A fresh light blue");
              if (variables.pink === 1) memory14.descriptions.push("A soft pastel pink");
              if (variables.green === 1) memory14.descriptions.push("A natural green");
              if (variables.purple === 1) memory14.descriptions.push("A rich purple");
              if (variables.own_col === 1) memory14.descriptions.push(`The colour of ${variables.playerColour_own}`);
              memoryArray.push(memory14);

              if (variables.relate === 1 || variables.newTime !== "" || variables.career === 1 || variables.resilience === 1 || variables.healthy === 1 || variables.creative === 1 || variables.own_benefit === 1 ) {
                let memory15: IMemory = {
                  title: "How that positive experience could change things",
                  descriptions: [],
                }
                if (variables.relate === 1) memory14.descriptions.push("It would change the way you relate to others");
                if (variables.newTime !== "") memory14.descriptions.push("It would change the way you spend your time");
                if (variables.career === 1) memory14.descriptions.push("It would make a difference to your career");
                if (variables.resilience === 1) memory14.descriptions.push("It would help you to bounce back from failure or rejection more easily");
                if (variables.healthy === 1) memory14.descriptions.push("It could improve your physical health");
                if (variables.creative === 1) memory14.descriptions.push("It would help you to become more creative");
                if (variables.own_benefit === 1) memory14.descriptions.push(variables.playerSelf_descriptionPos1);
                memoryArray.push(memory15);

                if (variables.playerSelf_description2 !== "" && variables.posEmo_D9alt !== "") {
                  let memory16: IMemory = {
                    title: `The positive impact you imagined yourself having with more ${variables.posEmo_D9alt}`,
                    descriptions: [variables.playerSelf_description2],
                  }
                  memoryArray.push(memory16);

                  let memory17: IMemory = {
                    title: "The new and better story you wrote for yourself",
                    descriptions: [variables.playerNewStory],
                  }
                  memoryArray.push(memory17);
                }
              }
            }
          }
        }
      }
    }

    // ALT + D9 Alt
    if (variables.alt_process === 1 && variables.no_mem === 1) {
      if (variables.constellation !== "") {
        let memory18: IMemory = {
          title: "What you traced in the stars above you",
          descriptions: [],
        }
        if (variables.constellation === "peacock") memory18.descriptions.push("A peacock — proud, willing to be seen");
        if (variables.constellation === "horse") memory18.descriptions.push("A horse — strong and beautiful");
        if (variables.constellation === "fox") memory18.descriptions.push("A fox — clever and resourceful");
        memoryArray.push(memory18);
      }

      if (variables.playerSelf_description !== "") {
        let memory19: IMemory = {
          title: "The vision of yourself that you saw in the crater",
          descriptions: [variables.playerSelf_description],
        }
        memoryArray.push(memory19);
      }

      if (variables.posEmo_D9alt !== "") {
        let memory20: IMemory = {
          title: "The theme you chose for your new story",
          descriptions: [variables.posEmo_D9alt],
        }
        memoryArray.push(memory20);

        if (variables.playerExpands !== "") {
          let memory21: IMemory = {
            title: "What this feeling means to you and why you chose it for your theme",
            descriptions: [variables.playerExpands],
          }
          memoryArray.push(memory21);

          if (variables.playerPosEmo_alt !== "") {
            let memory22: IMemory = {
              title: `If the feeling of ${variables.posEmo_D9alt} had a colour`,
              descriptions: [],
            }
            if (variables.white === 1) memory22.descriptions.push("A bright white");
            if (variables.gold === 1) memory22.descriptions.push("Gold, like the early morning sun");
            if (variables.red === 1) memory22.descriptions.push("A fiery orange-red");
            if (variables.ocean === 1) memory22.descriptions.push("A deep, vibrant blue, like the ocean");
            if (variables.multi === 1) memory22.descriptions.push("A mix of colours, as if light that's been refracted through a crytsal");
            if (variables.babyB === 1) memory22.descriptions.push("A fresh light blue");
            if (variables.pink === 1) memory22.descriptions.push("A soft pastel pink");
            if (variables.green === 1) memory22.descriptions.push("A natural green");
            if (variables.purple === 1) memory22.descriptions.push("A rich purple");
            if (variables.own_col === 1) memory22.descriptions.push(`The colour of ${variables.playerColour_own}`);
            memoryArray.push(memory22);

            if (variables.playerSelf_description2 !== "" &&  variables.posEmo_D9alt !== "") {
              let memory23: IMemory = {
                title: `The positive impact you imagined yourself having with more ${variables.posEmo_D9alt}`,
                descriptions: [variables.playerSelf_description2],
              }
              memoryArray.push(memory23);

              let memory24: IMemory = {
                title: "The new and better story you wrote for yourself",
                descriptions: [variables.playerNewStory],
              }
              memoryArray.push(memory24);
            }
          }
        }
      }
    }

    this.capitalizeMemories(memoryArray);
    return memoryArray;
  }

  private getFirstDreamLevelAndPowers(variables: IFirstDreamVariables): IPowerLevel {
    let power1: IPower = {
      title: PowerTitle.COMPASS,
      imageUrl: this.getPowerImageUrl(PowerTitle.COMPASS),
      descriptions: [
        `The power to look forward and know what you want to see.`,
        `The benefits of being present are well-documented but it's important to be able to look ahead, too, especially when this is done through a positive lens. Without this ability, there would be no planning or striving - in order to work towards a goal, you must be able to imagine its completion. But, perhaps more interestingly, looking to the future plays a role in the prevention of self-sabotage - being able to see a "hoped-for" possible self up ahead motivates people to avoid behaviours that would reduce their chances of getting there.`
      ],
      optionals: [
        `Write a letter from your future self to your present self (your future self could be a mere six months ahead or many decades). How does this older version of you view your current life, and what advice might they give you?`
      ]
    };

    let level: ILevel = {
      title: LevelTitle.SEEKER,
      imageUrl: this.getLevelImageUrl(LevelTitle.SEEKER),
      descriptions: [
        `By completing Dream I and becoming a Seeker in the In-Between, you have demonstrated your powers of imagination as well as a willingness to ask and answer unusual questions.`,
        `These two things are what Betwixt is all about, so welcome! We hope you enjoy your journey.`
      ]
    };

    return {
      level,
      powers: [power1]
    }
  }

  private getSecondDreamLevelAndPowers(variables: ISecondDreamVariables): IPowerLevel {
    let powersAndLevels: IPowerLevel = {
      level: {
        title: LevelTitle.SEEKER,
        imageUrl: this.getLevelImageUrl(LevelTitle.SEEKER),
        descriptions: [
          `By completing Dream I and becoming a Seeker in the In-Between, you have demonstrated your powers of imagination as well as a willingness to ask and answer unusual questions.`,
          `These two things are what Betwixt is all about, so welcome! We hope you enjoy your journey.`
        ],
      },
      powers: [],
    };

    if (variables.playerValueD2 !== "") {
      let power1: IPower = {
        title: PowerTitle.SCALES,
        imageUrl: this.getPowerImageUrl(PowerTitle.SCALES),
        descriptions: [
          `The ability to know your values - to weigh up what is and isn't important to you, personally.`,
          `Whether you're aware of them or not, your values form a core part of your personality; they guide your sense of right and wrong, as well as your behaviour and decision-making. They are not, however, set in stone.`,
          `Bringing your values into the light of conscious awareness opens up the possibility to actively live in accordance with the values you wish to uphold, and to gradually let go of inherited values that don't seem to fit with your authentic sense of self.`,
        ],
        optionals: [
          `Write a love letter to one of your key values (this could be a value you mentioned in Dream II or something else entirely). What does this value mean to you? Why is it important to you? What does it bring to your life?`,
        ]
      };
      powersAndLevels.powers.push(power1);
    }

    if (variables.hasLoveD2 === 1) {
      let power2: IPower = {
        title: PowerTitle.ALCHEMY,
        imageUrl: this.getPowerImageUrl(PowerTitle.ALCHEMY),
        descriptions: [
          `The ability to find pleasure in the everyday.`,
          `Research has found that when we bring our full attention to the present moment and the task at hand, we experience greater happiness than when we let our minds drift. This remains true even when what we're doing isn't something we actually enjoy.`,
        ],
        optionals: [
          `Pick one thing you can either see, hear, feel, smell or taste right now and describe it in as much detail as possible.`,
        ]
      };
      powersAndLevels.powers.push(power2);
    }

    if (variables.myth === 1) {
      let power3: IPower = {
        title: PowerTitle.STORY_SEEKER,
        imageUrl: this.getPowerImageUrl(PowerTitle.STORY_SEEKER),
        descriptions: [
          `An interest in story - a key strength because story is how the mind learns.`,
          `"Fiction seems to be more effective at changing beliefs than nonfiction, which is designed to persuade through argument and evidence. Studies show that when we read nonfiction, we read with our shields up. We are critical and skeptical. But when we are absorbed in a story, we drop our intellectual guard. We are moved emotionally, and this seems to make us rubbery and easy to shape."`,
          `― Jonathan Gottschall, The Storytelling Animal`
        ],
        optionals: [
          `What's one story you remember from your childhood? This could be a nursery rhyme, a fairy tale, a family memory (or myth), or an actual event.`,
          `Once you have a story in mind, consider this: looking back, what kind of role did this story play in your development and what does it mean to you now?`,
        ]
      };
      powersAndLevels.powers.push(power3);
    }

    if (variables.dream === 1) {
      let power3: IPower = {
        title: PowerTitle.DREAMER,
        imageUrl: this.getPowerImageUrl(PowerTitle.DREAMER),
        descriptions: [
          `Keen attention to the unconscious world of dreams - the raw and unedited stories of the sleeping mind.`,
          `"We are, as a species, addicted to story. Even when the body goes to sleep, the mind stays up all night, telling itself stories."`,
          `― Jonathan Gottschall, The Storytelling Animal`
        ],
        optionals: [
          `What's one symbol that shows up often in your dreams? This could be a person you dream about frequently, or a house/room/environment that recurs, or a repeating theme/situation. What do you think this symbol could mean?`,
        ]
      };
      powersAndLevels.powers.push(power3);
    }

    return powersAndLevels;
  }

  private getThirdDreamLevelAndPowers(variables: IThirdDreamVariables): IPowerLevel {
    let powersAndLevels: IPowerLevel = {
      level: {
        title: LevelTitle.SEER,
        imageUrl: this.getLevelImageUrl(LevelTitle.SEER),
        descriptions: [
          `You have demonstrated the ability to investigate and understand your automatic reactions and patterns.`,
          `Human beings are creatures of habit. We naturally form patterns of thought, feeling and behaviour, many of which remain unchanged for the duration of our lives. However, not all of our patterns serve us well, and it is only by generating awareness that we can change them. As a Seer, you have opened this door.`,
        ],
      },
      powers: [],
    };

    if (variables.playerSafePlace !== "") {
      let power1: IPower = {
        title: PowerTitle.HAVEN,
        imageUrl: this.getPowerImageUrl(PowerTitle.HAVEN),
        descriptions: [
          `The ability to look back and find comfort in the past - to turn a treasured memory into an inner haven.`,
          `"Safe space visualisation" is a commonly used tool across various styles of therapy, in which the client is guided in committing to memory a calming, soothing or otherwise positive imagined environment.`,
          `This zero-cost and low-effort tool can be a valuable way to calm the nervous system during times of stress, such as in the leadup to an important event, or in the aftermath of an emotionally challenging situation. `,
        ],
        optionals: [
          `Describe your favourite spot using all five senses - what could you see, hear, feel, smell and taste if you were back there now?`,
        ]
      };
      powersAndLevels.powers.push(power1);
    }


    return powersAndLevels;
  }

  private getFourthDreamLevelAndPowers(variables: IFourthDreamVariables): IPowerLevel {
    let powersAndLevels: IPowerLevel = {
      level: {
        title: LevelTitle.SEER,
        imageUrl: this.getLevelImageUrl(LevelTitle.SEER),
        descriptions: [
          `You have demonstrated the ability to investigate and understand your automatic reactions and patterns.`,
          `Human beings are creatures of habit. We naturally form patterns of thought, feeling and behaviour, many of which remain unchanged for the duration of our lives. However, not all of our patterns serve us well, and it is only by generating awareness that we can change them. As a Seer, you have opened this door.`,
        ],
      },
      powers: [],
    };

    if (variables.D4_alt === 0) {
      let power1: IPower = {
        title: PowerTitle.SELF_DISTANCING,
        imageUrl: this.getPowerImageUrl(PowerTitle.SELF_DISTANCING),
        descriptions: [
          `The capacity to see yourself from an outside perspective. This skill helps you to achieve clarity and process upsetting emotions in healthier ways.`,
          `Self-distancing has many benefits. Research shows that this tool helps people cope more easily with both difficult past events and challenging present situations; it decreases the duration of negative emotions, reduces aggressive thoughts and feelings, and improves access to emotion regulation strategies, helping to relieve depressive symptoms.`,
        ],
        optionals: [
          `Think of an argument, dispute or debate you've had with someone else at some stage in your life*. Then, rather than reliving the conflict from your perspective, imagine stepping into the shoes of the other person to describe the experience from their point of view. How does it differ from yours?`,
          `*please make sure this is a memory you feel comfortable revisiting`
        ]
      };
      powersAndLevels.powers.push(power1);
    }

    if (variables.D4_alt === 1 && variables.skipped_all_D4 === 0) {
      let power2: IPower = {
        title: PowerTitle.PRESENCE,
        imageUrl: this.getPowerImageUrl(PowerTitle.PRESENCE),
        descriptions: [
          `The ability to slow down and attend - using your senses - to the world around you. When used in everyday life, this skill can help you to calm the mind, improve your focus and increase feelings of happiness/contentment.`,
        ],
        optionals: [
          `Set a timer for 1 minute and spend the duration focusing on the sounds around you*. Once the time is up, describe the soundscape in the richest detail possible.`,
          `*If you get distracted by your thoughts, don't worry. Simply return to what you can hear as soon as you notice.`
        ]
      };
      powersAndLevels.powers.push(power2);
    }

    if (variables.D4_alt === 1 && variables.skipped_all_D4 === 1) {
      let power3: IPower = {
        title: PowerTitle.PRESENCE,
        imageUrl: this.getPowerImageUrl(PowerTitle.PRESENCE),
        descriptions: [
          `The presence of mind to state your boundaries - to say "no" to the things that don't feel okay for you. This is an invaluable ability that allows for both self-care and healthy, connected relationships with others.`,
        ],
        optionals: [
          `Think of any situation (say, an area of work, or a particular relationship, task or environment) that sometimes feels challenging. Journal about this situation, focusing on what feels "okay" and what feels "not okay" for you within that context. As you do this, consider the boundaries you might need to set for yourself or others to improve your experience of this situation.`,
        ]
      };
      powersAndLevels.powers.push(power3);
    }

    return powersAndLevels;
  }

  private getFifthDreamLevelAndPowers(variables: IFifthDreamVariables): IPowerLevel {
    let powersAndLevels: IPowerLevel = {
      level: {
        title: LevelTitle.SEER,
        imageUrl: this.getLevelImageUrl(LevelTitle.SEER),
        descriptions: [
          `You have demonstrated the ability to investigate and understand your automatic reactions and patterns.`,
          `Human beings are creatures of habit. We naturally form patterns of thought, feeling and behaviour, many of which remain unchanged for the duration of our lives. However, not all of our patterns serve us well, and it is only by generating awareness that we can change them. As a Seer, you have opened this door.`,
        ],
      },
      powers: [],
    };

    if (variables.good_thing != "") {
      let power1: IPower = {
        title: PowerTitle.SIGHT,
        imageUrl: this.getPowerImageUrl(PowerTitle.SIGHT),
        descriptions: [
          `The ability to look beyond a destructive pattern and see the positive intention that motivates it.`,
          `This power plays an important part in the development of self-compassion, self-understanding and the ability to change. When you allow yourself to see the positive motivation behind a destructive decision, you not only make it easier to forgive yourself for the mistake, but also to correct your course by finding new and healthier ways to meet the underlying need(s).`,
        ],
        optionals: [
          `Consider something you have said or done in the past that left you feeling regretful. Using self-distancing (i.e. observing your past self from an outside perspective), explore the intentions behind your decision, even if those intentions now seem misguided or ineffective.`,
        ]
      };
      powersAndLevels.powers.push(power1);
    }

    let power2Desc = "";
    if (variables.chimera_engaged == "cat") power2Desc = "cat with a row of golden wings growing from its sides";
    if (variables.chimera_engaged == "dog") power2Desc = "dog with long tusks and a coat of fine, silky hair";
    if (variables.chimera_engaged == "deer") power2Desc = "deer with the delicate branches of a rosebush sprouting from its head";
    if (variables.chimera_engaged == "lion") power2Desc = "flying creature with the body of a lion and the head of an eagle";
    let power2: IPower = {
      title: PowerTitle.COMPANION,
      imageUrl: this.getPowerImageUrl(PowerTitle.COMPANION),
      descriptions: [
        `You now have a companion for your journey through the In-Between: the ${power2Desc}.`,
      ],
      optionals: [
        `Write freely about the concept of companionship and/or connection. What do these words mean to you? Where in your life do you have these things? Where would you benefit from a deeper sense of connection or companionship? And what can these things bring you? `,
      ]
    };
    powersAndLevels.powers.push(power2);

    return powersAndLevels;
  }

  private getSixthDreamLevelAndPowers(variables: ISixthDreamVariables): IPowerLevel {
    let powersAndLevels: IPowerLevel = {
      level: {
        title: LevelTitle.HERO,
        imageUrl: this.getLevelImageUrl(LevelTitle.HERO),
        descriptions: [`
          In fighting the monster, even though you lost the battle, you have levelled up. The hero is not always the victor. In fact, being challenged, beaten and then learning from the experience plays a vital part in the hero's journey.`
        ],
      },
      powers: [],
    };

    if (variables.your_fear != "fear") {
      let power1: IPower = {
        title: PowerTitle.VULNERABILITY,
        imageUrl: this.getPowerImageUrl(PowerTitle.VULNERABILITY),
        descriptions: [
          `The ability to own and name your fears.`,
          `Although it may seem counterintuitive, emotional vulnerability lies at the core of emotional strength. Popular culture would have us believe that you must "overcome" or "master" your feelings while on the path to fortitude, but this is untrue; it is the courage to face, experience and express your emotions that makes you powerful.`,
          `"Vulnerability is not winning or losing; it's having the courage to show up and be seen when we have no control over the outcome. Vulnerability is not weakness; it's our greatest measure of courage."`,
          `- Brené Brown, Rising Strong`
        ],
        optionals: [
          `Consider a time from your life when you had to be brave. Explore the emotions you experienced during that event, describing the different feelings you were aware of and how you responded to them.`,
        ]
      };
      powersAndLevels.powers.push(power1);
    }

    return powersAndLevels;
  }

  private getSeventhDreamLevelAndPowers(variables: ISeventhDreamVariables): IPowerLevel {
    let powersAndLevels: IPowerLevel = {
      level: {
        title: LevelTitle.HERO,
        imageUrl: this.getLevelImageUrl(LevelTitle.HERO),
        descriptions: [
          `In fighting the monster, even though you lost the battle, you have levelled up. The hero is not always the victor. In fact, being challenged, beaten and then learning from the experience plays a vital part in the hero's journey.`
        ],
      },
      powers: [],
    };

    if (variables.no_pattern_D7 === 0) {
      let power1: IPower = {
        title: PowerTitle.PATTERN_DECRYPTION,
        imageUrl: this.getPowerImageUrl(PowerTitle.PATTERN_DECRYPTION),
        descriptions: [
          `The ability to take an honest view of your emotional and behavioural patterns.`,
          `This skill is key to the development of self-awareness and vital for anyone who wishes to change the way they interact with the world around them or, indeed, within.`,
        ],
        optionals: [
          `It's not only our ineffective patterns that we need to know about, of course. Explore a positive, healthy or desirable habit that you notice in your behaviour (this could be a pattern related to fitness, health, achievement, friendship, etc.). As you did in Dream VII, break the pattern down into these stages to write about it: `,
          `- The trigger (what cues the pattern?)`,
          `- The internal experience (how do you feel inside as this pattern begins?)`,
          `- The behaviour (describe the positive behaviour itself)`,
          `- The big-picture consequence (as this pattern repeats, what kind of effect does it have on your life?)`,
        ]
      };
      powersAndLevels.powers.push(power1);
    }

    let power2: IPower = {
      title: PowerTitle.HEALING,
      imageUrl: this.getPowerImageUrl(PowerTitle.HEALING),
      descriptions: [
        `The awareness you're building on this journey opens the door to healing of many kinds - behavioural, emotional, spiritual and possibly even physical (the mind must be in a state of psychological rest in order for the body's healing processes to be at their most effective).`,
      ],
      optionals: [
        `Consider what the term "healthy" means to you personally. What does health itself look like for you, physically and/or emotionally? Do you feel healthy in general? And what are the things that threaten the experience of healthiness for you?`,
      ]
    };
    powersAndLevels.powers.push(power2);

    return powersAndLevels;
  }

  private getEighthDreamLevelAndPowers(variables: IEighthDreamVariables): IPowerLevel {
    let powersAndLevels: IPowerLevel = {
      level: {
        title: LevelTitle.HERO,
        imageUrl: this.getLevelImageUrl(LevelTitle.HERO),
        descriptions: [
          `In fighting the monster, even though you lost the battle, you have levelled up. The hero is not always the victor. In fact, being challenged, beaten and then learning from the experience plays a vital part in the hero's journey.`
        ],
      },
      powers: [],
    };

    if (
      variables.third_moment1 != "" || variables.third_moment2 != "" || variables.third_moment3 != "" ||
      variables.second_moment1 != "" || variables.second_moment2 != "" || variables.second_moment3 != "" ||
      variables.first_moment1 != "" || variables.first_moment2 != "" || variables.first_moment3 != ""
    ) {
      let power1: IPower = {
        title: PowerTitle.THE_FIRE,
        imageUrl: this.getPowerImageUrl(PowerTitle.THE_FIRE),
        descriptions: [
          `The power to know the kinds of activities or experiences that engage, inspire or otherwise light a fire inside you.`,
          `It's natural to focus more on negative experiences than positive ones - the human nervous system is inherently protective and its very first priority is always to keep you safe. This means that your mind is simply less interested in the happy moments than it is in the frightening or uncomfortable ones.`,
          `However, this system does make it easy to fall prey to a pessimistic outlook on life. The counter to this problem is quite simple: pay conscious attention to the positive moments - savour them as you would a delicious food - and they will expand in your awareness, helping you to feel increasingly optimistic and content.`
        ],
        optionals: [
          `Think of a positive moment from any stage in your life. This could be a solo experience or something you did with others; it could be a big thing or just a fleeting instance of happiness, contentment or calm. Write about this experience as if you're back in that moment, experiencing it all over again.`,
        ]
      };
      powersAndLevels.powers.push(power1);
    }

    if (variables.player_unique_strength != "" || variables.chosen_strength != "" || variables.player_strength1 != "" || variables.player_strength2 != "" || variables.player_strength3) {
      let power2: IPower = {
        title: PowerTitle.FORTITUDE,
        imageUrl: this.getPowerImageUrl(PowerTitle.FORTITUDE),
        descriptions: [
          `The knowledge of your personal strengths, skills and virtues.`,
          `To know your strengths is another key aspect of self-awareness, and it is vital to finding a sense of purpose. In order to know how you, personally, can make a meaningful contribution to the world, you must know what it is, specifically, that you offer.`
        ],
        optionals: [
          `The question of finding purpose can seem like a daunting proposition, but most people are able to think of things that make them feel purposeful as they do them. These will be the pursuits that engage you, inspire you or feel meaningful in some other way. Perhaps, for example, you feel purposeful when you help or teach others? Or maybe you achieve a sense of purpose while you're being creative, philosophical or scientific? In your writing, explore one activity that helps you to feel purposeful. How does it do this, and why does this pursuit feel particularly meaningful to you?`,
        ]
      };
      powersAndLevels.powers.push(power2);
    }

    return powersAndLevels;
  }

  private getNinthDreamLevelAndPowers(variables: INinthDreamVariables): IPowerLevel {
    let powersAndLevels: IPowerLevel = {
      level: {
        title: variables.story_words !== "" || variables.story_words2 !== "" ? LevelTitle.AUTHOR : LevelTitle.HERO,
        imageUrl: variables.story_words !== "" || variables.story_words2 !== "" ? this.getLevelImageUrl(LevelTitle.AUTHOR) : this.getLevelImageUrl(LevelTitle.HERO),
        descriptions: variables.story_words !== "" || variables.story_words2 !== "" ? [
          `By owning a negative story (that is, a negative thought/cognition), you have levelled up to Author.`,
          `The human mind understands the world, other people and the self by way of story - it's how we find meaning, make choices and form our perspectives. Furthermore, as story comes so naturally to us, when a clear narrative is not immediately obvious – the meaning of what someone has said, perhaps, or the significance of our actions – we make up our own. This means that much of our understanding is based on confabulation. Rarely (if ever) do we see things as they truly are. Rather, we see them as how they fit with the stories we tell.`,
          `To see ourselves as the Authors - rather than mere characters - of our life stories, then, is to recognise the responsibility and power that we have over the way our lives unfold.`
        ] : [
          `In fighting the monster, even though you lost the battle, you have levelled up. The hero is not always the victor. In fact, being challenged, beaten and then learning from the experience plays a vital part in the hero's journey.`
        ],
      },
      powers: [],
    };

    let power1: IPower = {
      title: PowerTitle.SHADOW_TAMING,
      imageUrl: this.getPowerImageUrl(PowerTitle.SHADOW_TAMING),
      descriptions: [
        `The willingness to look upon a negative or frightening aspect of the self from a new perspective.`,
        `There is always more than one way to see something. Be it a problem or challenging situation, a personality trait or action taken by you or someone else, there will be myriad interpretations you could make, and therefore, multiple ways in which you could respond.`,
        `When you feel uncomfortable with something you have thought, felt, said or done, learning to take a step back and view the issue from a different angle is a powerful thing - it can help you to see that your assumptions (your favourite stories) are not necessarily true, and it can help you to identify new paths to take.`
      ],
      optionals: [
        `Think of something you sometimes do but later regret - something like speaking out of turn, getting angry or frustrated with people, procrastinating on your work, etc. As a thought experiment, imagine taking the kindest, most generous perspective on that trait. If you were to see this aspect of yourself in a positive light, how would you describe it?`,
      ]
    };
    powersAndLevels.powers.push(power1);

    return powersAndLevels;
  }

  private getTenthDreamLevelAndPowers(variables: ITenthDreamVariables): IPowerLevel {
    let powersAndLevels: IPowerLevel = {
      level: {
        title: LevelTitle.AUTHOR,
        imageUrl: this.getLevelImageUrl(LevelTitle.AUTHOR),
        descriptions: variables.story_words === "" && variables.story_words2 === "" ? [
          `By writing a more positive or empowering story for yourself, you have levelled up to Author.`,
          `The human mind understands the world, other people and the self by way of story - it's how we find meaning, make choices and form our perspectives. Furthermore, as story comes so naturally to us, when a clear narrative is not immediately obvious - the meaning of what someone has said, perhaps, or the significance of our actions – we make up our own. This means that an enormous percentage of our understanding is based on confabulation. Rarely (if ever) do we see things as they truly are. Rather, we see them as they fit with the stories we tell.`,
          `To see ourselves as the Authors - rather than mere characters - of our life stories, then, is to recognise the responsibility and power that we have over the way our lives unfold.`,
        ] : [
          `By owning a negative story (that is, a negative thought/cognition), you have levelled up to Author.`,
          `The human mind understands the world, other people and the self by way of story - it's how we find meaning, make choices and form our perspectives. Furthermore, as story comes so naturally to us, when a clear narrative is not immediately obvious – the meaning of what someone has said, perhaps, or the significance of our actions – we make up our own. This means that much of our understanding is based on confabulation. Rarely (if ever) do we see things as they truly are. Rather, we see them as how they fit with the stories we tell.`,
          `To see ourselves as the Authors - rather than mere characters - of our life stories, then, is to recognise the responsibility and power that we have over the way our lives unfold.`
        ],
      },
      powers: [],
    };

    if (variables.story_words != "" || variables.story_words2 != "") {
      let power1: IPower = {
        title: PowerTitle.SELF_SCULPTING,
        imageUrl: this.getPowerImageUrl(PowerTitle.SELF_SCULPTING),
        descriptions: [
          `The ability to reframe negative thoughts and construct positive or empowering stories for yourself.`,
          `By now, you know all about this - as the Author, you choose the story you tell, the meaning you make and the path you walk.`
        ],
        optionals: [
          `Scan back over the events of the past day, week or month to identify one negative thought you've had - even if it was only a passing notion. This could be a thought about yourself, about the future, about other people, etc.`,
          `Once you have it, answer this simple but powerful question:`,
          `What would have been a more helpful thought?`
        ]
      };
      powersAndLevels.powers.push(power1);
    }

    if (variables.no_mem === 0) {
      let power2: IPower = {
        title: PowerTitle.MEMORY_MOULDING,
        imageUrl: this.getPowerImageUrl(PowerTitle.MEMORY_MOULDING),
        descriptions: [
          `The power to retell the stories from your past by reframing the experience and/or reconnecting with your previous self from a place of compassion.`,
          `Contrary to how it may feel, memory is not an accurate or reliable record of the past. Rather, it's plastic - evolving and changing as you grow and learn. This means you always have the option to take a new perspective on events from your past, changing the meaning you take from them.`
        ],
        optionals: [
          `Imagine you can go back in time to have a conversation with a younger version of yourself. If it feels safe, go back as far as you can remember. If not, pick a more recent time. Once you've chosen a period, write out what you'd like to tell that younger version of yourself. What do they most need to know or hear from you? What kind of advice might you give this version of yourself? And, if they were to reply, what might you learn from them?`
        ]
      };
      powersAndLevels.powers.push(power2);
    }

    let power3: IPower = {
      title: PowerTitle.ANCHORING,
      imageUrl: this.getPowerImageUrl(PowerTitle.ANCHORING),
      descriptions: [
        `The ability to "anchor" positive emotions by assigning them a colour, sound or other sensory tag.`,
        `Anchoring (otherwise known as classical conditioning) is a naturally occurring phenomenon - with repetition, the mind can learn to think, feel or behave a certain way upon the experience of a particular cue. Think: Pavlov's dogs.`,
        `When anchors are created intentionally, they can help you to access desirable emotional states - such as confidence or calm - more quickly and easily. Say, for example, you chose to visualise the colour red whenever you won a game of chess. Over time, that colour could help you to enter a state of mind associated with success, which you could use to access your "winning mindset" in other situations, too, such as during job interviews, exams or sports competitions.`
      ],
      optionals: [
        `Think of an emotional state or mindset you'd like to experience more often. Common examples include confidence, assertiveness, calmness/peace or a sense of connection.`,
        `Now, answer this question:`,
        `If that positive feeling had a colour, what colour would it be?`,
        `Next, consider a moment from your life when you have felt this desired emotion and imagine bringing your chosen colour into the scene somehow - perhaps you'd imagine that you wore that colour in your clothing, that it showed up in the environment, or that this colour shone from inside your body a little like the strange light described in Dream X.`,
        `Finally, journal about this newly coloured version of the memory, detailing your emotional experience and reactions.`,
      ]
    };
    powersAndLevels.powers.push(power3);

    return powersAndLevels;
  }

  private getEleventhDreamLevelAndPowers(variables: IEleventhDreamVariables): IPowerLevel {
    let powersAndLevels: IPowerLevel = {
      level: {
        title: LevelTitle.ARCHITECT,
        imageUrl: this.getLevelImageUrl(LevelTitle.ARCHITECT),
        descriptions: [
          `By creating your very own part of the In-Between - the home you built for ${this.transformationService.decapitalizeAndSwapPronouns(variables.monster_name)} - you have levelled up to Architect.`,
          `Unlike the outside world, which is bound by the laws of physics, your imagination is illimitable, ${variables.playerName}. You can use it in any way you wish. This is only the beginning. `
        ]
      },
      powers: [],
    };

    let power2: IPower = {
      title: PowerTitle.REINCARNATION,
      imageUrl: this.getPowerImageUrl(PowerTitle.REINCARNATION),
      descriptions: [
        `Following on from the power of Shadow Taming (Dream IX), Reincarnation is the continued ability to see aspects of yourself in new and increasingly wonderful ways.`,
        `Although we're all influenced by societal norms and expectations, your self-image can only ever be your responsibility. Taking control is an ongoing practice, but it's worth the effort - when you choose to see the good in yourself, the mirror will show you the same.`
      ],
      optionals: [
        `Think of a mistake you once made - this could be a small thing or a great regret. Knowing that the intention here is not to simply excuse your errors, but to make sure that you learn what you can from any slip, consider this question:`,
        `What can I make out of this?`,
        `If there's no silver lining to this mistake in a practical sense, then consider the role regret plays in informing future behaviour and decisions. What can you learn from this that will make you a better person?`
      ]
    };
    powersAndLevels.powers.push(power2);

    if (variables.has_playerGoodPlace === 1 || variables.notThere) {
      let power3: IPower = {
        title: PowerTitle.CHANGEMAKING,
        imageUrl: this.getPowerImageUrl(PowerTitle.CHANGEMAKING),
        descriptions: [
          `The ability to set goals and measure your progress as you work towards them.`,
          `While being overly focused on your results (outcome orientation) can prevent you from enjoying the journey, the ability to plan remains a vital part of personal growth. As this journey comes to an end, we encourage you to continue setting goals for yourself that incorporate desired thoughts, feelings and perspectives.`
        ],
        optionals: [
          `Focusing on a particular context of your life - for example your work, family, health, fitness, social life, etc. - take a moment to think forward to around six months in the future and consider this question:`,
          `How do you want to feel come this time?`,
          `In your journaling, name the specific emotions you'd like to be experiencing more of within your chosen context, and consider the thoughts, behaviours, values and goals connected with this desired experience. Use this information to paint a vivid picture of your desired future, bringing it to life with detailed descriptions of the most important factors.`
        ]
      };
      powersAndLevels.powers.push(power3);
    }
    return powersAndLevels;
  }

  getLevelImageUrl(levelName: LevelTitle): string {
    switch (levelName) {
      case LevelTitle.SEEKER:
        return "seeker";
      case LevelTitle.SEER:
        return "seer";
      case LevelTitle.HERO:
        return "hero";
      case LevelTitle.AUTHOR:
        return "author";
      case LevelTitle.ARCHITECT:
        return "architect";
      default:
        console.error('Cannot find the url of level image!');
        return '';
    }
  }

  getPowerImageUrl(powerName: PowerTitle): string {
    switch (powerName) {
      case PowerTitle.COMPASS:
        return "compass";
      case PowerTitle.SCALES:
        return "scales";
      case PowerTitle.ALCHEMY:
        return "alchemy";
      case PowerTitle.STORY_SEEKER:
        return "story_seeker";
      case PowerTitle.DREAMER:
        return "dreamer";
      case PowerTitle.HAVEN:
        return "haven";
      case PowerTitle.SELF_DISTANCING:
        return "self-distancing";
      case PowerTitle.PRESENCE:
        return "presence";
      case PowerTitle.SIGHT:
        return "sight";
      case PowerTitle.VULNERABILITY:
        return "vulnerability";
      case PowerTitle.PATTERN_DECRYPTION:
        return "pattern_decryption";
      case PowerTitle.HEALING:
        return "healing";
      case PowerTitle.THE_FIRE:
        return "the_fire";
      case PowerTitle.FORTITUDE:
        return "fortitude";
      case PowerTitle.SELF_SCULPTING:
        return "self-sculpting";
      case PowerTitle.MEMORY_MOULDING:
        return "memory_moulding";
      case PowerTitle.ANCHORING:
        return "anchoring";
      case PowerTitle.REINCARNATION:
        return "reincarnation";
      case PowerTitle.CHANGEMAKING:
        return "changemaking";
      case PowerTitle.COMPANION:
        return "companion";
      case PowerTitle.SHADOW_TAMING:
        return "shadow_taming";
      default:
        console.error('Cannot find the url of power image!');
        return '';
    }
  }
}

export interface IMemory {
  title: string;
  descriptions: string[];
}

export interface IPowerLevel {
  level: ILevel,
  powers: IPower[];
}

export interface IPower {
  title: string;
  imageUrl: string;
  descriptions: string[];
  optionals: string[];
}

interface ILevel {
  title: string;
  imageUrl: string;
  descriptions: string[];
}

enum PowerTitle {
  COMPASS = "Compass",
  SCALES = "Scales",
  ALCHEMY = "Alchemy",
  STORY_SEEKER = "Story Seeker",
  DREAMER = "Dreamer",
  HAVEN = "Haven",
  SELF_DISTANCING = "Self-distancing",
  PRESENCE = "Presence",
  SIGHT = "Sight",
  VULNERABILITY = "Vulnerability",
  PATTERN_DECRYPTION = "Pattern Decryption",
  HEALING = "Healing",
  THE_FIRE = "The Fire",
  FORTITUDE = "Fortitude",
  SELF_SCULPTING = "Self-sculpting",
  MEMORY_MOULDING = "Memory Moulding",
  ANCHORING = "Anchoring",
  REINCARNATION = "Reincarnation",
  CHANGEMAKING = "Changemaking",
  COMPANION = "Companion",
  SHADOW_TAMING = "Shadow Taming"
}

enum LevelTitle {
  SEEKER = "Seeker",
  SEER = "Seer",
  HERO = "Hero",
  AUTHOR = "Author",
  ARCHITECT = "Architect",
}

interface IFirstDreamVariables {
  D1_glimpsed_thing: string;
  TinyAction: string;
  artColour: string;
  artGoodPlace: string;
  betwixtEmotion: string;
  close_gargoyle: number;
  close_sky: number;
  colour: string;
  fearless: number;
  gameOverD1: number;
  gargoyle: number;
  numb: number;
  playerBetterPlace: string;
  playerBetterPlace3: string;
  playerBetwixt: string;
  playerExcellentPlace: string;
  playerGoodPlace: string;
  playerGoodPlace2: string;
  playerGoodPlaceExtra: string;
  quest_input: number;
  see: number;
  standing: number;
  stubborn: number;
  who: number;
}

interface ISecondDreamVariables {
  D1_glimpsed_thing: string;
  D2Art1: number;
  D2Art2: number;
  D2Art3: number;
  VARIABLES_SEPARATOR: number;
  artHasLove: number;
  awkward: number;
  dontKnowValid: number;
  dream: number;
  e: string;
  eir: string;
  eirs: string;
  em: string;
  emself: string;
  exhausted: number;
  gameOverD2: number;
  gargoyle_option: number;
  hasLoveD2: number;
  like_list: number;
  myth: number;
  mythMeaning: string;
  myth_passage: number;
  narratorVoiceFeeling: string;
  narrator_name: number;
  pause: number;
  playerDreamShare: string;
  playerDreams: string;
  playerGoodPlace: string;
  playerLove: string;
  playerLove2: string;
  playerLove3: string;
  playerName: string;
  playerThing: string;
  playerValueAction: string;
  playerValueD2: string;
  player_feel_like: string;
  plural: number;
  quest_input: number;
  silence: number;
  stillness: number;
  train: number;
  trust: number;
  two_pauses: number;
  wish_no_name_boo: number;
}

interface IThirdDreamVariables {
  D3Env: number;
  D3Need: number;
  D3Purpose: number;
  VARIABLES_SEPARATOR: number;
  artClan: string;
  card_number: number;
  clan: string;
  counter: string;
  counter_action: string;
  counter_improvement: string;
  counter_phrase: string;
  dontKnowValid: number;
  drive: string;
  laterSpot: number;
  manage: number;
  mixedD3: number;
  no: number;
  personaing: string;
  playerName: string;
  playerPower: string;
  playerSafePlace: string;
  playerSafePlaceFeeling: string;
  playerSafePlaceMeaning: string;
  playerSafePlaceMeaningFit: string;
  playerSafePlaceMeaningNeed: string;
  playerSafePlaceMeaningPurpose: string;
  playerWeakness: string;
  playerWeakness2: string;
  powersQ: number;
  see: number;
  seen_all: number;
  seen_one: number;
  counter_player?: string;
}

interface IFourthDreamVariables {
  D1_glimpsed_thing: string;
  D4_alt: number;
  D4custom: 0;
  VARIABLES_SEPARATOR: number;
  artD4_alt: number;
  artEmotion: string;
  chosenAgain: number;
  clan: string;
  counter_phrase: string;
  dread: number;
  e: string;
  eir: string;
  eirs: string;
  em: string;
  emotion: string;
  emotion_desc: string;
  emself: string;
  familiarD4: number;
  noNegD4: number;
  no_wave: number;
  own_emoD4: number;
  player1stPersonEmot: string;
  playerBasicDetails: string;
  playerDifference: string;
  playerEmotionAnalysis: string;
  playerEmotionD4: string;
  playerLesson2: string;
  playerMessage: string;
  playerName: string;
  playerSceneDescription: string;
  plural: number;
  present: number;
  sense_emotion: string;
  skipped_all_D4: number;
  wonder: number;
}

interface IFifthDreamVariables {
  D5_alt: number;
  NOfree_person_a: number;
  NOloving_person_a: number;
  NOsmart_person_a: number;
  VARIABLES_SEPARATOR: number;
  artClose_eyes: number;
  call3: number;
  cat: number;
  chimera_engaged: string;
  close_eyes: number;
  close_gargoyle: number;
  close_sky: number;
  creative_person: string;
  creative_person_a: string;
  dangerous: number;
  dontKnowValid: number;
  dontknow: number;
  dontknowkind: number;
  e: string;
  free_part_a: string;
  good_thing: string;
  good_thing_choice1: string;
  good_thing_choice2: string;
  good_thing_self: string;
  kind_part_a: string;
  loving_person: string;
  loving_person_a: string;
  motiveUnsure: number;
  opposite_effect_example: string;
  opposite_effect_result: string;
  person_alternative: number;
  playerGoodPlace: string;
  playerName: string;
  plural: number;
  reg: number;
  smart_part_a: string;
  smart_person: string;
  smart_person_a: string;
  something_else: number;
  train: number;
  what: number;
}

interface ISixthDreamVariables {
  D6_fight: number;
  D6_flee: number;
  D6_freeze: number;
  VARIABLES_SEPARATOR: number;
  afraid_of_fear: string;
  artD6_fight: number;
  artD6_flee: number;
  artD6_freeze: number;
  chimera_engaged: string;
  clan: string;
  fear_behD6: string;
  fear_feelingD6: string;
  fear_otherD6: string;
  fear_situsD6: string;
  fear_thoughtsD6: string;
  feared_thingD6: string;
  initial_reaction: string;
  mind: number;
  new_feeling: number;
  opposite_effect_example: string;
  other_emotion: number;
  past_fear: number;
  playerName: string;
  sense_emotion: string;
  your_fear: string;
}

interface ISeventhDreamVariables {
  VARIABLES_SEPARATOR: number;
  artFawner: number;
  artFighter: number;
  artFleer: number;
  artFreezer: number;
  counter_phrase: string;
  dontKnowValid: number;
  equaller: number;
  fawn_better_beh: string;
  fawn_feeling: string;
  fawn_player_window: string;
  fawn_player_window_2: string;
  fawn_reminder: string;
  fawn_self_talk2: string;
  fawn_trigger: string;
  fawn_trigger1st: string;
  fawned_expectation: string;
  fawned_issue: string;
  fawned_thing: string;
  fawner: number;
  fear_reaction: string;
  fight_better_beh: string;
  fight_feeling: string;
  fight_player_window: string;
  fight_player_window_2: string;
  fight_reminder: string;
  fight_self_talk2: string;
  fight_topic: string;
  fight_trigger: string;
  fighter: number;
  flee_better_beh: string;
  flee_feeling: string;
  flee_player_window: string;
  flee_player_window2: string;
  flee_reminder: string;
  flee_self_talk2: string;
  flee_thing: string;
  flee_trigger: string;
  fleer: number;
  fought_thing: string;
  freeze_better_beh: string;
  freeze_feeling: string;
  freeze_player_window: string;
  freeze_player_window_2: string;
  freeze_reminder: string;
  freeze_self_talk2: string;
  freeze_thing: string;
  freeze_trigger: string;
  freeze_way: string;
  freezer: number;
  initial_reaction: string;
  new_description: string;
  no_pattern_D7: number;
  person: number;
  playerName: string;
  player_fawn: string;
  player_fawn_more: string;
  player_fight: string;
  player_fight_more: string;
  player_flee: string;
  player_flee_more: string;
  player_freeze: string;
  player_freeze_more: string;
  question: number;
  sit: number;
  skippedD7: number;
  walk: number;
  when_pattern: string;
  window: string;
}

interface IEighthDreamVariables {
  VARIABLES_SEPARATOR: number;
  age1: string;
  age2: string;
  artSkipped_mainD8: number;
  cantSeeD8: number;
  chimera_engaged: string;
  chosen_strength: string;
  confront: number;
  dontKnowValid: number;
  first_moment1: string;
  first_moment2: string;
  first_moment3: string;
  knownPerson: number;
  known_person_name: string;
  loving_person: string;
  no_reminders: number;
  objObserver: number;
  playerGoodPlace: string;
  playerName: string;
  player_strength1: string;
  player_strength2: string;
  player_strength3: string;
  player_unique_strength: string;
  reminder_after_examples: number;
  second: number;
  second_moment1: string;
  second_moment2: string;
  second_moment3: string;
  see_skills: number;
  skipped_childhood: number;
  skipped_mainD8: number;
  skipped_second: number;
  something_else: number;
  strength1: string;
  strength2: string;
  strengths_list: string;
  then: number;
  third_moment1: string;
  third_moment2: string;
  third_moment3: string;
  use_person: number;
  value: string;
  value_reminder: number;
  way: number;
}

interface INinthDreamVariables {
  D4custom: number;
  D5_alt: number;
  VARIABLES_SEPARATOR: number;
  YS_message: string;
  YS_message2: string;
  YS_messagePlayer: string;
  YS_message_own: string;
  YS_message_own_2nd: string;
  alt_process: number;
  artMonster_comfort: string;
  clan: string;
  colour: string;
  comEx: number;
  comfort: number;
  confidence: number;
  custom_theme: number;
  d3Env: number;
  d3Need: number;
  d3Purpose: number;
  deserving: number;
  dontKnowValid: number;
  doubtStory: number;
  drive: string;
  e: string;
  eir: string;
  eirs: string;
  em: string;
  emotion: string;
  emself: string;
  endingGroup: number;
  enough: number;
  fawn_self_talk2: string;
  fawn_trigger: string;
  fawned_thing: string;
  fearNotFact: string;
  fearStory: number;
  fear_reaction: string;
  feelingStory: number;
  fight_self_talk2: string;
  fight_trigger: string;
  first_moment1: string;
  fit: number;
  flee_self_talk2: string;
  flee_thing: string;
  flee_trigger: string;
  fought_thing: string;
  freeze_self_talk2: string;
  freeze_thing: string;
  freeze_trigger: string;
  good_thing: string;
  judgeStory: number;
  known: number;
  lightness: number;
  like_list: number;
  listen: number;
  lovable: number;
  love: number;
  matter: number;
  mind: number;
  monster_comfort: string;
  monster_comfortLong: string;
  monster_name: string;
  monster_story: string;
  monster_type: string;
  monster_type2: string;
  named_after_story: number;
  no_theme: number;
  no_wave: number;
  no_words: number;
  not_sure: number;
  not_sure2: number;
  opposite_effect_example: string;
  opposite_effect_result: string;
  other_emotion: number;
  pick: number;
  playerBetterPlace: string;
  playerGoodPlace: string;
  playerLove: string;
  playerLove2: string;
  playerLove3: string;
  playerMessage: string;
  playerMonster_comfort: string;
  playerName: string;
  playerSafePlaceMeaningFit: string;
  playerSafePlaceMeaningNeed: string;
  playerSafePlaceMeaningPurpose: string;
  playerSceneDescription: string;
  playerThing: string;
  playerValueD2: string;
  playerWeakness: string;
  playerWeakness2: string;
  player_fears: string;
  player_unique_strength: string;
  playfulness: number;
  plural: number;
  pride: number;
  reflect: number;
  second_moment1: string;
  skippedD7: number;
  skipped_mainD8: number;
  skipped_second: number;
  smart: number;
  smell: number;
  stand_up: number;
  story_words: string;
  story_words2: string;
  story_work_done: number;
  theme: string;
  theme2: string;
  theme_counter: string;
  third_moment1: string;
  third_moment2: string;
  third_moment3: string;
  uncertain: number;
  wary: number;
  weakness: number;
  worth: number;
  your_fear: string;
}

interface ITenthDreamVariables {
  AS_message2: string;
  VARIABLES_SEPARATOR: number;
  YS_feeling: number;
  YS_message: string;
  YS_message2: string;
  YS_messagePlayer: string;
  YS_message_own: string;
  YS_message_own_2nd: string;
  YS_own_connection: number;
  YS_situ: number;
  YS_story: number;
  YS_story_feeling: number;
  YSe: string;
  YSeir: string;
  YSeirs: string;
  YSem: string;
  YSemself: string;
  YSplural: number;
  adult_mem: number;
  affect_bridge: string;
  affect_bridge_loc: string;
  affect_bridge_mov: string;
  affect_bridge_sense: string;
  affect_bridge_weight: string;
  after_pause: number;
  agrees_mem: number;
  alt_process: number;
  animal_other: number;
  artBabyB: number;
  artGold: number;
  artGreen: number;
  artMulti: number;
  artOcean: number;
  artOwn_col: number;
  artPink: number;
  artPurple: number;
  artRed: number;
  artWhite: number;
  babyB: number;
  career: number;
  certain_period: number;
  changeShape_choice: string;
  comfort: number;
  confidence: number;
  constellation: string;
  content_free: number;
  creative: number;
  customAffect: number;
  custom_emo: number;
  custom_theme: number;
  deserving: number;
  diverters: number;
  dontKnowValid: number;
  e: string;
  eir: string;
  eirs: string;
  em: string;
  emoShape: string;
  emo_loc: string;
  emself: string;
  enough: number;
  finding: number;
  fit: number;
  flower: number;
  futureVision: string;
  gem: number;
  gist: number;
  gold: number;
  green: number;
  hasPosEmo: number;
  hasPosEmo2: number;
  has_feeling: number;
  healthy: number;
  heavy: number;
  important_action: string;
  important_convo: string;
  important_other: string;
  important_place: string;
  lightness: number;
  lovable: number;
  love: number;
  love_all: number;
  love_others: number;
  love_self: number;
  love_world: number;
  matter: number;
  memory_age: string;
  monster_comfort: string;
  monster_comfortLong: string;
  monster_comfort_correct: number;
  monster_name: string;
  monster_type: string;
  monster_type2: string;
  multi: number;
  multiple_other: number;
  newEmo_D9alt: number;
  newTime: string;
  no_childhood: number;
  no_mem: number;
  no_theme: number;
  ocean: number;
  own_benefit: number;
  own_col: number;
  own_wordsD9alt: number;
  period: number;
  picked_same: number;
  pink: number;
  playerAffect_bridge_loc: string;
  playerAssociation: string;
  playerChangeEmo: string;
  playerColour: string;
  playerColour2: string;
  playerColour3: string;
  playerColour_D9alt: string;
  playerColour_own: string;
  playerConvo: string;
  playerEmoShape: string;
  playerEmo_description: string;
  playerEmo_trigger: string;
  playerExpands: string;
  playerGoodPlace: string;
  playerKnows: string;
  playerMemory1: string;
  playerMessage_own: string;
  playerMonster_comfort: string;
  playerName: string;
  playerNewStory: string;
  playerNewStory2: string;
  playerNewStory_own: string;
  playerObject: string;
  playerPosEmo_alt: string;
  playerPosEmo_main: string;
  playerSelf_acceptance: string;
  playerSelf_description: string;
  playerSelf_description2: string;
  playerSelf_descriptionPos1: string;
  playfulness: number;
  plural: number;
  posEmoD11: string;
  posEmo_D9alt: string;
  posEmo_adj: string;
  posEmo_alt: string;
  posEmo_alt2: string;
  posEmo_main: string;
  posEmo_main2: string;
  pride: number;
  purple: number;
  red: number;
  relate: number;
  resilience: number;
  safe_memory: number;
  self_acceptance: string;
  selfaccept: number;
  shapeAgain: number;
  smart: number;
  step_back: number;
  story_words: string;
  story_words2: string;
  switched_pronouns: number;
  theme: string;
  theme2: string;
  theme_counter: string;
  weakness: number;
  white: number;
  words_only: number;
  worth: number;
  written_message: number;
  younger: number;
}

interface IEleventhDreamVariables {
  D1_glimpsed_thing: string;
  PSpecific: string;
  VARIABLES_SEPARATOR: number;
  YSe: string;
  YSeir: string;
  YSeirs: string;
  YSem: string;
  YSemself: string;
  YSplural: number;
  animalMonster: number;
  artQ: string;
  babyB: number;
  birdsongSP_sound: number;
  birdsong_streetSP_sound: number;
  careful: number;
  childSelfDescription: string;
  childSelfMonster: number;
  chimeraAnimal: string;
  chimeraBody: string;
  chimeraExtra: string;
  chimeraHead: string;
  chimeraMonster: number;
  citySP: number;
  clan: string;
  connection_reason: number;
  courage_reason: number;
  curious_reason: number;
  dontKnowValid: number;
  dragon: number;
  dragonBody: number;
  dragonHead: number;
  e: string;
  eir: string;
  em: string;
  emo_reason: number;
  emself: string;
  fairy: number;
  fireSP_sound: number;
  follow: number;
  forest: number;
  forestBirdsongSP_sound: number;
  fox: number;
  foxHead: number;
  gold: number;
  green: number;
  hasLoveD2: number;
  has_body: number;
  has_playerGoodPlace: number;
  has_signs: number;
  hill: number;
  imaginarySP: number;
  important_other: string;
  indoorsSP: number;
  keep_self: number;
  knownSP: number;
  leopard: number;
  magicalSP: number;
  monComSame: number;
  monster_comfort: string;
  monster_name: string;
  multi: number;
  naturalSP: number;
  no_connection: number;
  notThere: number;
  ocean: number;
  otherworldly: number;
  outdoorsSP: number;
  owlHead: number;
  ownHead: number;
  ownMonsterChange: number;
  own_col: number;
  own_emo: number;
  own_soundSP: number;
  pantherHead: number;
  peopleSP_sound: number;
  pgp: number;
  pgpPlus: number;
  pink: number;
  pixieHead: number;
  playerAnimalReason: string;
  playerBetterPlace: string;
  playerBodyReason: string;
  playerColour: string;
  playerColour_D9alt: string;
  playerColour_own: string;
  playerConnectionDes: string;
  playerEvidenceOwn: string;
  playerExcellentPlace: string;
  playerGoodPlace: string;
  playerGoodPlaceD11: string;
  playerHeadReason: string;
  playerLove: string;
  playerLove2: string;
  playerLove3: string;
  playerName: string;
  playerNewStory: string;
  playerNewStory2: string;
  playerPosEmo_alt: string;
  playerPosEmo_main: string;
  playerSafePlace: string;
  playerSpeaking: number;
  playerSpecialEmo: string;
  playerSpecialPlaceAuditory: string;
  playerSpecialPlaceFeeling: string;
  playerSpecialPlaceShortDescription: string;
  playerSpecialPlaceVDescription: string;
  playerSpecialPlaceVThing: string;
  playerSpecialPlaceVThing2: string;
  playerSpecialPlaceVThing3: string;
  playerThing: string;
  player_unique_strength: string;
  plural: number;
  populatedSP: number;
  posEmoD11: string;
  posEmo_D9alt: string;
  posEmo_alt: string;
  posEmo_main: string;
  posEmo_main2: string;
  preset: number;
  purple: number;
  quest_input: number;
  rabbit: number;
  red: number;
  relax_reason: number;
  rename_mon: number;
  repeatSP: number;
  repeat_monPlace: number;
  reset: number;
  ruralSP: number;
  shore: number;
  slightly: number;
  soloSP: number;
  solo_and_populatedSP: number;
  streetSP_sound: number;
  thunderSP_sound: number;
  waterSP_sound: number;
  wavesSP_sound: number;
  white: number;
  windSP_sound: number;
  wolfHead: number;
  younger: number;
  yourMonster: string;
}
