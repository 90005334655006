<ion-app>
    <ion-header class="ion-no-border">
    </ion-header>
    <ion-content scrollY="true">
        <div class="cmn-wrapper">
            <div class="cmn-font-helvetica ion-text-uppercase">
                <ion-icon class="cmn-icon-helvetica" (click)="back()" name="chevron-back-outline">
                </ion-icon>
            </div>

            <div class="cmn-serif-title ion-text-center">
                Privacy Policy
            </div>
            <div class="disclaimer-text">
                <p class="ion-text-center">last updated 26 December 2022</p>
                <p>This Privacy Policy explains how Mind Monsters Games Ltd ("Betwixt","we", "us", "our",) collects,
                    uses, and discloses information about you when you access or use our website, mobile application,
                    and other online products and services (collectively, the "Services"), and when you otherwise
                    interact with us.
                    &nbsp;</p>
                <p>We may change this Privacy Policy from time to time. If we make changes, we will notify you by
                    revising the date at the top of the policy and, in some cases, we may provide you with additional
                    notice (such as sending you a notification).
                </p>
                <p><strong>Collection of Information</strong></p>
                <p>We collect the following kinds of data when you use the App and our Services:</p>
                <ul>
                    <li>
                        <p><strong>Your contact data</strong> such as your email address;</p>
                    </li>
                    <li>
                        <p><strong>Your usage and log data</strong> such as what screens or features you access, the
                            type of web browser you use, app version, access times and dates, pages viewed, your IP
                            address.
                            <br>
                            This data is anonymised and then processed by Google Analytics and doesn’t personally
                            identify you.
                        </p>
                    </li>
                    <li>
                        <p><strong>The native actions</strong> you take within the App, such as picking from a number of
                            available options and story paths.
                            <br>
                            Your choices are processed by a rule-based engine in order to direct you to the relevant
                            branch of the story. No one is monitoring or responding to your messages. </p>
                    </li>
                    <li>
                        <p><strong>The content you produce through</strong> the App.
                            <br>
                            The only reason we collect your responses is so we can store them for you and ensure you
                            don’t lose access to your insights if you switch phones.
                            <br>
                            We don’t want to know your thoughts. We have no incentive to, either. Our model isn’t based
                            on selling data or advertising or marketing additional services to you. All we care about is
                            creating a great product that makes people’s lives a little better.

                        </p>
                    </li>
                    <li>
                        <p><strong>The information you provide to us</strong> when you fill out a form or a survey, make
                            a purchase, talk to us via social media sites, request customer support, or otherwise
                            communicate with us.</p>
                    </li>
                </ul>

                <p><strong>Use of Information</strong></p>
                <p>We use the information we collect to:</p>
                <ul>
                    <li>
                        <p>Create and manage your account in order for you to access and use the Services;</p>
                    </li>
                    <li>
                        <p>Personalize and enhance your user experience by making the story reflect your past choices;
                        </p>
                    </li>
                    <li>
                        <p>Monitor and analyze trends, usage, and activities in connection with our Services;</p>
                    </li>
                    <li>
                        <p>Respond to law enforcement requests and comply with the law, in particular, for the purpose
                            of detecting, investigating, and prosecuting illegal acts.</p>
                    </li>
                    <li>
                        <p>Detect, investigate and prevent fraudulent transactions and other illegal activities and
                            protect our rights and property and those of others, including to enforce our agreements and
                            policies.
                        </p>
                    </li>
                    <li>
                        <p>We may also de-identify and/or aggregate and/or anonymize your data for purposes of product
                            development and improvement. De-identified data, in individual or aggregated form, may also
                            be used for research purposes both internally or with research partners for the advancement
                            of clinical and scientific knowledge.
                        </p>
                    </li>
                </ul>
                <p><strong>Cookies and similar technologies</strong></p>
                <p>When you use the Services, We may use cookies and other IT functions to collect your Data. Cookies
                    are small text files that are automatically created by your browser and stored on your device when
                    you connect to the Website or the Application and use the Services.</p>
                <p>You can disable all or certain cookies in your browser by configuring your settings. </p>
                <p><strong>Website analytics</strong></p>
                <p>We use selected third-party vendors, such as Google Analytics, to allow tracking technologies on the
                    App so we can better understand online activity and improve our product. By accessing the App or our
                    Site, you consent to the collection and use of your information by these third-party vendors. You
                    are encouraged to review their privacy policy and contact them directly for responses to your
                    questions. We do not transfer personal information to these third-party vendors.
                </p>
                <p><strong>Security of your data</strong></p>
                <p>We seek to safeguard the security of your Data and have implemented security measures consistent with
                    accepted practices in the industry to protect your data and limit access to it.
                </p>
                <p>In particular, we have implemented appropriate technical and organizational measures to minimize
                    risks associated with data loss, misuse, unauthorized access, and unauthorized disclosure through
                    the following security standards:</p>
                <ul>
                    <li>Encryption of data in transit</li>
                    <li>Encryption of stored data</li>
                    <li>Automatic security updates</li>
                    <li>Use of strong passwords
                    </li>
                </ul>

                <p>
                    It’s important to bear in mind that there is always a risk that an unauthorized third party may find
                    a way around our security systems or that transmissions of your Data over the Internet will be
                    intercepted. Unfortunately, we cannot guarantee the absolute security of your data, nor can we
                    guarantee that the Data that you provide will not be intercepted while being transmitted to Us over
                    the Internet. Therefore, we urge you to also take every precaution to protect your Data when you are
                    on the Internet or using the Application.

                </p>
                <p>
                    In some cases, we may use third party providers for development and support purposes. In supplying
                    their services, these providers may have limited access to our databases. They will be subject to
                    contractual restrictions prohibiting them from using users’ personal data for any purpose other than
                    the legitimate uses outlined here.
                </p>

                <p><strong>Storage of personal data</strong></p>
                <p>We will keep your personal data for as long as it is necessary to fulfill the purposes for which it
                    was collected as described above and in accordance with our legal and regulatory obligations.
                </p>
                <p>If you would like further information about our data retention practices you can ask for this at any
                    time (see “Contact Us” section below).</p>
                <p><strong>How we store your data</strong></p>
                <p>We process your information and store it on servers located in data centers in the EEA. Our server
                    environment is highly secure and any information is encrypted with industry standard techniques.
                </p>
                <p>Unfortunately, the transmission of information via the internet is not completely secure. Although We
                    will do our best to protect your personal data, We cannot guarantee the security of your data
                    transmitted to our Site(s); any transmission is at your own risk. Once We have received your
                    information, We will use strict procedures and security features to try to prevent unauthorised
                    access.
                </p>
                <p><strong>Legal basis of personal data processing</strong></p>
                <p>In accordance with GDPR, the lawful basis we use for collecting and processing your information are
                    as follows:</p>
                <ul>
                    <li>
                        <p>Where it is necessary for entering into or performing a contract with you;</p>
                    </li>
                    <li>
                        <p>Where we have a legitimate interest to do so, provided your rights do not override those
                            interests;</p>
                    </li>
                    <li>
                        <p>Where you have consented to its uses;</p>
                    </li>
                    <li>
                        <p>Where our colleagues believe it is in your vital interests to share your personal details;
                        </p>
                    </li>
                    <li>
                        <p>Where required to comply with our legal obligations.</p>
                    </li>
                </ul>
                <p><strong>Your rights</strong></p>
                <p>Subject to certain exemptions, and in some cases dependent upon the processing activity we are
                    undertaking, you have certain rights in relation to your personal information.</p>
                <ul>
                    <li>
                        <p>Right to information: You can request a copy of the personal information we have collected
                            about you.</p>
                    </li>
                    <li>
                        <p>Right to rectification: We want to ensure that your information is up to date and correct.
                            You can request that your information be corrected or removed if you consider it incorrect.
                        </p>
                    </li>
                    <li>
                        <p>Right to be forgotten: You can request us to delete your personal information. We may not
                            delete data that the law requires us to keep.
                        </p>
                    </li>
                    <li>
                        <p>Data portability: You can request that we transfer your personal data from our IT
                            environment, either to another company or to you. This does not apply to information that
                            the law requires us to keep.
                        </p>
                    </li>
                    <li>
                        <p>Withdrawal of consent: You can withdraw your consent to share your information or to receive
                            marketing / emails at any time. Either by unsubscribing from the mailing list or by
                            contacting us through email.
                        </p>
                    </li>
                    <li>
                        <p>Complaint: You can file a complaint with the Data Protection Authorities if you believe that
                            we are treating your personal data in violation with GDPR.</p>
                    </li>
                </ul>
                <p>You can exercise your rights by contacting us by email at <a
                        href="mailto:hazel@mindmonsters.co.uk">hazel@mindmonsters.co.uk</a>. Subject to legal and other
                    permissible considerations, we will make every reasonable effort to honour your request promptly or
                    inform you if we require further information in order to fulfil your request.
                </p>
                <p><strong>Children&rsquo;s information</strong></p>
                <p>The Services are not directed to children under 13 (or other age as required by local law), and we do
                    not knowingly collect personal data from children. If you learn that your child has provided us with
                    personal data without your consent, you may contact us as set forth below. If we learn that we have
                    collected any personal data in violation of applicable law, we will promptly take steps to delete
                    such personal data and terminate the child’s account.
                </p>
                <p><strong>Contact</strong></p>
                <p>Please send any questions, comments or requests regarding this privacy policy to our team at
                    <a href="mailto:hazel@mindmonsters.co.uk">hazel@mindmonsters.co.uk</a>. You can also write to us at
                    the following address – Mind Monsters Games Ltd,12 Whitmore House, 140 Nuttall Street, London N1
                    5LJ.
                </p>
                <p>If you feel that we have not addressed your questions or concerns adequately, or you believe that
                    your data protection or privacy rights have been infringed, you can complain to any supervisory
                    authority or other public body with responsibility for enforcing privacy laws. In the United Kingdom
                    this is the Information Commissioner’s Office, you can see their contact details via the ICO
                    website.</p>
                <p><br /></p>
            </div>
            <br><br>
            <div #continue (click)="back()" class="ion-text-center ion-text-uppercase cmn-font-helvetica cmn-enabled">
                Continue</div>
            <br><br>
        </div>
    </ion-content>
</ion-app>