import { Component, OnInit } from '@angular/core';

import { Plugins } from '@capacitor/core';
import { NavController } from '@ionic/angular';
import { fadeAnimation } from 'src/helpers/transitions-helper';
const { Storage } = Plugins;

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.css']
})
export class DisclaimerComponent implements OnInit {

  constructor(private navCtrl: NavController) { }

  ngOnInit(): void {
    this.checkUserIsLoggedIn();
  }

  acceptAndContinue() {
    Storage.set({ key: 'onboarded', value: "true " }).then(_ => {
      this.navCtrl.navigateForward(["signupoptions"], { animation: fadeAnimation });
    });
  }

  async checkUserIsLoggedIn(): Promise<void> {
    await Storage.get({ key: 'userid' }).then(useridres => {
      if (useridres.value != null)
      this.navCtrl.navigateRoot("dream-selector");
    });
  }
}
