import { IPower } from './../../services/content.service';
import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { IPowerLevel } from 'src/services/content.service';

@Component({
  selector: 'app-read-more-detail',
  templateUrl: './read-more-detail.component.html',
  styleUrls: ['./read-more-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReadMoreDetailComponent implements OnInit {
  @Input() showByType: 'level' | IPower = 'level';
  @Input() powerLevelObject: IPowerLevel;
  @Input() isShow: boolean;

  constructor() { }

  ngOnInit(): void {}

  share() {
    if (this.showByType !== 'level') {
      this.sharePowerAndLevel('main-image', {
        imageName: `${this.showByType.imageUrl}.svg`,
        description: this.showByType.descriptions.join() + "\n" + this.showByType.optionals.join(),
        title: this.showByType.title
      });
    } else {
      this.sharePowerAndLevel('main-image', {
        imageName: `${this.powerLevelObject.level.imageUrl}.svg`,
        description: this.powerLevelObject.level.descriptions.join(),
        title: this.powerLevelObject.level.title
      });
    }
  }

  async sharePowerAndLevel(selectedDivId: string, data: { imageName: string, title: string, description: string }) {
    const img =  document.getElementById(selectedDivId) as HTMLImageElement;
    const blob = await fetch(img.src).then(res => res.blob());
    await this.shareByNavigator(blob, data.imageName, data.title, data.description);
  }

  async shareByNavigator(blob: Blob, fileName: string, title: string, text: string) {
    const webShareSupported = 'canShare' in navigator;
    if (webShareSupported) {
      const data = {
        files: [
          new File([blob], fileName, {
            type: blob.type,
          }),
        ],
        title,
        text: title + "\n\n" + text.replace('.,', '. \n'),
      };
      const isNavigator = window.navigator;
      if (window.navigator && isNavigator.share) {
        try {
          await navigator.share(data);
        } catch (err) {
          if (err.name !== 'AbortError') {
            console.error(err.name, err.message);
          }
        } finally {
          return;
        }
      }
    }
  }
}
