import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import 'firebase/firestore';

import {
  Plugins,
} from '@capacitor/core';
import { AnalyticsService } from 'src/services/analytics.service';
import { first } from 'rxjs/operators';
import { JournalService } from 'src/services/journal.service';
import { NotificationService } from 'src/services/notification.service';
import { NavController } from '@ionic/angular';
import { fadeAnimation } from 'src/helpers/transitions-helper';

const { Storage } = Plugins;


@Component({
  selector: 'email-signup',
  templateUrl: 'emailsignup.component.html',
  styleUrls: ['emailsignup.compontent.css']
})
export class EmailSignupComponent implements OnInit {

  constructor(public ngFireAuth: AngularFireAuth,
    public router: Router,
    private afs: AngularFirestore,
    private analytics: AnalyticsService,
    public journalService: JournalService,
    public notificationService: NotificationService,
    private navCtrl: NavController) { }

  usersCollection: AngularFirestoreCollection<any>;

  agreed: boolean = true;
  email2: string;
  password2: string;
  promoValue: string;

  remaningCodeUsages: number;

  ngOnInit(): void {
    this.checkUserIsLoggedIn()
  }

  signUpClick(email, password, activationCode) {
    if (this.agreed) {
      this.email2 = email.value;
      this.password2 = password.value;

      // this.afs.collection('activation_codes', ref => ref.where('code', '==', activationCode.value)).valueChanges().pipe(first()).
      this.afs.collection('activation_codes').doc(activationCode.value).valueChanges().pipe(first())
        .subscribe(res => {
          if (res) {
            this.remaningCodeUsages = res['remaining'];
            if (this.remaningCodeUsages > 0) {
              this.signup(this.email2, this.password2, activationCode.value);
            }
            else {
              alert("User limit with this code reached")
            }
          }
          else {
            alert("Invalid code")
          }
        })
    }
  }

  validPass: boolean = true;
  pristinePass: boolean = true;
  validEmail: boolean = true;
  pristineEmail: boolean = true;

  validPromo: boolean = true;

  signup(email, password, activationCode) {
    this.pristineEmail = false;
    this.pristinePass = false;

    if (!this.validateEmail(email) || !this.validatePassword(password)) {
      return;
    }

    return this.ngFireAuth.createUserWithEmailAndPassword(email, password).then(async (res) => {

      let userid = res.user.uid;
      console.log(userid);

      const { value } = await Storage.get({ key: 'notif_token' });
      console.log('Got item: ', value);


      if (activationCode && activationCode != '') {
        this.afs.collection<any>('users').doc(userid).set({
          email: email,
          token: value,
          usrid: userid,
          activationCode: activationCode
        });

        this.afs.collection('activation_codes').doc(activationCode).update({
          remaining: this.remaningCodeUsages - 1
        });

        //this.analytics.logEvent("study_ios_" + promocodeValue);
        //await Storage.set({ key: 'study_ios', value: userid });
      } else {
        this.afs.collection<any>('users').doc(userid).set({
          email: email,
          token: value,
          usrid: userid
        });
      }

      await Storage.set({ key: 'userid', value: userid });

      // this.saveDream1toFireBase(userid);
      //this.journalService.initiateCollections();

      //Event tracking for analytics
      this.analytics.logEventWithParams("sign_up", {email: email});
      this.analytics.mixpanelAlias(email);
      if (activationCode) {
        this.analytics.mixpanelTrack("sign_up", { email: email, activationCode: activationCode });
        this.analytics.mixpanelSetProfile(email, activationCode);
      }
      else {
        this.analytics.mixpanelTrack("sign_up", { email: email });
        this.analytics.mixpanelSetProfile(email);
      }

      this.navCtrl.navigateRoot("intro-scene", { animation: fadeAnimation, animated: true });
    }
    ).catch(error => {
      console.log("Error creating user: ");
      console.log(error);
      let emailInUse = error['message'].includes("The email address is already in use");
      let badformat = error['message'].includes("The email address is badly formatted");
      if (emailInUse == true) {
        alert("The email is taken");
      }
      if (badformat) {
        alert("Invalid Email Format");
      }
      return false;
    });
  }

  signin() {
    this.navCtrl.navigateForward("login", { animated: false });
  }

  saveDream1toFireBase(userid) {
    var newObj = {};
    var storyPoints1 = localStorage.getItem("storyPoints-1");
    var storyState1 = localStorage.getItem("storyState-1");

    newObj["storyPoints-1"] = storyPoints1;
    newObj["storyState-1"] = storyState1;

    if (userid != null) {
      this.afs.collection<any>('dream_data').doc(userid).set(newObj).catch();
    }
  }

  onEmailChange(value: string) {
    if (!this.pristineEmail) {
      this.validateEmail(value);
    }
  }


  onPassChange(value: string) {
    if (!this.pristinePass) {
      this.validatePassword(value);
    }
  }

  validateEmail(value: string) {
    let regexValidator = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!regexValidator.test(value)) {
      this.validEmail = false;
      return false;
    }
    this.validEmail = true;
    return true;
  }

  validatePassword(value: string) {
    let regexValidator = new RegExp("^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,}$");

    if (!regexValidator.test(value)) {
      this.validPass = false;
      return false;
    }
    this.validPass = true;
    return true;
  }

  async checkUserIsLoggedIn(): Promise<void> {
    await Storage.get({ key: 'userid' }).then(useridres => {
      if (useridres.value != null)
      this.navCtrl.navigateRoot("dream-selector");
    });
  }
}
