import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AnalyticsService } from 'src/services/analytics.service';
import { StateManagementService } from 'src/services/state-management.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-community',
  templateUrl: './community.component.html',
  styleUrls: ['./disclaimer.component.css']
})
export class CommunityComponent implements OnInit {

  constructor(public location: Location, private analyticsService: AnalyticsService,
    public stateManagement: StateManagementService,
    public router: Router) { }

  ngOnInit(): void {
  }

  back() {
    if (!this.stateManagement.isAppOpenedFromNotificaton) {
      this.location.back();
    } else {
      this.stateManagement.isAppOpenedFromNotificaton = false;
      this.router.navigate(["navmenu/navhome"]);
    }
  }
}
