<ion-app>
  <ion-header class="ion-no-border">
  </ion-header>
  <ion-content scrollY="false">
    <div class="card-outline">
      <div class="cmn-wrapper">
        <div>
          <span>
            <ion-icon class="cmn-icon-helvetica" (click)="back()" name="chevron-back-outline">
            </ion-icon>
          </span>
          <span (click)="signup()" class="sign-up ion-text-uppercase cmn-font-helvetica">Sign up</span>

        </div>
        <div class="cmn-serif-title ion-text-center cmn-text-shadow">
          Log in
        </div>
        <div class="cmn-font-oscar ion-text-center">
          Log in with your <br> Betwixt account details
        </div>

        <div class="ion-text-left cmn-input-parent">
          <ion-input #email type="email" class="input-field" placeholder="Your email"></ion-input>
        </div>
        <div class="ion-text-left inputParent">
          <ion-input #password type="password" class="input-field" placeholder="Password"></ion-input>
        </div>
        <div class="cmn-font-oscar forgot-pass" [routerLink]="'/forgotten-pass'">Forgot
          password?</div>
        <div class="cst-footer ion-text-center cmn-bottom-text">
          <div class="cmn-button ion-text-center" (click)="loginClick(email,password)">
            Log in
          </div>
          <div class="cmn-font-oscar ion-text-center" [routerLink]="'/emailsignup'">or Create an Account
          </div>
        </div>
      </div>
    </div>
  </ion-content>
</ion-app>