<div journal_menu_container>
  <div class="journal-menu">
    <div class="journal-menu-wrapper">
      <div class="header">
        <div class="header-content">
          <div class="background" [style]="journalBackgroundImage"></div>
          <div class="eyebrow">
            <div class="nav-button left" (click)="onIconEvent()">
              <img [ngClass]="showReadMore ? 'hide' : 'show'" [src]="'assets/images/back-icon.svg'" alt="icon">
              <img [ngClass]="showReadMore ? 'show' : 'hide'" class="close-btn" [src]="'assets/images/close-icon.svg'" alt="icon">
            </div>
            <div class="story-title global-text">Story {{ romanizeDreamNumber(dreamId) }}</div>
          </div>
          <div class="journal-text">
            <div>{{ isCompleted ? 'Completed' : 'Journal' }}</div>
          </div>
        </div>
      </div>
      <div class="content">
        <mat-tab-group [(selectedIndex)]="selectedTabIndex" animationDuration="450">
          <mat-tab label="Progress"></mat-tab>
          <mat-tab label="Memories"></mat-tab>
        </mat-tab-group>
        <div class="content-wrapper">
          <div
            class="progress-page-tab"
            [ngClass]="{'translated': selectedTabIndex !== 0, 'anim-translate': animTranslate}"
          >
            <div class="progress-tab">
              <div class="progress-image" (click)="onReadMore('level', powerLevelObject.level.title)">
                <div class="outer-circle" [ngClass]="{'glow-effect': !viewedItems.includes(powerLevelObject.level.title)}">
                  <div class="image-circle">
                    <img [src]="'assets/images/levels/' + powerLevelObject.level.imageUrl + '.svg'" alt="progress">
                  </div>
                </div>
                <h1 class="global-text">{{ powerLevelObject.level.title }}</h1>
              </div>
            </div>
            <div class="powers">
              <div class="power-items">
                <div class="title global-text">YOUR POWERS</div>
                <div
                  journal_swiper_container
                  class="swiper-container powers"
                  [ngClass]="{'visable-overflow': selectedTabIndex === 0, 'hidden-overflow': selectedTabIndex !== 0}">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide" *ngFor="let power of powerLevelObject.powers; let i = index">
                      <div class="power" (click)="onReadMore({title: power.title, descriptions: power.descriptions, optionals: power.optionals, imageUrl: power.imageUrl }, power.title)">
                        <img [src]="'assets/images/powers/' + power.imageUrl + '.svg'" alt="" [ngClass]="{'glow-effect': !viewedItems.includes(power.title) && selectedTabIndex === 0}">
                        <span class="global-text">{{ power.title }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="memory-page"
            [ngClass]="{'translated': selectedTabIndex === 0, 'anim-translate': animTranslate}"
          >
            <div class="memories">
              <ng-container *ngIf="memories.length > 0; else emptyContent">
                <div class="memory-content" *ngFor="let memory of memories">
                  <div class="bg-wrapper">
                    <div class="memory-title">{{ memory.title }}</div>
                    <div *ngIf="memory.descriptions.length && hasNoEmptyElement(memory.descriptions)" class="memory-description">
                      <div class="description-text" *ngFor="let description of memory.descriptions">
                        {{ description }}
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-template #emptyContent>
                <div class="empty-content">
                  ...
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="triggerDetails">
        <app-read-more-detail
          [showByType]="showByType === 'level' ? 'level' : showByType"
          [powerLevelObject]="powerLevelObject"
          [isShow]="showReadMore"
        ></app-read-more-detail>
      </ng-container>
    </div>
  </div>
</div>
