<ion-app>
  <ion-header class="ion-no-border">
  </ion-header>
  <ion-content>
    <div class="card-outline">
      <div class="cmn-wrapper">
        <div #signIn (click)="signin()" class="ion-text-right ion-text-uppercase cmn-font-helvetica">Sign in</div>

        <div class="cmn-serif-title ion-text-center cmn-text-shadow">
          Sign up
        </div>
        <!-- <div class="ion-text-center cmn-font-oscar subtitle">
          Create an account so we can save your journey and reach out with important information. No spam, we promise.
          <br><br>
        </div> -->
        <div class="ion-text-left cmn-input-parent">
          <ion-input #email class="input-field" placeholder="Your Email" type="email"
            (input)="onEmailChange($event.target.value)"> </ion-input>
        </div>
        <div class="ion-text-left cmn-input-parent">
          <ion-input #password class="input-field" placeholder="Password" type="password"
            (input)="onPassChange($event.target.value)"></ion-input>
        </div>
        <div class="cmn-font-oscar clarification">
          Must be at least 8 characters,
          contain at least one number and have a mixture of upper and lower case letters.
        </div>
        <div *ngIf="!validPass" class="validation cmn-font-oscar"> Please enter a valid password.</div>
        <div *ngIf="!validEmail" class="validation cmn-font-oscar"> Please enter a valid email </div>

        <!-- <div class="cmn-font-oscar">
          <ion-checkbox [(ngModel)]="agreed"></ion-checkbox> <span class="accept"> I agree to the <a
              [routerLink]="'/terms-conditions'">Terms & Conditions</a>
            and <a [routerLink]="'/privacy'">Privacy Policy</a> </span>
        </div> -->
        <div class="ion-text-left cmn-input-parent">
          <ion-input #activationcode class="input-field" placeholder="Activation Code" type="input"> </ion-input>
        </div>
        <div *ngIf="validEmail" class="validation cmn-font-oscar"> </div>
        <div *ngIf="validPass" class="validation cmn-font-oscar"> </div>

        <div class="cst-footer ion-text-center cmn-bottom-text">
          <ion-button class="cmn-button ion-text-center" [ngClass]="{'btn-disabled' : agreed == false}"
            (click)="signUpClick(email,password,activationcode)">
            Create Account
          </ion-button>
        </div>
      </div>
    </div>
  </ion-content>
</ion-app>