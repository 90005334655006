import {
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
  NgZone,
  ViewContainerRef,
} from "@angular/core";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { MenuComponent, MenuType } from "src/components/menu/menu.component";

@Injectable({
  providedIn: "root",
})
export class MenuService {
  private _menuComponentRef: ComponentRef<MenuComponent>;
  private _menuOpened = new BehaviorSubject<boolean>(false);
  private _closeSubscription: Subscription;

  constructor(private resolver: ComponentFactoryResolver, private ngZone: NgZone) {}

  public get isMenuOpened(): Observable<boolean> {
    return this._menuOpened.asObservable();
  }

  openMenu(viewContainerRef: ViewContainerRef, menuType: MenuType, finished = false, dreamId = 0): void {
    this.ngZone.run(() => {
      const factory = this.resolver.resolveComponentFactory(MenuComponent);
      if (this._closeSubscription && !this._closeSubscription.closed) {
        this.closeMenu();
        this._closeSubscription.unsubscribe();
      }
      viewContainerRef.clear();
      this._menuComponentRef = viewContainerRef.createComponent<MenuComponent>(factory);
      this._menuComponentRef.instance.menuType = menuType;
      this._menuComponentRef.instance.finished = finished;
      this._menuComponentRef.instance.dreamId = dreamId;
      this._closeSubscription = this._menuComponentRef.instance.close.subscribe(() => {
        this.closeMenu();
        if (this._closeSubscription) this._closeSubscription.unsubscribe();
      })
      this._menuComponentRef.changeDetectorRef.detectChanges();
      this._menuOpened.next(true);
    })
  }

  closeMenu() {
    this.ngZone.run(() => {
      if (this._menuComponentRef) {
        this._menuComponentRef.destroy();
        this._menuOpened.next(false);
        if (this._closeSubscription && !this._closeSubscription.closed) this._closeSubscription.unsubscribe();
        this._menuComponentRef = undefined;
      }
    })
  }

  async toggleMenu(wrapperRef: ViewContainerRef, menuType: MenuType, finished = false, dreamId = 0) {
    if (!wrapperRef) {
      console.error('menu insertion point missing');
      return;
    }
    const isOpened = await this.isMenuOpened.pipe(take(1)).toPromise();
    if (isOpened) {
      this.closeMenu();
    } else {
      this.openMenu(wrapperRef, menuType, finished, dreamId);
    }
  }
}
