import { OperationsHelper } from 'src/helpers/ops-helper';
import { ContentService } from './../../services/content.service';
import { ActivatedRoute } from '@angular/router';
import { Component, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { NavController } from '@ionic/angular';
import { FlipCardHelper } from 'src/helpers/flip-card-helper';
import Swiper from 'swiper';
import SwiperCore, { Pagination } from 'swiper/core';
import { expandAnimationDream } from 'src/helpers/transitions-helper';
import { StateManagementService } from 'src/services/state-management.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { StoryService } from 'src/services/story.service';
import { AnalyticsService } from 'src/services/analytics.service';
import { MenuService } from 'src/services/menu.service';

SwiperCore.use([Pagination]);

@Component({
  selector: 'app-dream-selector',
  templateUrl: './dream-selector.component.html',
  styleUrls: ['./dream-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeJournal', [
      state('void', style({transform: "translateY(20%)", opacity: 0})),
      state('fadeIn', style({ transform: "none", opacity: 1 })),
      state('instant', style({ transform: "none", opacity: 1 })),
      transition(':enter', [animate('300ms ease')]),
      transition('void <=> fadeIn', [animate('300ms ease')]),
      transition('void => instant', [animate('0ms')]),
      transition('instant => void', [animate('300ms')]),
    ]),
    trigger('fadeDreamSelector', [
      state('faded', style({transform: "translateY(-20%)", opacity: 0})),
      state('notFaded', style({})),
      state('instant', style({ opacity: 0 })),
      transition(':enter', [animate('300ms ease')]),
      transition('notFaded <=> faded', [animate('300ms ease')]),
      transition('* => instant', [animate('0ms')]),
      transition('instant => *', [animate('300ms ease')]),
    ])
  ]
})
export class DreamSelectorComponent implements OnInit, OnDestroy {
  @Input('hasBackground') hasBackground = true;

  swiper: Swiper;
  activeDreamNumber = 1;
  dreamStyles: string[] = [];
  buttonText = '';
  fadeOutText = false;
  isActiveAnimationAttribute = true;
  showJournal: boolean;
  effectSet = false;
  transformedDreamData: DreamType[] = [];
  showCompleted = false;
  @ViewChild('menuInsertionPoint', { read: ViewContainerRef }) wrapperRef: ViewContainerRef;
  dreamIndex = 0;
  isIos = true;
  dreamData: DreamType[] = [
    {
      id: 1,
      eyebrow: "Story I",
      headline: "The In-Between",
      subhead: "The Ice World",
      description: "Exploring the change you want to see."
    },
    {
      id: 2,
      eyebrow: "Story II",
      headline: "A Game of Trust",
      subhead: "The Ice Breaks",
      description: "Exploring the things you love, appreciate and value."
    },
    {
      id: 3,
      eyebrow: "Story III",
      headline: "The Power & the Flaw",
      subhead: "The World Warms",
      description: "Identifying your core drive to reach for excellence and perfection (the Striver)."
    },
    {
      id: 4,
      eyebrow: "Story IV",
      headline: "Then Came the Flood",
      subhead: "The Waters of Change",
      description: "Self-distancing: seeing your emotions from the outside in."
    },
    {
      id: 5,
      eyebrow: "Story V",
      headline: "What Lies Beneath",
      subhead: "The Lost City",
      description: "Exploring the connection between self-sabotage and unmet needs."
    },
    {
      id: 6,
      eyebrow: "Story VI",
      headline: "A Shadow Looms",
      subhead: "First Contact",
      description: "A meditation on fear."
    },
    {
      id: 7,
      eyebrow: "DREAM VII",
      headline: "What Lies Beyond",
      subhead: "Tracing Patterns",
      description: "Fight, flee, freeze or fawn? Knowing your patterns."
    },
    {
      id: 8,
      eyebrow: "Story VIII",
      headline: "Hidden Gems",
      subhead: "Homecoming",
      description: "Identifying your key strengths."
    },
    {
      id: 9,
      eyebrow: "Story IX",
      headline: "A Story Told",
      subhead: "Becoming the Author",
      description: "The shadow self and its destructive stories."
    },
    {
      id: 10,
      eyebrow: "Story X",
      headline: "A New Narrative",
      subhead: "Constellations",
      description: "Using memory or metaphor to choose a better."
    },
    {
      id: 11,
      eyebrow: "Story XI",
      headline: "You, the Architect",
      subhead: "Metamorphosis",
      description: "Choosing a magical form for your monster and dreaming up its new home."
    },
    {
      id: 12,
      eyebrow: "Story II",
      headline: "A Game of Trust",
      subhead: "The Ice Breaks",
      description: "Exploring the things you love, appreciate and value."
    },
  ];

  constructor(
    private navCtrl: NavController,
    private route: ActivatedRoute,
    private stateService: StateManagementService,
    private storyService: StoryService,
    private analyticsService: AnalyticsService,
    private contentService: ContentService,
    public menuService: MenuService,
  ) { }

  ngOnInit(): void {
    this.isIos = OperationsHelper.isIphone();
    this.showCompleted = Object.keys(this.route.snapshot.queryParams).includes('openJournal');
    if (this.showCompleted) this.showJournal = true;
    this.setDreamSelectorState();
    this.dreamStyles = this.getDreamStyles(12);
    const selectedDreamId = localStorage.getItem("selectedDream");
    this.dreamIndex = selectedDreamId ? parseInt(selectedDreamId) - 1 : this.getCurrentDream();
    this.swiper = new Swiper(".swiper-container", {
      speed: 300,
      initialSlide: this.dreamIndex,
      direction: 'horizontal',
      observer: true,
      observeParents: true,
      slidesPerView: 1.1,
      centeredSlides: true,
      spaceBetween: 13,
      slideActiveClass: 'active',
      setWrapperSize: true,
      lazy: true,
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        dynamicBullets: true,
        renderBullet: (index: number, className: string) => {
          return `<span class="${className} swiper-pagination-bullet--svg-animation">${this.getSvgType(index)}</span>`;
        },
      },
      on: {
        slideChange: (swiper) => {
          if ((swiper.realIndex + 1) === this.dreamData[swiper.realIndex].id) {
            this.activeDreamNumber = this.dreamData[swiper.realIndex].id;
            this.fadeOut();
          }
        }
      }
    });
    localStorage.removeItem("selectedDream");
    this.activeDreamNumber = this.swiper.activeIndex + 1;
    this.fadeOut();
    this.removeAnimationAttributes();
    this.onEffectsSet();
  }

  fadeOut() {
    this.fadeOutText = true;
    const timeout = setTimeout(() => {
      this.fadeOutText = false;
      clearTimeout(timeout);
    }, 500);
  }

  getCurrentDream(): number {
    const lastCmplDream = this.stateService.getLastCompletedDream();
    return (lastCmplDream + 1) <= 11 ? lastCmplDream : lastCmplDream - 1;
  }

  private getDreamStyles(dreams: number): string[] {
    const dreamStylesArray: string[] = [];
    for (let i = 1; i < dreams; i++) {
      const styles = FlipCardHelper.getStyles(i);
      dreamStylesArray.push(styles.frontCardStyle)
    }

    return dreamStylesArray;
  }

  setDreamSelectorState(): void {
    const lastCmplDream = this.stateService.getLastCompletedDream();
    const restartedDreamIdFromLocalStorage = localStorage.getItem("restartedDreamId");
    const restartedDreamId =  restartedDreamIdFromLocalStorage ? parseInt(restartedDreamIdFromLocalStorage) : 0;

    this.transformedDreamData = this.dreamData.map((dream) => {
      if (dream.id < lastCmplDream) {
        dream.buttonText = ButtonTexts.REREAD_THIS_DREAM;
        dream.description = this.contentService.getDreamTitles(dream.id, JSON.parse(this.stateService.getStoryFromLocalStorage(dream.id)).variablesState);
      } else if (dream.id === lastCmplDream && !this.stateService.hasSavedState(lastCmplDream + 1) && restartedDreamId !== dream.id + 1) {
        dream.buttonText = ButtonTexts.REPLY_THIS_DREAM;
        dream.description = this.contentService.getDreamTitles(dream.id, JSON.parse(this.stateService.getStoryFromLocalStorage(dream.id)).variablesState);
      } else if (dream.id === lastCmplDream && (this.stateService.hasSavedState(lastCmplDream + 1) || restartedDreamId === dream.id + 1)) {
        dream.buttonText = ButtonTexts.REREAD_THIS_DREAM;
        dream.description = this.contentService.getDreamTitles(dream.id, JSON.parse(this.stateService.getStoryFromLocalStorage(dream.id)).variablesState);
      } else if (dream.id === (lastCmplDream + 1) && !this.stateService.hasSavedState(lastCmplDream + 1)) {
        dream.buttonText = ButtonTexts.ENTER_THE_IN_BETWEEN;
        dream.description = "Your journey awaits";
      } else if (dream.id === (lastCmplDream + 1) && this.stateService.hasSavedState(lastCmplDream + 1)) {
        dream.buttonText = ButtonTexts.ENTER_THE_IN_BETWEEN;
        dream.description = "Your journey awaits";
      } else {
        dream.buttonText = ButtonTexts.THE_WORLD_AWAITS;
      }

      return dream;
    });
  }

  async navigateToStory(): Promise<void> {
    if (this.isFutureDream(this.activeDreamNumber - 1)) return;
    if (this.transformedDreamData[this.activeDreamNumber - 1].buttonText === ButtonTexts.REPLY_THIS_DREAM) {
      if (!confirm("Are you sure you want to restart the dream?")) return;
      this.analyticsService.logEvent("dream_replay");
      await this.storyService.startGame(this.activeDreamNumber);
      localStorage.setItem('lastCmplDream', (this.activeDreamNumber - 1).toString());
      this.storyService.clearInfoForRestart();
    }
    this.navCtrl.navigateRoot("game/" + this.activeDreamNumber, { animation: expandAnimationDream, animated: true });
  }

  removeAnimationAttributes(): void {
    setTimeout(() => {
      this.isActiveAnimationAttribute = false;
    }, 3000);
  }

  toggleJournalMenu(event?: boolean): void {
    if (this.showCompleted) {
      this.showCompleted = false;
      if (location.href.includes('?')) {
        history.pushState({}, null, location.href.split('?')[0]);
      }
      if (event) {
        if (this.stateService.getLastCompletedDream() !== 11) {
          setTimeout(() => {
            this.swiper.slideNext();
          }, 1000);
        }
      }
    }
    this.showJournal = !this.showJournal;
  }

  onEffectsSet(): void {
    setTimeout(() => {
      this.effectSet = !this.effectSet;
    }, 5000);
  }

  checkDreamTypeConditions(index: number): boolean {
    if (this.transformedDreamData && this.transformedDreamData[index])
      return this.transformedDreamData[index].buttonText === ButtonTexts.ENTER_THE_IN_BETWEEN || this.isFutureDream(index);
  }

  isFutureDream(index: number): boolean {
    if (this.transformedDreamData && this.transformedDreamData[index])
      return this.transformedDreamData[index].buttonText === ButtonTexts.THE_WORLD_AWAITS;
  }

  getJournalAnimationState(): 'fadeIn' | 'instant' {
    if (this.showCompleted) return 'instant';
    return 'fadeIn';
  }

  getDreamContainerAnimationState(): 'instant' | 'notFaded' | 'faded' {
    if (this.showJournal && this.showCompleted) return 'instant';
    if (this.showJournal) return 'faded';
    else return 'notFaded';
  }

  getSvgType(index: number) {
    if (this.checkDreamTypeConditions(index)) {
      return `
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
          <circle cx="7" cy="7" r="3" stroke="white"/>
        </svg>`;
    } else {
      return `
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
          <g filter="url(#filter0_d_113_593)">
          <circle cx="7" cy="7" r="3" fill="white"/>
          </g>
          <defs>
          <filter id="filter0_d_113_593" x="0" y="0" width="15" height="15" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="1"/>
          <feGaussianBlur stdDeviation="2.5"/>
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.35 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_113_593"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_113_593" result="shape"/>
          </filter>
          </defs>
        </svg>`;
    }
  }

  checkToNavigateToStory(event: Event): void {
    if ((event.target as Element).classList.contains('read-button-section')) return;
    this.navigateToStory();
  }

  ngOnDestroy(): void {
    this.menuService.closeMenu();
  }
}

type DreamType = {
  id: number;
  eyebrow: string;
  headline: string;
  subhead: string;
  description: string;
  buttonText?: ButtonTexts;
}

enum ButtonTexts {
  REPLY_THIS_DREAM = 'Replay this dream',
  ENTER_THE_IN_BETWEEN = 'Enter the In-Between',
  THE_WORLD_AWAITS = 'The world awaits',
  REREAD_THIS_DREAM = 'Reread this dream'
}
