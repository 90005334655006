import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { AnalyticsService } from 'src/services/analytics.service';

@Component({
  selector: 'signup-options',
  templateUrl: 'signupoptions.compontent.html',
  styleUrls: ['signupoptions.component.css']
})
export class SignUpOptionsCompontent implements OnInit {

  constructor(private navCtrl: NavController, private analyticsService: AnalyticsService) { }
  ngOnInit(): void {
  }

  navigate(location) {
    this.analyticsService.logEventWithParams("signup_or_login", {choice: location});
    this.analyticsService.mixpanelTrack("signup_or_login", {choice: location});
    this.navCtrl.navigateForward(location, { animated: false });
  }
}
