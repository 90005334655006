import {
  animate,
  AnimationTriggerMetadata,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";

export const headsetFadeInOut = fadeInOut('headsetFadeInOut', 2000, { in: 'in', out: 'out' });
export const starsFadeAnim = trigger('starsFadeAnim', [
  state('void', style({ opacity: 0 })),
  transition(':enter', [
    animate(`2000ms ease`, style({ opacity: 1 }))
  ])
]);

function fadeInOut(triggerName: string, delay: number, stateName: { in: string, out: string }): AnimationTriggerMetadata {
  return trigger(`${triggerName}`, [
    state(`${stateName.in}`, style({ opacity: 1 })),
    state(`${stateName.out}`, style({ opacity: 0 })),
    transition(`void => ${stateName.in}`, [ style({ opacity: 0 }), animate(`${delay}ms ease`) ]),
    transition(`${stateName.in} => ${stateName.out}`, [ animate(`${delay}ms ease`) ]),
  ]);
}
