import { Injectable } from '@angular/core';
import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
  Capacitor
} from '@capacitor/core';



import { AngularFireAuth } from '@angular/fire/auth';
import { BehaviorSubject } from 'rxjs';
import { AnalyticsService } from './analytics.service';
import { environment } from 'src/environments/environment';

const { PushNotifications, Storage } = Plugins;

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  constructor(private angularFireAuth : AngularFireAuth, private analyticsService: AnalyticsService) {

  //Initialize mixpanel at app start
  this.analyticsService.mixpanelInit(environment.mixpanel.projectToken, { debug: true });
  }

  isLoggedIn$ = new BehaviorSubject(undefined);

}